import { action, observable } from 'mobx';
import moment, { type Moment } from 'moment';
import { AccelFile, Updatable } from '../..';

export default class QuizItemAnswer extends Updatable {
    constructor(item?: Partial<QuizItemAnswer>) {
        super();
        if (item) this.update(item);
    }

    @observable itemId: string;
    @observable fileIds: string[];

    @observable rawValue: any;

    @observable booleanValue?: boolean;
    @observable numericValue?: number;
    @observable dateTimeValue?: Moment;
    @observable stringValue?: string;
    @observable listValue?: string;
    @observable listLabelValue?: string;
    @observable multiListValue?: string[];
    @observable multiListLabelValue?: string[];
    @observable files?: AccelFile[];

    @action update(q: Partial<QuizItemAnswer>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static fromJson(json: any): QuizItemAnswer {
        return new QuizItemAnswer({
            ...json,
            files: json.files ? json.files.map(AccelFile.fromJson) : [],
            dateTimeValue: json.dateTimeValue ? moment(json.dateTimeValue) : undefined,
        });
    }
}

import React, { useState } from "react";

/**
 * @deprecated use withHandler2 instead
 * @param WrappedComponent 
 * @returns 
 */
const withHandler = function <Props, Handler>(
    WrappedComponent: React.ComponentType<Props>
): React.FC<Props> & { useHandler: () => Handler } {
    const ComponentWithHandler = (props: Props) => {
        //@ts-ignore
        return <WrappedComponent {...props} />;
    };

    ComponentWithHandler.useHandler = () => {
        //@ts-ignore 
        const [handle] = useState<Handler>({});
        return handle;
    };
    return ComponentWithHandler;
}
export default withHandler;

import { action, runInAction, observable } from 'mobx';
import { inject } from 'react-ioc';
import Api from '../../api';
import { AccelFile } from '../../models';
import { saveAs } from '../../utils';

export default class FileStore {

    constructor(private api: Api) {
        this.api = api || inject(this, Api);
    }

    @action async save(file: Partial<AccelFile>) {
        return await this.api.file.save(file);
    }

    @observable removing: boolean = false;
    @action async remove(fileId: string) {
        this.removing = true;
        const result = await this.api.file.remove(fileId);
        runInAction(() => {
            this.removing = false;
        });
        return result;
    }

    @action
    async download(file: AccelFile) {
        if (file.isExternalFile) {
            saveAs(file.url!, true, file.filename);
            return true;
        }
        const result = await this.api.file.download(file.id);
        if (result.success) {
            saveAs(result.response.body, false, file.filename);
        }
        return result.success;
    }

    @action
    async preview(file: AccelFile) {
        const result = await this.api.file.download(file.id, true);
        if (result.success) {
            saveAs(result.response.body, true, file.filename);
        }
        return result.success;
    }
}
import React from 'react';
import styles from './VoiceRecordLock.module.scss';
import { ArrowUpOutlined, AudioOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { combineClasses } from '../../../../../../utils';

type Props = {
    percentToLock: number;
    locked: boolean;
    recording: boolean;
}
const VoiceRecordLock: React.FC<Props> = ({ percentToLock, locked, recording }) => {

    return <div className={styles.voice_record_lock}
        data-locked={locked}
        data-recording={recording}
        data-percent={percentToLock}
        style={{ height: percentToLock == 100 ? 70 : 70 * ((100 - percentToLock) / 100) }}>
        <UnlockOutlined />
        <ArrowUpOutlined className='absolute' style={{ bottom: 5, opacity: percentToLock == 100 ? 1 : 0.5 * ((100 - percentToLock) / 100) }} />
    </div>;
}
export default VoiceRecordLock;
export { }

declare global {
    interface Location {
        getReferral(): string;
    }
}
if (!location.getReferral) {
    location.getReferral = function (): string {
        return location.pathname + location.search + location.hash;
    }
}

import { observable, action } from 'mobx';
import { Contact } from '../..';
import { Designable } from '../../Entity';

export default class ForumThread extends Designable {
    constructor(branch?: Partial<ForumThread>) {
        super(branch);
        if (branch) this.update(branch);
    }

    @observable title: string;
    @observable description: string;

    @observable canStudentCreateTopics: boolean;
    @observable isPublished: boolean;
    @observable isLimitedAccess: boolean;

    @observable createdDate: Date;
    @observable updatedDate?: Date;
    @observable lastActivityDate?: Date;

    @observable parentThread: ForumThread | null;
    @observable moderators: Contact[];
    @observable topicCount: number;
    @observable commentCount: number;

    @action
    update(branch: Partial<ForumThread>) {
        super.update(branch);
    }

    static fromJson(json: any): ForumThread {
        return new ForumThread({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            lastActivityDate: json.lastActivityDate ? new Date(json.lastActivityDate) : undefined,
            parentThread: json.parentThread ? ForumThread.fromJson(json.parentThread) : null,
            moderators: json.moderators ? json.moderators.map((i: any) => Contact.fromJson(i.student)) : [],
        });
    }
}



import { observable, action } from 'mobx';
import { Admin, CallSIPRegistration, Entity, CallLineType } from '../..';

export enum CallIncomingType {
    Internal = 'internal',
    Phone = 'phone'
}


export default class CallIncoming extends Entity {
    constructor(callIncoming?: Partial<CallIncoming>) {
        super(callIncoming);
        if (callIncoming) this.update(callIncoming);
    }

    @observable admin: Admin | null;
    @observable sipRegistration: CallSIPRegistration | null;
    @observable phone: string;
    @observable phoneE164: string;
    @observable type: CallIncomingType;
    @observable lineType: CallLineType;
    @observable createdDate: Date;
    @observable updatedDate: Date;

    static fromJson(json: any): CallIncoming {
        return new CallIncoming({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            admin: json.admin ? Admin.fromJson(json.admin) : null,
            sipRegistration: json.sipRegistration ? CallSIPRegistration.fromJson(json.sipRegistration) : null,
        });
    }

    @action
    update(callIncoming: Partial<CallIncoming>) {
        super.update(callIncoming);
    }
}


import { KeyGenerator } from '../../utils';
import { useCallback, useState } from "react";

export type UniqueKeyController = {
    key: string;
    update: () => void;
}

export function useUniqueKey(defaultKey?: string): UniqueKeyController {
    const [key, setKey] = useState<string>(defaultKey ?? KeyGenerator.generate(5));

    const update = useCallback(() => {
        setKey(KeyGenerator.generate(5));
    }, []);

    return {
        key,
        update
    };
}

import { Updatable } from '../../..';

export default class BitPaySettings extends Updatable {
    public token: string;
    public testMode: boolean;

    constructor(settings?: Partial<BitPaySettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<BitPaySettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { action, computed, observable } from 'mobx';
import { FileSize } from '../../../../utils';
import QuizItemSettings from './QuizItemSettings';
import Humanize from 'humanize-plus';


export class UploadItemSettings extends QuizItemSettings {
    constructor(s?: Partial<UploadItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable fileType: 'images' | 'all';
    @observable maxFileSizeKb: number;
    @computed get maxFileSize(): FileSize {
        return FileSize.fromKilobytes(this.maxFileSizeKb);
    }

    get maxFileSizeString(): string {
        return Humanize.fileSize(this.maxFileSizeKb * 1024, 0);
    }

    @action update(q: Partial<UploadItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(def?: Partial<UploadItemSettings>) {
        return new UploadItemSettings({
            ...QuizItemSettings.default,
            fileType: 'all',
            maxFileSizeKb: FileSize.fromMegabytes(25).kilobytes,
            ...def
        });
    }

    clone() {
        return new UploadItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return UploadItemSettings.build(json);
    }
}

import ApiRequest, { HttpMethod } from "./core/request";
import ApiResult from "./core/result";
import { ApiListResponse, ApiResponse, FileResponseBody, TListResponseBody } from "./core/response";
import ApiClient from './clients/ApiClient';
import FileApi from './methods/file';
import FileManagerApi from './methods/file-manager';
import LocalizationApi from "./methods/loc";
import { BaseFilter, Entity } from "../models";


export default abstract class Api<
    TFileApi extends FileApi = FileApi,
    TFileManagerApi extends FileManagerApi = FileManagerApi,
    TLocApi extends LocalizationApi = LocalizationApi
> {

    constructor(protected client: ApiClient) {
    }

    abstract file: TFileApi;
    abstract fileManager: TFileManagerApi;
    abstract loc: TLocApi;

    async execute<TRequest extends ApiRequest, TResponse extends ApiResponse>
        (request: TRequest, ResponseType: { new(json: any): TResponse; }): Promise<ApiResult<TResponse>> {
        return await this.client.execute(request, ResponseType);
    }

    /**
     * new format of request execution
     * @param req 
     * @param parse 
     * @returns 
     */
    async execute2<TResponseBody = any>(req: Partial<ApiRequest> & Pick<ApiRequest, 'url'>, parse?: (json: any) => TResponseBody): Promise<ApiResult<ApiResponse<TResponseBody>>> {
        const result = await this.client.execute(new ApiRequest({ ...req }));
        if (result.response?.body && parse)
            result.response.body = parse(result.response.body);
        return result as ApiResult<ApiResponse<TResponseBody>>;
    }

    async put<TResponseBody = any>(url: string, body?: any, parse?: (json: any) => TResponseBody) {
        return this.execute2({ method: HttpMethod.PUT, url, body }, parse);
    }

    async get<TResponseBody = any>(url: string, fields = '', parse?: (json: any) => TResponseBody) {
        return this.execute2({ method: HttpMethod.GET, url, fields }, parse);
    }

    async getFile(url: string, body?: any) {
        return this.execute2<FileResponseBody>({ method: HttpMethod.GET, url, body, responseType: 'blob' });
    }

    async getList<TEntity extends Entity, TFilter extends BaseFilter<TEntity> = BaseFilter<TEntity>>(url: string, fields = '', filter?: BaseFilter<any>, parse?: (json: any) => TListResponseBody<TEntity, TFilter>) {
        const result = await this.client.execute(new ApiRequest({ method: HttpMethod.GET, url, fields, body: { ...filter } })) as ApiResult<ApiListResponse<TEntity, TFilter>>;
        if (result.response?.body) {
            let response = result.response as ApiListResponse<TEntity, TFilter>;
            if (parse)
                response.body = parse(result.response.body);

            // ListStore backward compability
            response.items = result.response.body.items;
            response.filter = result.response.body.filter;
        }
        return result;
    }

    async post<TResponseBody = any>(url: string, body: any, parse?: (json: any) => TResponseBody) {
        return this.execute2({ method: HttpMethod.POST, url, body }, parse);
    }

    async delete<TResponseBody = any>(url: string, parse?: (json: any) => TResponseBody) {
        return this.execute2({ method: HttpMethod.DELETE, url }, parse);
    }
}


﻿import IDisposable from './IDisposable';

export default class DisposableStack {
    private stack: Array<any> = [];

    public get length() {
        return this.stack.length;
    }

    public push(func: IDisposable | Function) {
        if (func == null) throw new Error('[DisposableStack] func is null');
        if ((func as IDisposable).dispose != undefined) {
            this.stack.push((func as IDisposable).dispose.bind(func));
            return;
        }
        if (typeof func != 'function') throw new Error('[DisposableStack] func is not a function');
        this.stack.push(func);
    }

    public dispose() {
        while (this.stack.length > 0) {
            this.stack.pop()();
        }
    }
}
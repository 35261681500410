import { observable, action } from 'mobx';
import type { DragSource, DropTarget } from './models';
import { debounce } from 'lodash';

export default class DragDropManager {
    constructor() {
        this.setCurrentTarget = debounce(this.setCurrentTarget.bind(this), 10, { leading: true });
    }

    @observable sources: DragSource<any>[] = [];
    @observable targets: DropTarget[] = [];

    @observable currentSource: DragSource<any> | null = null;
    @observable currentTarget: DropTarget | null = null;

    @action addSource(source: DragSource<any>) {
        this.sources.push(source);
        return source;
    }
    @action addTarget(target: DropTarget) {
        this.targets.push(target);
        return target;
    }

    @action deleteSource(source: DragSource) {
        this.sources = this.sources.filter(x => x != source);
    }
    @action deleteTarget(target: DropTarget) {
        this.targets = this.targets.filter(x => x != target);
    }

    @action setCurrentSource(source: DragSource | null) {
        this.currentSource = source;
    }

    @action setCurrentTarget(target: DropTarget | null, callback?: () => void) {
        this.currentTarget = target;
        callback?.();
    }

    isCurrentSource(source: DragSource) {
        return this.currentSource == source;
    }

    isCurrentTarget(target: DropTarget) {
        return this.currentTarget == target;
    }
}
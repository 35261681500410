import { ApiRequest, ApiResponse, HttpMethod } from '../../..';
import FileManagerItem from '../../../../models/file-manager/file-manager-item';
import { FileManagerItemTarget } from './FileSystemTarget';

export class CreateFileManagerDirectoryRequest extends ApiRequest {
	public method = HttpMethod.POST;
	public url: string = `/file-manager/create-directory`;

	constructor(dir: FileManagerItem, target?: FileManagerItemTarget) {
		super();

		this.body = {
			name: dir.name,
			parentId: dir.parentId,
			sectionType: dir.sectionType,
			...target
		};
	}
}

export class CreateFileManagerDirectoryResponse extends ApiResponse {
	public id: string;
	constructor(response: any) {
		super(response);
		this.id = this.body;
	}
}

import { action } from 'mobx';
import { User  } from '../User';

export class ManagerUser extends User {
    constructor(user?: Partial<ManagerUser>) {
        super(user);
        if (user) this.update(user);
    }

    static fromJson(json: any) {
        return new ManagerUser({
            ...json
        });
    }

    @action update(user: Partial<ManagerUser>, allowUndefined = false) {
        super.update(user, allowUndefined);
    }

}
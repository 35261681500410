import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input } from 'antd';
import { Localization, combineClasses } from '../../../../utils';
import { KeyCode } from '../../../../enums';
import { TextAreaRef } from 'antd/lib/input/TextArea';

export type EditorHandler = {
    focus: () => void;
    blur: () => void;
    insert: (text: string) => void;
}

type Props = {
    loc: Localization;
    text: string;
    enterToSubmit: boolean;
    disabled: boolean;
    placeholder?: string;
    autoSize?: { minRows?: number, maxRows?: number };
    autoFocus?: boolean;
    handlerRef?: React.MutableRefObject<EditorHandler | undefined>;
    className?: string;
    onSend: () => Promise<boolean>;
    onChange: (text: string) => void;
    onFocus?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
}
const Editor: React.FC<Props> = ({ loc, placeholder, text, enterToSubmit, autoSize, autoFocus, disabled, handlerRef, className, onChange, onSend, onFocus, onBlur }) => {
    const inputRef = useRef<TextAreaRef>(null);
    const [handler] = useState<EditorHandler>({
        focus: () => {
            inputRef.current?.focus();
            // don't ask me why 1000 idk it works
            inputRef.current?.resizableTextArea?.textArea.setSelectionRange(1000, 1000);
        },
        blur: () => inputRef.current?.blur(),
        insert: (text: string) => {
            if (!inputRef.current) return;
            inputRef.current.focus();
            const el = inputRef.current.resizableTextArea!.textArea;
            const [start, end] = [el.selectionStart, el.selectionEnd];
            el.setRangeText(text, start, end, 'end');
            onChange(el.value);
        }
    });

    useEffect(() => {
        if (handlerRef) handlerRef.current = handler;
        if (autoFocus === true)
            handler.focus();
    }, []);

    const handleInputKeyDown = useCallback(async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (!enterToSubmit) return;
        if (e.keyCode != KeyCode.Enter || e.shiftKey)
            return;

        e.preventDefault();
        await onSend();
        inputRef.current?.focus();
    }, []);

    return <Input.TextArea
        ref={inputRef}
        value={text}
        className={combineClasses('msg-edit-input', className)}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder ?? loc.word('Enter comment')}
        autoSize={autoSize}
        onKeyDown={handleInputKeyDown}
        onFocus={onFocus}
        onBlur={onBlur} />;
}

export default Editor;
import { Admin, Entity, PaymentGateway, PurchaseOrder } from '../..';
import { Currency } from '../../../enums';
import { PurchaseOrderPaymentStatus } from './PurchaseOrderPaymentStatus';
import { type Moment } from 'moment';
import moment from 'moment';
import { computed, observable } from 'mobx';
import { PurchaseOrderPaymentInstallmentStatus } from './PurchaseOrderPaymentInstallmentStatus';

export default class PurchaseOrderPayment extends Entity {
    constructor(payment?: Partial<PurchaseOrderPayment>) {
        super(payment);
        if (payment) this.update(payment);
    }

    @observable amount: number;
    @observable status: PurchaseOrderPaymentStatus;
    @observable installmentStatus?: PurchaseOrderPaymentInstallmentStatus;
    @observable currency: Currency;
    @observable createdDate: Moment;
    @observable paymentDate: Moment | null;
    @observable waitingForCaptureUntilDate: Moment | null;
    @observable errorMessage: string;
    @observable additionalPaymentData: string;
    @observable additionalPaymentMethodName: string;
    @observable additionalPaymentMethodData: string;
    @observable offSession: boolean;

    @observable purchaseOrder: PurchaseOrder;
    @observable paymentGateway: PaymentGateway;
    @observable admin: Admin;

    @observable isManual: boolean;
    @observable isTest: boolean;

    @observable fee: number;
    @observable income: number;

    @observable type: 'money' | 'bonus';

    @computed get canModify() {
        return this.admin != null;
    }

    update(product: Partial<PurchaseOrderPayment>) {
        super.update(product);
    }

    static fromJson(json: any): PurchaseOrderPayment {
        return new PurchaseOrderPayment({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            paymentDate: json.paymentDate ? moment(json.paymentDate) : null,
            waitingForCaptureUntilDate: json.waitingForCaptureUntilDate ? moment(json.waitingForCaptureUntilDate) : null,
            purchaseOrder: json.purchaseOrder ? PurchaseOrder.fromJson(json.purchaseOrder) : undefined,
            paymentGateway: json.paymentGateway ? PaymentGateway.fromJson(json.paymentGateway) : undefined,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined
        });
    }
}
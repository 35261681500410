
import { action, computed, observable } from 'mobx';
import LocalizationTranslation from './localization-translation';
import { Entity } from '..';
import { Lang } from '../../enums';

export default class LocalizationWord extends Entity {
    constructor(item?: Partial<LocalizationWord>) {
        super(item);
        if (item) this.update(item);
    }
    @observable id: string;
    @observable text: string;
    @observable comment: string;
    @observable useInSchool: boolean;
    @observable useInManager: boolean;
    @observable useInSite: boolean;
    @observable useInAdmin: boolean;
    @observable translations: LocalizationTranslation[];

    static fromJson(json: any): LocalizationWord {
        return new LocalizationWord({
            ...json,
            translations: json.translations
                ? json.translations.map((i: any) => LocalizationTranslation.fromJson(i))
                : [],
        });
    }

    @action update(word: Partial<LocalizationWord>) {
        super.update(word);
    }

    @computed get englishTranslation() {
        if (this.translations == null || this.translations.length == 0)
            return null;
        return this.translations.find(i => i.language.prefix = Lang.en);
    }
}
import { User, AccelFile, CustomFieldType } from '..';
import { observable, action } from 'mobx';
import CustomAttributeValue from './CustomAttribute/CustomAttributeValue/CustomAttributeValue';
import moment from 'moment';

export default class Contact extends User {
    constructor(contact?: Partial<Contact>) {
        super(contact);
        if (contact) this.update(contact);
    }

    @observable banned: boolean;
    @observable isEmailConfirmed: boolean;
    /**
     * @deprecated remove
     */
    @observable isSchoolEmailConfirmed: boolean;
    @observable emailConfirmationSentDate: Date | null;
    @observable isPhoneConfirmed: boolean;
    @observable needChangePassword?: boolean;
    @observable customAttributesValues: CustomAttributeValue[];
    @observable createdDate: Date;
    @observable partnerId?: string;

    getAttributeValue(attrId: string): CustomAttributeValue | null {
        const val = this.customAttributesValues.find(x => x.customField.id == attrId);
        return val ?? null;
    }

    @action setAttributeValue(value: CustomAttributeValue) {
        const val = this.getAttributeValue(value.customField.id);
        if (val) {
            val.update(value, true);
        } else {
            this.customAttributesValues.push(value);
        }
    }

    @action update(contact: Partial<Contact>, allowUndefined = false) {
        super.update(contact, allowUndefined);
    }

    clone(): Contact {
        return new Contact({
            ...this,
        });
    }

    static fromJson(json: any) {
        return new Contact({
            ...json,
            avatar: json.avatar ? AccelFile.fromJson(json.avatar) : undefined,
            birthday: json.birthday ? new Date(json.birthday) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            emailConfirmationSentDate: json.emailConfirmationSentDate ? new Date(json.emailConfirmationSentDate) : null,
            customAttributesValues: Contact.parseAttrValuesFromJson(json.leadAttributeValues),
            softDeletedDate: json.softDeletedDate ? moment(json.softDeletedDate) : undefined,
        });
    }

    static parseAttrValuesFromJson(values: any): CustomAttributeValue[] {
        const customAttrValues = (values
            ? values.map(CustomAttributeValue.fromJson)
            : []) as CustomAttributeValue[];
        const grouped = customAttrValues.groupBy(x => x.customField.id);
        const result: CustomAttributeValue[] = [];
        grouped.forEach(x => {
            const first = x[0];
            // if attr type is 'multilist' => combine values into one array only , and ignore everything else
            if (first.customField.type == CustomFieldType.MultiList) {
                // merge arrays to gather all the selected options
                const combinedValues = x.map(x => x.valueMultiList!).reduce((a, b) => [...a, ...b]);
                first.update({ valueMultiList: combinedValues });
            }
            result.push(first);
        });
        return result;
    }
}

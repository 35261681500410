import { action, computed, observable } from "mobx";
import { Updatable } from "../..";
import { TimeSpan } from '../../../utils';

export default class WebinarRoomSettings extends Updatable {
    constructor(settings?: Partial<WebinarRoomSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable enableChat: boolean;
    @observable blockGuestLinks: boolean;
    @observable hideParticipantLastName: boolean;
    @observable hideParticipantsCounter: boolean;
    @observable denyMultipleTabs: boolean;
    @observable enableQuestions: boolean;
    @observable enableQuestionsDelay: boolean;
    @observable questionsDelayMs: number;
    @observable enableChatGlossariesStopWords: boolean;
    @computed get questionsDelay(): TimeSpan {
        return TimeSpan.fromMilliseconds(this.questionsDelayMs);
    }

    @action update(settings: Partial<WebinarRoomSettings>) {
        super.update(settings);
    }

    hasChanges(settings: WebinarRoomSettings) {
        return this.enableChat != settings.enableChat
            || this.hideParticipantLastName != settings.hideParticipantLastName
            || this.denyMultipleTabs != settings.denyMultipleTabs
            || this.enableQuestions != settings.enableQuestions
            || this.enableQuestionsDelay != settings.enableQuestionsDelay
            || this.questionsDelayMs != settings.questionsDelayMs
            || this.blockGuestLinks != settings.blockGuestLinks
            || this.hideParticipantsCounter != settings.hideParticipantsCounter
            || this.enableChatGlossariesStopWords != settings.enableChatGlossariesStopWords;
    }

    copy() {
        return new WebinarRoomSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return new WebinarRoomSettings({
            enableChat: json.enableChat,
            hideParticipantLastName: json.hideParticipantLastName,
            denyMultipleTabs: json.denyMultipleTabs,
            enableQuestions: json.enableQuestions,
            enableQuestionsDelay: json.enableQuestionsDelay,
            questionsDelayMs: json.questionsDelayMs,
            blockGuestLinks: json.blockGuestLinks,
            hideParticipantsCounter: json.hideParticipantsCounter,
            enableChatGlossariesStopWords: json.enableChatGlossariesStopWords,
        });
    }
}
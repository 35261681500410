import { observable, action } from 'mobx';
import Entity from '../../Entity';
import Admin from '../Admin';

export default class AdminPartnerTariff extends Entity {
    constructor(tariff?: Partial<AdminPartnerTariff>) {
        super(tariff);
        if (tariff) this.update(tariff);
    }

    @observable name: string;
    @observable mainServicePercent: number;
    @observable additionalServicePercent: number;
    @observable admin: Admin;

    @action update(user: Partial<AdminPartnerTariff>, allowUndefined = false) {
        super.update(user, allowUndefined);
    }

    static fromJson(json: any) {
        return new AdminPartnerTariff({
            id: json.id,
            name: json.name,
            mainServicePercent: json.mainServicePercent,
            additionalServicePercent: json.additionalServicePercent,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
        });
    }
}
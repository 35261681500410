import React, { useCallback, useEffect } from "react";
import { ApiResponse, ApiResult } from "../../api";
import { useNotifier } from "./useNotifier";
import { ArgsProps } from "antd/lib/notification";
import { isDev } from "../../enums";

export type ApiMutateConfig<TResponseData> = {
    onSuccess?: (data: TResponseData, result: ApiResult<ApiResponse<TResponseData>>) => void;
    onError?: (result: ApiResult<ApiResponse<TResponseData>>) => void;
    mock?: TResponseData;
    auto?: boolean;
    successMsg?: React.ReactNode;
    notify?: {
        successOpts?: ArgsProps,
        errorOpts?: ArgsProps,
    }
}
const useApiRequest = function <TResponseData, T extends (...args: any[]) => Promise<ApiResult<ApiResponse<TResponseData>>>>
    (cb: T, config?: ApiMutateConfig<TResponseData>):
    [T, {
        data: TResponseData | null,
        loading: boolean,
        state: 'idle' | 'loading' | 'success' | 'error',
        result: ApiResult<ApiResponse<TResponseData>> | null,
    }] {
    const [data, setData] = React.useState<TResponseData | null>(null);
    const [result, setResult] = React.useState<ApiResult<ApiResponse<TResponseData>> | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState<'idle' | 'loading' | 'success' | 'error'>('idle');

    const [notify] = useNotifier();
    const execute = useCallback(async (...args: any[]) => {
        setState('loading');
        setLoading(true);
        setResult(null);
        const result = await cb(...args);
        setResult(result);
        const bodyData = (config?.mock && isDev) ? config?.mock : result.response.body;
        if (result.success) {
            setData(bodyData);
            setState('success');
        } else {
            setState('error');
        }
        setLoading(false);

        notify(result, config?.successMsg, config?.notify?.successOpts, config?.notify?.errorOpts);
        result.success ? config?.onSuccess?.(bodyData, result) : config?.onError?.(result);
        return result;
    }, [cb]) as T;

    useEffect(() => {
        if (config?.auto === true)
            execute();
    }, []);

    return [execute, { data, loading, state, result }];
};

export default useApiRequest
import { observable, action, runInAction, computed } from 'mobx';
import { ViewFileManagerItemRef as ViewFileManagerItemHandler } from './FileManagerItem';
import { FileManagerItem, FileManagerItemFilter, AccelFile, FileManagerItemType } from '../../models';
import { Localization, KeyGenerator } from '../../utils';
import { ErrorCode } from '../../enums';
import { FetchFileManagerItemsRequest } from '../../api/methods/file-manager/types/fetch';
import { RemoveFileManagerItemRequest } from '../../api/methods/file-manager/types/remove-file';
import { CreateFileManagerDirectoryRequest } from '../../api/methods/file-manager/types/create-directory';
import { CreateFileManagerItemRequest } from '../../api/methods/file-manager/types/create-item';
import { UpdateFileManagerItemRequest } from '../../api/methods/file-manager/types/update-item';
import _ from 'lodash';
import Api from '../../api/Api';


export class ViewFileManagerItem extends FileManagerItem {
	@observable isSelected: boolean = false;
	@observable isUploading: boolean = false;
	@observable isRenaming: boolean = false;
	newTemporaryName?: string;
	domElement: HTMLDivElement | null;
	itemHandler?: ViewFileManagerItemHandler
}

const LocalStorageParentIdKey = "fileManagerParentId";

export default class FileManagerStore {
	loc: Localization;
	keygen: KeyGenerator;

	@observable items: ViewFileManagerItem[] = [];
	@observable path: ViewFileManagerItem[] = [];
	@observable filter: FileManagerItemFilter;
	@observable isLoaded: boolean = false;
	@observable isVisible: boolean = false;
	@observable currentParentId: string | null;

	@computed get selectedItems() {
		return this.items.filter(x => x.isSelected);
	}

	constructor(private api: Api, loc: Localization, keygen: KeyGenerator) {
		this.currentParentId = localStorage.getItem(LocalStorageParentIdKey);
		this.api = api;
		this.loc = loc;
		this.keygen = keygen;
	}

	@action
	async addFile(item: ViewFileManagerItem) {
		this.items.push(item);
		this.sortItems()
	}


	@action
	async abortUploading(item: ViewFileManagerItem) {
		if (item.isUploading) {
			this.removeItemFromList(item);
		} else {
			this.removeItemFromServer(item);
			this.removeItemFromList(item);
		}
	}

	@action async fileNameExists(name: string) {
		return this.items.some(x => x.name == name);
	}

	@action changeParent(id: string | null = null) {
		this.currentParentId = id;
		if (this.currentParentId) {
			localStorage.setItem(LocalStorageParentIdKey, this.currentParentId);
		} else {
			localStorage.removeItem(LocalStorageParentIdKey);
		}

		this.deselectAll();
		this.fetchFiles();
	}

	@action deselectAll() {
		this.selectedItems.forEach(x => x.isSelected = false)
	}


	get parentFolder() {
		if (this.currentParentId || this.path.length == 1) {
			return _.last(this.path)?.parentId || null;
		}
		return null;
	}

	goOneLevelUp() {
		this.changeParent(this.parentFolder);
	}

	async createFileManagerItem(item: ViewFileManagerItem, file: AccelFile) {
		const result = await this.api.fileManager.createItem(new CreateFileManagerItemRequest(item.name, 'fileSystem', { fileId: file.id }, item.parentId));
		if (result.success) {
			runInAction(() => item.id = result.response?.id)
		}
		return result;
	}

	async uploadFiles(files: File[]) {
		if (files.length == 0) return;
		for (const file of files) {
			var newFileItem = new ViewFileManagerItem(
				new FileManagerItem({
					id: "_" + this.keygen.generate(),
					name: file.name,
					file: AccelFile.fromFile(file),
					parentId: this.currentParentId,
					type: FileManagerItemType.File,
				})
			);
			newFileItem.isUploading = true;
			this.addFile(newFileItem);
		}
	}

	async createFileManagerDirectory() {
		var newDirName = this.loc.word("New Folder");
		var i = 1;
		while (await this.fileNameExists(newDirName) == true) {
			newDirName = `${this.loc.word("New Folder")} ${i}`;
			i++;
		}

		var newDir = new ViewFileManagerItem(
			new FileManagerItem({
				id: "_" + this.keygen.generate(),
				type: FileManagerItemType.Directory,
				name: newDirName,
				parentId: this.currentParentId,
				sectionType: 'fileSystem'
			})
		);

		const result = await this.api.fileManager.createDirectory(new CreateFileManagerDirectoryRequest(newDir));
		if (result.success) {
			runInAction(() => newDir.id = result.response.id)
			this.addFile(newDir);
		}
		return result;
	}

	@action
	async removeItemFromServer(item: ViewFileManagerItem) {
		const result = await this.api.fileManager.remove(new RemoveFileManagerItemRequest([item.id]));
		return result;
	}

	@action
	removeItemFromList(item: ViewFileManagerItem) {
		this.items = this.items.filter(x => x.id != item.id);
	}



	@action
	async fetchFiles() {
		this.isLoaded = false;
		this.updateFilter({ take: 1000, parentId: this.currentParentId });

		const result = await this.api.fileManager.fetchItems(new FetchFileManagerItemsRequest(this.filter,
			`{ id, name, updatedDate, createdDate, type, parentId, softDeleted, file{ id, name, size, type, mimeType, cloudKey, extension }}`));
		if (result.success) {
			runInAction(() => {
				var itemList = result.response.items.map((x: any) => new ViewFileManagerItem(x));
				this.items = _.orderBy(itemList, ["type", "createdDate"], ['asc', 'desc']);
				this.path = result.response.path.map((x: any) => new ViewFileManagerItem(x));
				this.filter = result.response.filter;
			});
		} else {
			if (result.response.hasError(ErrorCode.DirectoryNotFound)) {
				this.changeParent();
				return;
			}
		}

		runInAction(() => {
			this.isLoaded = true;
		});
	}

	@action updateFilter(changes: Partial<FileManagerItemFilter>) {
		if (this.filter == null)
			this.filter = new FileManagerItemFilter({ sectionType: 'fileSystem' });
		this.filter.update({ ...changes, sectionType: 'fileSystem' });
	}

	async sortItems() {
		this.items = _.orderBy(this.items, ["type", "createdDate"], ['asc', 'desc']);
	}

	async renameSaveToServer(item: ViewFileManagerItem) {
		runInAction(() => {
			item.name = item.newTemporaryName || "";
		})
		const result = await this.api.fileManager.updateItem(new UpdateFileManagerItemRequest(item));
		return result;
	}

	async changeParentFolderSaveToServer(item: ViewFileManagerItem, newFolderId: string | null) {
		runInAction(() => {
			item.parentId = newFolderId;
		})
		const result = await this.api.fileManager.updateItem(new UpdateFileManagerItemRequest(item));
		return result;
	}
}

import { observable, action } from 'mobx';
import { type Moment } from 'moment';

export interface ISerializable {
    /**
   * Methods for API serialization
   */
    toJson(): any;
}

export interface IClonable {
    clone(changes?: any): any;
}

export class Updatable implements IClonable {
    @action
    update(entity?: Partial<any>, allowUndefined = false) {
        if (entity == null)
            return;
        for (const key in entity) {
            if (!entity.hasOwnProperty(key))
                continue;

            if (allowUndefined)
                this[key] = entity[key];
            else {
                if (entity[key] !== undefined)
                    this[key] = entity[key];
            }
        }
    }

    /**
    * for backward compatibility
    */
    copy() {
        return this.clone();
    }

    clone(): Updatable {
        throw new Error('Method not implemented.');
    }
}

export default class Entity extends Updatable {
    @observable id: string;
    @observable softDeleted: boolean;
    @observable softDeletedDate: Moment;

    constructor(entity?: Partial<Entity>) {
        super();
        if (entity) this.update(entity);
    }


    @action
    update(entity: Partial<Entity>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}


export interface IDesignable {
    designSettings: string;
    designSettingsPublished: string;
    designHTMLPublished: string;
    designStylePublished: string;
    designConcurrencyStamp: string;
}
/**
 * Entity with design settings for ez-editor
 */
export class Designable extends Entity implements IDesignable {
    @observable designSettings: string;
    @observable designSettingsPublished: string;
    @observable designHTMLPublished: string;
    @observable designStylePublished: string;
    @observable designConcurrencyStamp: string;

    @action
    update(entity: Partial<Designable>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { action, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';


export class NumberItemSettings extends QuizItemSettings {
    constructor(s?: Partial<NumberItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable minValue?: number;
    @observable maxValue?: number;

    @action update(q: Partial<NumberItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(def?: Partial<NumberItemSettings>) {
        return new NumberItemSettings({
            ...QuizItemSettings.default,
            minValue: undefined,
            maxValue: undefined,
            ...def
        });
    }

    clone() {
        return new NumberItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return NumberItemSettings.build(json);
    }
}

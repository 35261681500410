import { ApiRequest, ApiResponse, HttpMethod } from '../../..';
import { AccelFile } from '../../../../models';
import { FileManagerSection } from '../../../../models/file-manager/file-manager-item';
import { FileManagerItemTarget } from './FileSystemTarget';


export class CreateFileManagerItemRequest extends ApiRequest {
	public method = HttpMethod.POST;
	public url: string = `/file-manager/create-file`;

	constructor(public name: string,
		public section: FileManagerSection,
		public content: { fileId?: string, videoId?: string, fileUrl?: string; },
		public parentId?: string | null,
		public target?: FileManagerItemTarget) {
		super();

		this.body = {
			name: this.name,
			sectionType: this.section,
			fileId: this.content.fileId,
			fileUrl: this.content.fileUrl,
			videoId: this.content.videoId,
			parentId: this.parentId,
			...this.target
		};
	}
}

export class CreateFileManagerItemResponse extends ApiResponse {
	public id: string;
	public name: string;
	/**
	 * in case of creating video fm item
	 */
	public videoId?: string;
	public videoStreamId?: string;
	public file: Partial<AccelFile>;
	constructor(response: any) {
		super(response);
		this.id = this.body.id;
		this.file = this.body.file;
		this.name = this.body.name;
		this.videoId = this.body.videoId;
		this.videoStreamId = this.body.videoStreamId;
	}
}


import { observable, action } from 'mobx';
import { Entity, Contact } from '../..';

export default class ForumBan extends Entity {
    constructor(ban?: Partial<ForumBan>) {
        super(ban);
        if (ban) this.update(ban);
    }

    @observable reason: string;
    @observable beginDate: Date;
    @observable endDate?: Date;
    @observable contact: Contact;

    static fromJson(json: any): ForumBan {
        return new ForumBan({
            ...json,
            beginDate: json.beginDate ? new Date(json.beginDate) : undefined,
            endDate: json.endDate ? new Date(json.endDate) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : null,
        });
    }

    @action
    update(branch: Partial<ForumBan>) {
        super.update(branch);
    }
}


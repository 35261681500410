import { action, observable } from 'mobx';
import { AccelFile, Admin, Entity } from '../..';

export enum ExternalImportState {
    Created = 'created',
    Uploaded = 'uploaded',
    Processing = 'processing',
    Completed = 'completed',
    Failure = 'failure',
    Aborted = 'aborted',
}

export enum ExternalImportSource {
    SalesJet = 'salesJet',
}

export enum ExternalImportEntityType {    
    Tag = 'tag',
    PaymentGateway = 'paymentGateway',
    Course = 'course',
    CourseImage = 'courseImage',
    CourseCategory = 'courseCategory',
    CourseCategoryLink = 'courseCategoryLink',
    CoursePlan = 'coursePlan',
    CourseItem = 'courseItem',
    CourseItemPlanLink = 'courseItemPlanLink',
    Student = 'student',
    StudentCourse = 'studentCourse',
    StudentCourseAccess = 'studentCourseAccess', 
    StudentCourseItemProgress = 'studentCourseItemProgress', 
    StudentProduct = 'studentProduct', 
    StudentTagLink = 'studentTagLink', 
    Product = 'product',
    ProductPackage = 'productPackage',
    ProductPackageCourseLink = 'productPackageCourseLink', 
    ProductPurchaseOrder = 'productPurchaseOrder', 
    ProductPurchaseOrderContent = 'productPurchaseOrderContent', 
    ProductPurchaseOrderPayment = 'productPurchaseOrderPayment', 
    ProductImageLink = 'productImageLink', 
    LeadAttribute = 'leadAttribute', 
    LeadAttributeValue = 'leadAttributeValue',
    CourseItemTest = 'courseItemTest',
    CourseItemTestQuestion = 'courseItemTestQuestion',
    CourseItemTestAnswer = 'courseItemTestAnswer',
    AccelFile = 'accelFile'
}

export default class ExternalImport extends Entity {
    constructor(externalImport?: Partial<ExternalImport>) {
        super(externalImport);
        if (externalImport) this.update(externalImport);
    }
    
    @observable totalCount: number;
    @observable processedCount: number;
    @observable error: string;

    @observable state: ExternalImportState;
    @observable source: ExternalImportSource;
    @observable lastImportedEntity?: ExternalImportEntityType;

    @observable createdDate: Date;
    @observable updatedDate: Date;

    @observable file: AccelFile;
    @observable createdByAdmin: Admin;
    @observable updatedByAdmin: Admin;

    static fromJson(json: any): ExternalImport {
        return new ExternalImport({
            ...json,
            file: json.file ? AccelFile.fromJson(json.file) : undefined,
            createdByAdmin: json.createdByAdmin ? Admin.fromJson(json.createdByAdmin) : undefined,
            updatedByAdmin: json.updatedByAdmin ? Admin.fromJson(json.updatedByAdmin) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }

    @action
    update(externalImport: Partial<ExternalImport>) {
        super.update(externalImport);
    }
}
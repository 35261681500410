import React, { useContext, useEffect, useMemo } from "react";
import { Context } from "../AccelProvider/AccelProvider";

type DocTitleProps = {
    title: string,
    prefix?: string,
    usePrefix?: boolean,
}
const DocTitle: React.FC<DocTitleProps> = ({ title, prefix, usePrefix, children }) => {
    const { domainDocPrefix } = useContext(Context);

    let docTitle = useMemo(() => {
        var t = usePrefix == false ? '' : prefix || domainDocPrefix || 'AXL';
        if (title) {
            if (usePrefix != false) t += ' | ';
            t += title;
        }
        return t;
    }, [usePrefix, prefix, title]);

    useEffect(() => {
        if (document.title !== docTitle)
            document.title = docTitle;
    }, [docTitle]);

    return <>{children}</>;
}
export default DocTitle;
import { Updatable } from '../../..';

export default class EcPaySettings extends Updatable {
    public merchantId: string;
    public hashKey: string;
    public hashIV: string;
    public testMode: boolean;

    constructor(settings?: Partial<EcPaySettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<EcPaySettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { marked } from 'marked';
import React, { useEffect, useMemo } from 'react';
import { combineClasses, isEmpty } from '../../utils';
import styles from './MarkdownText.module.scss';

type Props = {
    text: string;
    // TODO describe settings type
    settings?: any;
    className?: string;
    ellipsis?: boolean;
    placeholder?: React.ReactNode;
    onParsed?: (x: string) => void;
}
const MarkdownText: React.FC<Props> = ({ text, settings, className, ellipsis, placeholder, onParsed }) => {

    const modifiedText = useMemo(() => {
        if (isEmpty(text)) return '';
        const lines = text.split('\n');

        return lines.map((line, index) => {
            // Проверяем, является ли строка частью списка
            const isListItem = /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(line);
            const isNextLineListItem = (index < lines.length - 1) && /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(lines[index + 1]);

            if (isListItem || isNextLineListItem)
                return line;

            if (line.trim() === '\\')
                return line.replace('\\', '&nbsp;\n');

            return line + '&nbsp;\n';
        }).join('\n');
    }, [text]);

    useMemo(() => {
        marked.use(settings ?? {
            breaks: true,
            gfm: true,
            renderer: {
                paragraph(text: string) {
                    return `<p class="mb-0">${text}</p>`;
                },
                link(href: string | null, title: string | null, text: string) {
                    return `<a href="${href}" target="_blank">${text}</a>`;
                }
            }
        });
    }, [settings]);

    const textHtml = useMemo(() => marked.parse(modifiedText), [modifiedText]);
    useEffect(() => {
        onParsed?.(textHtml);
    }, [textHtml, onParsed]);

    if (isEmpty(textHtml))
        return <>{placeholder}</>;
    return <div dangerouslySetInnerHTML={{ __html: textHtml }} className={combineClasses(className, styles.markdown_inner)} data-ellipsis={ellipsis}></div>;
}

export default MarkdownText;
import { useCallback, useRef, useState } from "react";

const useLongPress = (
    onLongPress: (e: any) => void,
    onClick: () => void,
    { shouldPreventDefault = true, delay = 300 } = {}
) => {
    const longPressTriggered = useRef(false);
    const timeout = useRef<any>();
    const target = useRef<any>();

    const start = useCallback(
        event => {
            if (shouldPreventDefault && event.target) {
                event.target.addEventListener("touchend", preventDefault, {
                    passive: false
                });
                target.current = event.target;
            }
            timeout.current = setTimeout(() => {
                longPressTriggered.current = true;
                onLongPress(event);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
          
            if(shouldTriggerClick && longPressTriggered.current == false)
                onClick();
            longPressTriggered.current = false;
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [shouldPreventDefault, onClick]
    );

    return {
        onMouseDown: (e: any) => start(e),
        onTouchStart: (e: any) => start(e),
        onMouseUp: (e: any) => clear(e),
        onMouseLeave: (e: any) => clear(e, false),
        onTouchEnd: (e: any) => clear(e)
    };
};

const isTouchEvent = (e: any) => {
    return "touches" in e;
};

const preventDefault = (e: any) => {
    if (!isTouchEvent(e)) return;

    if (e.touches.length < 2 && e.preventDefault) {
        e.preventDefault();
    }
};

export default useLongPress;
import { type Moment } from 'moment';
import { observable, action, computed } from 'mobx';
import Entity, { ISerializable } from '../Entity';
import { isArraysEqual } from '../../utils';
import CustomField, { CustomFieldType } from './CustomField';
import moment from 'moment';

export default class CustomFieldValue<TField extends CustomField = CustomField> extends Entity implements ISerializable {
    constructor(attr?: Partial<CustomFieldValue<TField>>) {
        super(attr);
        if (attr) this.update(attr);
    }

    @observable valueString?: string;
    @observable valueNumeric?: number;
    @observable valueBoolean?: boolean;
    @observable valueDateTime?: Moment;
    @observable valueDate?: Moment;
    @observable valueList?: string;
    @observable valueMultiList?: string[];

    @observable customField: TField;

    @computed get value(): any {
        if (this.valueBoolean !== undefined) return this.valueBoolean;
        if (this.valueNumeric !== undefined) return this.valueNumeric;
        if (this.valueDateTime !== undefined) return this.valueDateTime;
        if (this.valueDate !== undefined) return this.valueDate;
        if (this.valueString !== undefined) return this.valueString;
        if (this.valueList !== undefined) return this.valueList;
        return this.valueMultiList;
    }

    @action update(attr: Partial<CustomFieldValue<TField>>, allowUndefined = false) {
        super.update(attr, allowUndefined);
    }

    isEquals(attrValue: CustomFieldValue<TField>) {
        if (this.customField.id != attrValue.customField.id) return false;
        if (this.valueBoolean !== undefined) return this.valueBoolean === attrValue.valueBoolean;
        if (this.valueNumeric !== undefined) return this.valueNumeric === attrValue.valueNumeric;
        if (this.valueDateTime !== undefined) return this.valueDateTime === attrValue.valueDateTime;
        if (this.valueDate !== undefined) return this.valueDate === attrValue.valueDate;
        if (this.valueString !== undefined) return this.valueString === attrValue.valueString;
        if (this.valueList !== undefined) return this.valueList === attrValue.valueList;
        return isArraysEqual(this.valueMultiList, attrValue.valueMultiList);
    }

    copy(): CustomFieldValue<TField> {
        return new CustomFieldValue({
            id: this.id,
            customField: this.customField,
            valueString: this.valueString,
            valueNumeric: this.valueNumeric,
            valueBoolean: this.valueBoolean,
            valueDateTime: this.valueDateTime,
            valueDate: this.valueDate,
            valueList: this.valueList,
            valueMultiList: this.valueMultiList?.slice(),
        });
    }

    get valueJson() {
        return this.valueMultiList
            ? JSON.stringify(this.value)
            : this.value;
    }

    /**
     * @returns short json object for api
     */
    toJson() {
        return {
            id: this.customField.id,
            value: this.valueJson
        }
    }

    /**
     * json which contains all fields, it can be restored via `fromJson`
     */
    toFullJson() {
        return {
            id: this.customField.id,
            valueString: this.valueString,
            valueNumeric: this.valueNumeric,
            valueBoolean: this.valueBoolean,
            valueDateTime: this.valueDateTime,
            valueDate: this.valueDate,
            valueList: this.valueList,
            valueMultiList: this.valueMultiList,
            field: this.customField
                ? {
                    id: this.customField.id,
                    name: this.customField.name,
                    type: this.customField.type
                } : undefined
        }
    }

    clone() {
        return new CustomFieldValue<TField>({
            ...this
        });
    }

    static fromJson(json: any) {
        return new CustomFieldValue({
            id: json.id,
            valueString: json.valueString ?? undefined,
            valueNumeric: json.valueNumeric ?? undefined,
            valueBoolean: json.valueBoolean ?? undefined,
            valueDateTime: json.valueDateTime ? moment(json.valueDateTime) : undefined,
            valueDate: json.valueDate ? moment(json.valueDate) : undefined,
            valueList: json.valueList ?? undefined,
            valueMultiList: json.valueMultiList
                ? Array.isArray(json.valueMultiList) ? json.valueMultiList : [json.valueMultiList]
                : undefined,
            customField: json.field ? CustomField.fromJson(json.field) : undefined
        });
    }

    static fromValueType(type: CustomFieldType, value: any) {
        const val = new CustomFieldValue();
        switch (type) {
            case CustomFieldType.Boolean:
                val.valueBoolean = value;
                break;
            case CustomFieldType.Date:
                val.valueDate = value && moment(value);
                break;
            case CustomFieldType.DateTime:
                val.valueDateTime = value && moment(value);
                break;
            case CustomFieldType.List:
                val.valueList = value;
                break;
            case CustomFieldType.MultiList:
                const multiValue = typeof value === 'string' ? JSON.parse(value) : value;
                val.valueMultiList = Array.isArray(multiValue) ? multiValue : [multiValue];
                break;
            case CustomFieldType.Number:
                val.valueNumeric = value && parseFloat(value);
                break;
            case CustomFieldType.Text:
            case CustomFieldType.TextArea:
                val.valueString = value;
                break;
        }
        return val;
    }

    /**
     * TODO use it here, in purchase order, in pipeline stage item, in contact
     */
    static parseValuesFromJson<T extends CustomFieldValue = CustomFieldValue>(values: any, parse?: (x: any) => T): T[] {
        const customAttrValues = (values
            ? values.map((x: any) => parse ? parse(x) : CustomFieldValue.fromJson(x))
            : []) as T[];
        const grouped = customAttrValues.groupBy(x => x.customField.id);
        const result: T[] = [];
        grouped.forEach(x => {
            const first = x[0];
            // if attr type is 'multilist' => combine values into one array only , and ignore everything else
            if (first.customField.type == CustomFieldType.MultiList) {
                // merge arrays to gather all the selected options
                const combinedValues = x.map(x => x.valueMultiList!).reduce((a, b) => [...a, ...b]);
                first.update({ valueMultiList: combinedValues });
            }
            result.push(first);
        });
        return result;
    }
}
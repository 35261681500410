import { action, observable } from "mobx";
import WebinarRegistrationField from '../WebinarRegistrationField';
import WebinarRegistrationTermOfServiceFieldSettings from './WebinarRegistrationTermOfServiceFieldSettings';

export default class WebinarRegistrationTermOfServiceField extends WebinarRegistrationField {
    constructor(settings?: Partial<WebinarRegistrationTermOfServiceField>) {
        super();
        if (settings) this.update(settings);
    }

    @observable settings: WebinarRegistrationTermOfServiceFieldSettings;

    @action update(settings: Partial<WebinarRegistrationTermOfServiceField>) {
        super.update(settings);
    }

    get hasSettings() {
        return true;
    }

    hasChanged(field: WebinarRegistrationTermOfServiceField) {
        return super.hasChanged(field) || this.settings.hasChanged(field.settings);
    }

    copy() {
        return new WebinarRegistrationTermOfServiceField({
            ...this,
            settings: this.settings?.copy(),
        });
    }

    static fromJson(json: any) {
        return new WebinarRegistrationTermOfServiceField({
            ...json,
            settings: json.settings ? WebinarRegistrationTermOfServiceFieldSettings.fromJson(json.settings) : undefined
        });
    }
}

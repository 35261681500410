import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { BillingAddress, BillingInvoice, BillingPaymentMethod, BillingPlan, Entity, PaymentGatewayType, School } from '../..';
import { ManagerUser } from '../../user/ManagerUser'
import { Currency, PaymentMethodType } from '../../../enums';
import { isEmpty } from '../../../utils';

export enum BillingTransactionStatus {
    Success = 'success',
    Fail = 'fail',
    Pending = 'pending'
}

export type PaymentMethodCardData = {
    last4: string;
    expMonth: number;
    expYear: number;
    type: PaymentMethodType;
    hasLast4: boolean;
}
export default class BillingTransaction extends Entity {
    constructor(t?: Partial<BillingTransaction>) {
        super(t);
        if (t) this.update(t);
    }

    @observable school: School;
    amount: number;
    bonusAmount: number;
    externalId?: string;
    externalPayload?: string;
    paymentMethod: BillingPaymentMethod | null;
    createdDate: Moment;
    updatedDate?: Moment;
    reasonMessage?: string;
    errorMessage?: string;
    plan: BillingPlan | null;
    status: BillingTransactionStatus;
    invoice: BillingInvoice | null;
    exchangeCurrency: Currency;
    exchangeRate: number;
    gatewayType: PaymentGatewayType | null;
    manager: ManagerUser;
    address?: BillingAddress;
    paymentMethodCard?: PaymentMethodCardData;
    isAutopay: boolean;

    get isDeletable() {
        return this.gatewayType == null && this.invoice == null;
    }


    @computed get totalAmount() {
        return this.amount + this.bonusAmount;
    }

    @computed get amountExchange() {
        return this.amount * this.exchangeRate;
    }

    @computed get bonusAmountExchange() {
        return this.bonusAmount * this.exchangeRate;
    }

    @computed get totalAmountExchange() {
        return this.amountExchange + this.bonusAmountExchange;
    }

    @computed get hasReason() {
        return !isEmpty(this.reasonMessage);
    }

    @computed get isIncome() {
        return this.totalAmount > 0;
    }

    isCreator(id: string): boolean {
        return this.manager?.id == id;
    }

    @action
    update(t: Partial<BillingTransaction>) {
        super.update(t);
    }

    static fromJson(json: any): BillingTransaction {
        return new BillingTransaction({
            id: json.id,
            amount: json.amount,
            bonusAmount: json.bonusAmount,
            invoice: json.invoice ? BillingInvoice.fromJson(json.invoice) : null,
            externalId: json.externalId,
            externalPayload: json.externalPayload,
            paymentMethod: json.paymentMethod ? BillingPaymentMethod.fromJson(json.paymentMethod) : null,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            reasonMessage: json.reasonMessage,
            errorMessage: json.errorMessage,
            plan: json.billingPlan ? BillingPlan.fromJson(json.billingPlan) : null,
            status: json.status,
            school: json.school ? School.fromJson(json.school) : undefined,
            exchangeCurrency: json.exchangeCurrency,
            exchangeRate: json.exchangeRate,
            gatewayType: json.gatewayType,
            manager: json.manager ? ManagerUser.fromJson(json.manager) : undefined,
            address: json.address ? BillingAddress.fromJson(json.address) : undefined,
            paymentMethodCard: json.paymentMethodCardJson ? JSON.parse(json.paymentMethodCardJson) : undefined,
            isAutopay: json.isAutopay
        });
    }
}

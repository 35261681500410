import { ApiRequest, ApiResponse, HttpMethod } from '../../..';


export class RemoveFileManagerItemRequest extends ApiRequest {
	public method = HttpMethod.POST;
	public url: string = `/file-manager/delete`;

	constructor(public ids: string[]) {
		super();
		this.body = {
			ids
		};
	}
}

export class RemoveFileManagerItemResponse extends ApiResponse {
	ids: string[];
	constructor(response: any) {
		super(response);
		this.ids = this.body;
	}
}

import { ClientProxyFilter } from './ClientProxyFilter';
import HubRequest from './HubRequest';

export default abstract class ProxyHubRequest extends HubRequest {
    public subscription: string;
    public data: any = null;
    public proxyFilter: ClientProxyFilter = {
        excludeCurrentConnection: true
    };

    public async invoke(connection: signalR.HubConnection): Promise<any> {
        this.url = 'send';
        this.body = [this.subscription, this.data, this.proxyFilter];
        return super.invoke(connection);
    }
}

export { }

declare global {
    interface Array<T> {
        pushIfNotExists(elem: T, comparer?: (a: T, b: T) => boolean): void;
        remove(elem: T): Array<T>;
        removeIf(predicate?: (item: T) => boolean): Array<T>;
        removeAt(idnex: number): Array<T>;
        reorder(srcIndex: number, dstIndex: number): {
            oldItems: Array<T>;
            items: Array<T>;
            srcItem: T;
            dstItem: T;
        };
        toggle(item: T, predicate?: (item: T) => boolean): Array<T>;
        toMap<K>(getKey: (item: T) => K): Map<K, T>;
        groupBy(getKey: (item: T) => string): Map<string, Array<T>>;
        isEmpty(): boolean;
        isEquals(dstArr?: T[], comparer?: (a: T, b: T, aIdnex: number, bIndex: number) => boolean): boolean;
        orderBy(getter?: (a: T) => any): Array<T>;
        orderByDesc(getter?: (a: T) => any): Array<T>;
        count(predicate?: (item: T) => boolean): number;
        insert(item: T, index: number): Array<T>;
        /**
         * @param target the object to search for in the array
         * @param comparator  (optional) a method for comparing the target object type
         * @returns index of a matching item in the array if one exists, otherwise the bitwise complement of the index where the item belongs
         */
        binarySearch(target: T, comparator?: (a: T, b: T) => number): number;
        /**
        * @param target the object to search for in the array
        * @param duplicate  (optional) whether to insert the object into the array even if a matching object already exists in the array (false by default)
        * @param comparator  (optional) a method for comparing the target object type
        * @returns new array
        */
        binaryInsert(target: T, duplicate?: boolean, comparator?: (a: T, b: T) => number): { index: number, items: Array<T> };
        distinct(): Array<T>;
        distinctBy(field: (item: T) => any): Array<T>;
        /** flat polyfill */
        flat(depth?: number): T;
        shuffle(): Array<T>;
    }
}

if (!Array.prototype.pushIfNotExists) {
    Array.prototype.pushIfNotExists = function <T>(elem: T, comparer: (a: T, b: T) => boolean = defaultComparer): void {
        if (this.some(x => comparer(x, elem))) return;
        this.push(elem);
    }
}

if (!Array.prototype.remove) {
    Array.prototype.remove = function <T>(elem: T): T[] {
        return this.filter(e => e !== elem);
    }
}

if (!Array.prototype.removeAt) {
    Array.prototype.removeAt = function <T>(i: number): T[] {
        const items = this.slice();
        items.splice(i, 1);
        return items;
    }
}

if (!Array.prototype.removeIf) {
    Array.prototype.removeIf = function <T>(predicate: (item: T) => boolean): T[] {
        return this.filter(e => !predicate(e));
    }
}

if (!Array.prototype.reorder) {
    Array.prototype.reorder = function <T>(srcIndex: number, dstIndex: number) {
        const items = this.slice();
        const srcItem = items[srcIndex];
        const dstItem = items[dstIndex];
        items.splice(srcIndex, 1);
        items.splice(dstIndex, 0, srcItem);
        return {
            oldItems: this,
            items,
            srcItem,
            dstItem
        }
    }
}

if (!Array.prototype.toggle) {
    Array.prototype.toggle = function <T>(item: T, predicate?: (item: T) => boolean) {
        const items = this.slice();
        const p = predicate ? predicate : (x: T) => x === item;
        const i = items.findIndex(p);
        if (i < 0) items.push(item);
        else items.splice(i, 1);
        return items;
    }
}

if (!Array.prototype.toMap) {
    Array.prototype.toMap = function <T, K>(getKey: (item: T) => K) {
        return new Map<K, T>(this.map(x => ([getKey(x), x])))
    }
}

if (!Array.prototype.groupBy) {
    Array.prototype.groupBy = function <T>(getKey: (item: T) => string) {
        const map = new Map();
        this.forEach((item) => {
            const key = getKey(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
}

if (!Array.prototype.isEmpty) {
    Array.prototype.isEmpty = function <T>() {
        return this.length == 0;
    }
}

function defaultComparer(a: any, b: any) {
    return a === b;
}
if (!Array.prototype.isEquals) {
    Array.prototype.isEquals = function <T>(dstArr?: T[], comparer: (a: T, b: T, aIndex: number, bIndex: number) => boolean = defaultComparer) {
        if (this === dstArr) return true;
        if (!this || !dstArr) return false;
        if (this.length !== dstArr.length) return false;
        return this.every((x, srcIndex) => dstArr.find((y, dstIndex) => comparer(x, y, srcIndex, dstIndex)) != null);
    }
}

if (!Array.prototype.orderBy) {
    Array.prototype.orderBy = function <T>(getter: (a: T) => any = x => x) {
        if (!this) return [];
        return this.slice().sort((a, b) => getter(a) < getter(b) ? -1 : 1);
    }
}

if (!Array.prototype.orderByDesc) {
    Array.prototype.orderByDesc = function <T>(getter: (a: T) => any = x => x) {
        if (!this) return [];
        return this.slice().sort((a, b) => getter(b) < getter(a) ? -1 : 1);
    }
}

if (!Array.prototype.count) {
    Array.prototype.count = function <T>(predicate: (item: T) => boolean = x => true) {
        return this.filter(predicate).length;
    }
}

if (!Array.prototype.insert) {
    Array.prototype.insert = function <T>(item: T, index: number): Array<T> {
        const items = this.slice();
        items.splice(index, 0, item);
        return items;
    }
}


if (!Array.prototype.binarySearch) {
    Array.prototype.binarySearch = function <T>(target: T, comparator?: (a: T, b: T) => number) {
        var l = 0,
            h = this.length - 1,
            m, comparison;
        comparator = comparator || function (a, b) {
            return (a < b ? -1 : (a > b ? 1 : 0)); /* default comparison method if one was not provided */
        };
        while (l <= h) {
            m = (l + h) >>> 1; /* equivalent to Math.floor((l + h) / 2) but faster */
            comparison = comparator(this[m], target);
            if (comparison < 0) {
                l = m + 1;
            } else if (comparison > 0) {
                h = m - 1;
            } else {
                return m;
            }
        }
        return ~l;
    }
}

if (!Array.prototype.binaryInsert) {
    Array.prototype.binaryInsert = function <T>(target: T, duplicate?: boolean, comparator?: (a: T, b: T) => number) {
        const items = this.slice();
        var i = items.binarySearch(target, comparator);
        if (i >= 0) { /* if the binarySearch return value was zero or positive, a matching object was found */
            if (!duplicate) {
                return { index: i, items: items };
            }
        } else { /* if the return value was negative, the bitwise complement of the return value is the correct index for this object */
            i = ~i;
        }
        items.splice(i, 0, target);
        return { index: i, items: items };
    }
}

if (!Array.prototype.distinct) {
    Array.prototype.distinct = function <T>() {
        return this.filter((value, index, array) => array.indexOf(value) === index);
    }
}

if (!Array.prototype.distinctBy) {
    Array.prototype.distinctBy = function <T>(field: (item:T) => any) {
        return this.filter((a, i) => this.findIndex((s) => field(a) === field(s)) === i)
    }
}


if (!Array.prototype.flat) {
    Array.prototype.flat = function <T>(depth: number = Infinity) {
        return this.reduce<T[]>(function (flat, toFlatten) {
            return flat.concat((Array.isArray(toFlatten) && (depth > 1)) ? toFlatten.flat(depth - 1) : toFlatten);
        }, []);
    }
}

if(!Array.prototype.shuffle) {
    Array.prototype.shuffle = function <T>() {
        let q = this.slice();
        for (let i = q.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [q[i], q[j]] = [q[j], q[i]];
        }
        return q;
    }
}
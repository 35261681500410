import { observable } from 'mobx';
import { Updatable } from '../../../../..';

export type CourseItemTaskFieldListItemOption = {
    key: string;
    label: string;
}

export default class CourseItemTaskFieldListSettings extends Updatable {
    constructor(s: Partial<CourseItemTaskFieldListSettings>) {
        super();
        s && this.update(s);
    }

    @observable options: CourseItemTaskFieldListItemOption[];


    update(s: Partial<CourseItemTaskFieldListSettings>, allowUndefined = false) {
        super.update(s, allowUndefined);
    }

    static fromJson(json: any) {
        return new CourseItemTaskFieldListSettings({
            options: [],
            ...json
        });
    }
}

import { observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Entity } from '..';

export default class ChatBotPhrase extends Entity {
    constructor(item?: Partial<ChatBotPhrase>) {
        super(item);
        if (item) this.update(item);
    }

    @observable phrase: string;
    @observable executionTimes: number;
    @observable createdDate: Moment;


    update(bot: Partial<ChatBotPhrase>) {
        super.update(bot);
    }

    static fromJson(json: any): ChatBotPhrase {
        return new ChatBotPhrase({
            id: json.id,
            softDeleted: json.softDeleted,
            phrase: json.phrase,
            executionTimes: json.executionTimes,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
        });
    }

}
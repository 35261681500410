import { observable, action } from 'mobx';
import moment from 'moment';
import Entity, { ISerializable } from '../Entity';
import School from '../school/school';
import BaseFilter from '../BaseFilter';

export default class Organization extends Entity implements ISerializable {
    constructor(company?: Partial<Organization>) {
        super(company);
        if (company) this.update(company);
    }

    @observable name: string = '';
    @observable domain: string = '';
    @observable textAgreement: string = '';

    schools: School[] = [];


    @action update(org: Partial<Organization>, allowUndefined = false) {
        super.update(org, allowUndefined);
    }

    toJson() {
        return {
            ...this
        };
    }

    static fromJson(json: any): Organization {
        return new Organization({
            ...json,
            schools: json.schools ? json.schools?.map((x: any) => School.fromJson(x)) : [],
        });
    }
}



export class OrganizationFilter extends BaseFilter<Organization> {
    constructor(filter?: Partial<OrganizationFilter>) {
        super(filter);
        if (filter) this.update(filter);
    }

    exсludeId: string;
    name: string;
    domain: string;

    update(changes: Partial<OrganizationFilter>) {
        super.update(changes);
    }
}
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Checkbox, Table as ATable, TableProps as ATableProps } from 'antd';
import { observer, Observer } from 'mobx-react';
import { SorterResult, TableCurrentDataSource, ColumnType, Key, TablePaginationConfig, } from 'antd/lib/table/interface';
import styles from './Table.module.scss';
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { IListStore, ListStore, SelectableListStore } from "../../stores";
import { Context } from "../AccelProvider/AccelProvider";
import { BaseFilter, Entity } from "../../models";
import { SortingDirection } from "../../enums";
import { combineClasses } from "../../utils";

export type OmittedTableProps<T extends Entity> = Omit<ATableProps<T>, 'columns' | 'dataSource' | 'loading' | 'size'>;
type TableProps<T extends Entity, TFilter extends BaseFilter<T>> = OmittedTableProps<T> & {
    columns: ColumnType<T>[],
    store: IListStore<T> | ListStore<T, BaseFilter<T>> | SelectableListStore<T, TFilter>,
    dataSource?: T[];
    selectable?: boolean;
    useObservableCell?: boolean;
    filter?: React.ReactNode;
    filterSticky?: boolean;
    disablePagination?: boolean,
    showSizeChanger?: boolean,
    autoFetch?: boolean,
    hidePaginationOnePage?: boolean,
    size?: SizeType | 'xs';
    rowBordered?: boolean;
    loading?: boolean;
}

function Table<T extends Entity>(
    props: TableProps<T, BaseFilter<T>>
) {
    const { loc } = useContext(Context);

    useEffect(() => {
        if (props.selectable === true && !(props.store instanceof SelectableListStore))
            console.error('[Table] Store must be an instance of SelectableListStore');
    }, [props.selectable, props.store]);

    const disablePagination = useMemo(() => {
        const filter = props.store.filter;
        return props.disablePagination === true ||
            (props.hidePaginationOnePage !== false && filter.pageTotal == 1)
    }, [props.disablePagination, props.hidePaginationOnePage, props.store.filter]);

    const pagination = useMemo<TablePaginationConfig | false>(() => {
        if (disablePagination) return false;
        const filter = props.store.filter;
        return {
            showSizeChanger: props.showSizeChanger ?? true,
            showTotal: (total, range) => {
                return `${range[0]}-${range[1]} ${loc.word('Global.of')} ${total}`;
            },
            total: filter.itemsTotal,
            pageSize: filter.take,
            current: filter.page
        };
    }, [disablePagination, props.store.filter]);

    const handleTableChange = useCallback((pagination: TablePaginationConfig,
        filters: Record<string, (Key | boolean)[] | null>,
        sorter: SorterResult<T> | SorterResult<T>[],
        extra: TableCurrentDataSource<T>) => {

        // page with this.props.store.updateFilter
        switch (extra.action) {
            case 'paginate':
                const take = pagination.pageSize ?? props.store.filter.take;
                const page = pagination.current ?? props.store.filter.page;
                props.store.filter.update({ take, page });
                break;
            case 'sort':
                props.store.filter.update({
                    // dirty hack
                    sortName: (sorter as SorterResult<T>).field as string,
                    sortType: (sorter as SorterResult<T>).order == "ascend" ? SortingDirection.asc : SortingDirection.desc,
                });
                break;
        }
        props.store.filter.update({ filters });
        props.onChange?.(pagination, filters, sorter, extra);
        if (props.autoFetch !== false)
            props.store.fetch();
    }, [props.autoFetch, props.store, props.onChange]);

    const columns = useMemo(() => {
        return props.useObservableCell == false
            ? props.columns
            : props.columns.map(x => !x.render ? x :
                {
                    ...x,
                    render: (a: any, b: T, c: number) => {
                        return <Observer>{() => <>{x.render!(a, b, c)}</>}</Observer>;
                    }
                });
    }, [props.useObservableCell, props.columns]);

    return <>
        {props.filter &&
            <div className={combineClasses(styles.filter, 'p-7 bg-white')}
                data-sticky={props.filterSticky ?? true}>
                {props.filter}
            </div>}
        <ATable<T>
            {...props}
            className={combineClasses(props.className,
                props.size == 'xs' ? styles.xsmall : undefined,
                props.rowBordered === false ? styles.noRowBorder : undefined)}
            //@ts-ignore
            size={props.size}
            rowKey={props.rowKey ? props.rowKey : x => x.id}
            columns={columns}
            scroll={{ scrollToFirstRowOnChange: true, ...props.scroll, }}
            dataSource={props.dataSource ?? props.store.items}
            loading={props.loading ?? props.store.fetching}
            pagination={pagination}
            onChange={(p, f, s, e) => handleTableChange(p, f, s, e)}
            rowSelection={props.selectable === true
                ? props.rowSelection ?? {
                    columnTitle: () => null,
                    renderCell: (checked, item) => {
                        const store = props.store as SelectableListStore<T, BaseFilter<T>>;
                        return <Observer>
                            {() => <Checkbox checked={store.selectedAll || store.isSelected(item.id)}
                                disabled={store.selectedAll}
                                onChange={(e) => {
                                    store.toggleItem(item.id);
                                }} />}
                        </Observer>;
                    }
                }
                : undefined}
        />
    </>;
}
export default observer(Table);

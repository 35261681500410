import { action, computed, observable } from 'mobx';
import { Updatable } from '../Entity';

export enum CertFontFamily {
    Roboto = 'Roboto',
    'Open Sans' = 'OpenSans',
    Montserrat = 'Montserrat',
    Jost = 'Jost',
    'Noto Sans' = 'NotoSans',
    'PT Sans' = 'PTSans',
    Ubuntu = 'Ubuntu',
    Raleway = 'Raleway',
    Rubik = 'Rubik',
    'Inter Tight' = 'InterTight',
    'JetBrains Mono' = 'JetBrainsMono',
    'Playfair Display' = 'PlayfairDisplay',
    Mulish = 'Mulish',
    Finlandica = 'Finlandica',
    Piazzolla = 'Piazzolla',
    Nunito = 'Nunito',
    Merriweather = 'Merriweather',
    'Exo 2' = 'Exo2',
    'Roboto Condensed' = 'RobotoCondensed',
    'IBM Plex Sans' = 'IBMPlexSans'
}

export enum CertLayout {
    A4Portrait = 'a4Portrait',
    A4Landscape = 'a4Landscape',
    Custom = 'custom'
}

export default class CertSettings extends Updatable {
    constructor(settings?: Partial<CertSettings>) {
        super();
        if (settings) this.update(settings);
    }

    /**
     * a4_portrait = 1240 x 1754 (aspect ratio 0.7)
     * a4_landscape = 1754 x 1240 (aspect ratio 1.41)
     */
    @observable layout: CertLayout;
    @observable customWidth: number;
    @observable customHeight: number;

    @computed get width() {
        switch (this.layout) {
            case CertLayout.A4Portrait:
                return 1240;
            case CertLayout.A4Landscape:
                return 1754;
            case CertLayout.Custom:
                return this.customWidth;
        }
    }

    @computed get height() {
        switch (this.layout) {
            case CertLayout.A4Portrait:
                return 1754;
            case CertLayout.A4Landscape:
                return 1240;
            case CertLayout.Custom:
                return this.customHeight;
        }
    }

    @computed get size() {
        return {
            width: this.width,
            height: this.height
        };
    }

    @computed get aspectRatio() {
        return this.width / this.height;
    }

    @observable bgImageUrl: string | null;
    @observable bgColor: string;
    @observable fontFamily: CertFontFamily;

    toJson() {
        return {
            ...this,
        };
    }

    clone(changes?: Partial<CertSettings>): CertSettings {
        return new CertSettings({
            ...this,
            ...changes
        });
    }

    @action update(c: Partial<CertSettings>, allowUndefined = false) {
        super.update(c, allowUndefined);
    }

    hasChanges(settings: CertSettings) {
        return this.bgColor != settings.bgColor
            || this.bgImageUrl != settings.bgImageUrl
            || this.fontFamily != settings.fontFamily
            || this.layout != settings.layout
            || this.customHeight != settings.customHeight
            || this.customWidth != settings.customWidth;
    }

    static fromJson(json: any): CertSettings {
        return new CertSettings({
            ...json
        });
    }

    static default(changes?: Partial<CertSettings>): CertSettings {
        return new CertSettings({
            layout: CertLayout.A4Landscape,
            customHeight: 1754,
            customWidth: 1240,
            bgColor: '#FFFFFF',
            bgImageUrl: null,
            fontFamily: CertFontFamily.Roboto,
            ...changes
        });
    }
}

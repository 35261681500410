import { action, observable } from 'mobx';
import { Entity } from '..';
import moment, { type Moment } from 'moment';
import { KeyGenerator } from '../../utils';

export type GlossaryWord = {
    id: string;
    word: string;
}

export default class Glossary extends Entity {
    constructor(glossary?: Partial<Glossary>) {
        super(glossary);
        if (glossary) this.update(glossary);
    }

    @observable title: string;
    @observable description: string;
    @observable words: GlossaryWord[];
    @observable wordsCount: number;

    @observable createdDate: Moment;
    @observable updatedDate?: Moment;

    @action addWord(defaultWord = '') {
        this.words.push({ id: KeyGenerator.generate(6), word: defaultWord });
    }

    @action addWords(words: string[]) {
        this.words.push(...words.map(x => ({ id: KeyGenerator.generate(6), word: x })));
    }

    @action removeWord(key: string) {
        const indexToRemove = this.words.findIndex(item => item.id === key);
        if (indexToRemove !== -1)
            this.words.splice(indexToRemove, 1);
    }

    @action update(glossary: Partial<Glossary>, allowUndefined = false) {
        super.update(glossary, allowUndefined);
    }

    static fromJson(json: any): Glossary {
        const glossary = new Glossary({
            id: json.id,
            title: json.title,
            description: json.description,
            words: json.words ? json.words.map((x: string) => ({ id: KeyGenerator.generate(6), word: x })) : [],
            wordsCount: json.wordsCount,

            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,

            softDeleted: json.softDeleted
        });

        return glossary;
    }

}
import React, { useEffect, useRef } from 'react';
import { TimeSpan } from '../../utils';
import { useTimer } from '../hooks/useTimer';
import './Highlighter.module.scss';

type Props = {
    active: boolean;
    duration?: TimeSpan;
    scrollIntoView?: boolean;
    scrollOptions?: ScrollIntoViewOptions;
    children: React.ReactNode;
    className?: string;
}
const Highlighter: React.FC<Props> = ({ active, duration, scrollIntoView, scrollOptions, children, className }) => {

    const ref = useRef<HTMLDivElement>(null);
    const timer = useTimer();

    useEffect(() => {
        if (!ref.current) return;

        if (!active) {
            ref.current?.classList.remove('highlight');
            return;
        }

        if (scrollIntoView)
            ref.current.scrollIntoView(scrollOptions);

        ref.current.classList.add('highlight');
        if (duration)
            timer.set(() => ref.current?.classList.remove('highlight'), duration);

        return () => timer?.clear();
    }, [active, scrollIntoView, duration, scrollOptions]);

    return <div ref={ref} className={className}>
        {children}
    </div>;
}

export default Highlighter;
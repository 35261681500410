import { Updatable } from '../../..';

export default class StripeSettings extends Updatable {
    public secretKey: string;
    public publishableKey: string;
    public isWidgetModeEnabled: boolean;
    public convertToUsd: boolean;

    constructor(settings?: Partial<StripeSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<StripeSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { Entity, VideoDomain } from "../..";
import { action, observable } from "mobx";

export default class VideoAccess extends Entity {
    constructor(access?: Partial<VideoAccess>) {
        super();
        if (access) this.update(access);
    }

    @observable coursesAccess: boolean;
    @observable sitesAccess: boolean;
    @observable linkAccess: boolean;
    
    @observable createdDate: Date;
    @observable updatedDate: Date;

    @observable domains: VideoDomain[];

    @action update(access: Partial<VideoAccess>) {
        super.update(access);
    }

    static fromJson(json: any): VideoAccess {
        return new VideoAccess({
            ...json,
            domains: json.videoAccessDomainLinks ? json.videoAccessDomainLinks.map((i: any) => VideoDomain.fromJson(i.videoDomain)) : [],
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }
}
import { Updatable } from '../../..';
import { PaymentGatewayPaymentMethodType, PaymentGatewayPaymentObjectType, PaymentGatewayTaxType, PaymentGatewayTaxationType } from '../PaymentGatewayInfo';

export default class RoboKassaSettings extends Updatable {
    public mrchLogin: string;
    public mrchPassword1: string;
    public mrchPassword2: string;
    public enableKassa: boolean = false;
    public isTest: boolean = false;
    public taxation: PaymentGatewayTaxationType = PaymentGatewayTaxationType.Osn;
    public tax: PaymentGatewayTaxType = PaymentGatewayTaxType.None;
    public paymentObject: PaymentGatewayPaymentObjectType = PaymentGatewayPaymentObjectType.Payment;
    public paymentMethod: PaymentGatewayPaymentMethodType = PaymentGatewayPaymentMethodType.FullPayment;

    update(entity: Partial<RoboKassaSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { observable, action } from 'mobx';
import { Entity, AccelFile, Category } from '..';

export default class Library extends Entity {
    constructor(lib?: Partial<Library>) {
        super(lib);
        if (lib) this.update(lib);
    }

    @observable name: string;
    @observable shortDescription: string;

    @observable createdDate: Date;
    @observable updatedDate: Date;

    @observable isPublished: boolean;
    @observable freeAccess: boolean;
    @observable itemCount: number;
    @observable gamificationEnabled: boolean;

    @observable categories: Category[];

    @observable primaryImage: AccelFile | null;
    @observable images: AccelFile[];
    
    @observable studentCount: number;

    clone(): Library {
        return new Library({
            ...this,
            primaryImage: this.primaryImage ? this.primaryImage.clone() : null,
            images: this.images.slice(),
        })
    }

    static fromJson(json: any): Library {
        return new Library({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            primaryImage: json.primaryImage ? AccelFile.fromJson(json.primaryImage.file) : null,
            images: json.images ? json.images.map((x: any) => AccelFile.fromJson(x.file)) : [],
            categories: json.categoriesLinks ? json.categoriesLinks.map((x: any) => Category.fromJson(x.category)) : [],
        });
    }

    @action
    update(course: Partial<Library>) {
        super.update(course);
    }
}


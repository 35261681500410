import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from "antd";
import { observer } from "mobx-react";
import { HubConnectionState } from '@microsoft/signalr';
import { Context } from '../AccelProvider/AccelProvider';
import { BaseHubClient } from '../../hub-client';
import { TimeSpan } from '../../utils';

const HubStatusNotificationKey: string = 'hub_status_notification_key';

type HubConnectionStatusProps = {
    hub: BaseHubClient;
    useDescription?: boolean;
    delay?: TimeSpan;
}
const HubConnectionStatus: React.FC<HubConnectionStatusProps> = ({ hub, delay, useDescription }) => {
    const ctx = useContext(Context);
    const loc = ctx.loc;
    const [visible, setVisible] = useState(false);
    const stop = useRef<boolean>(false);
    const timer = useRef<any>();

    useEffect(() => {
        switch (hub.state) {
            case HubConnectionState.Connected:
                clearTimeout(timer.current);
                stop.current = false;
                setVisible(false);
                break;
            case HubConnectionState.Reconnecting:
                if (stop.current) return;
                if (delay) {
                    timer.current = setTimeout(() => {
                        setVisible(true);
                    }, delay.milliseconds);
                } else {
                    setVisible(true);
                }
                break;
        }
    }, [hub.state]);

    useEffect(() => {
        if (visible) {
            notification.warning({
                key: HubStatusNotificationKey,
                placement: 'bottomLeft',
                icon: <LoadingOutlined />,
                message: loc.word('HubConnection.reconnectingLabel', { default: 'Connecting to the server' }),
                description: useDescription === true ? `${loc.word('Trying to reconnect')}...` : null,
                duration: 0,
                onClose: () => {
                    setVisible(false);
                    stop.current = true;
                }
            });
        } else {
            notification.close(HubStatusNotificationKey);
        }
    }, [visible]);

    return null;
}
export default observer(HubConnectionStatus);

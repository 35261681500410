import Api from '../..';
import { CreateFileManagerDirectoryRequest, CreateFileManagerDirectoryResponse } from "./types/create-directory";
import { CreateFileManagerItemRequest, CreateFileManagerItemResponse } from "./types/create-item";
import { FetchFileManagerItemsRequest, FetchFileManagerItemsResponse } from "./types/fetch";
import { FetchFileManagerItemRequest, FetchFileManagerItemResponse } from './types/fetch-item';
import { MoveFileManagerItemsRequest, MoveFileManagerItemsResponse } from './types/move-items';
import { RemoveFileManagerItemRequest, RemoveFileManagerItemResponse } from "./types/remove-file";
import { UpdateFileManagerItemRequest, UpdateFileManagerItemResponse } from "./types/update-item";

export default class FileManagerApi {
	constructor(private api: Api) { }

	public async fetchItems(request: FetchFileManagerItemsRequest) {
		return await this.api.execute(request, FetchFileManagerItemsResponse);
	}

	public async fetchItem(request: FetchFileManagerItemRequest) {
		return await this.api.execute(request, FetchFileManagerItemResponse);
	}

	public async remove(request: RemoveFileManagerItemRequest) {
		return await this.api.execute(request, RemoveFileManagerItemResponse);
	}

	public async createItem(request: CreateFileManagerItemRequest) {
		return await this.api.execute(request, CreateFileManagerItemResponse);
	}

	public async createDirectory(request: CreateFileManagerDirectoryRequest) {
		return await this.api.execute(request, CreateFileManagerDirectoryResponse);
	}

	public async updateItem(request: UpdateFileManagerItemRequest) {
		return await this.api.execute(request, UpdateFileManagerItemResponse);
	}

	public async moveItems(request: MoveFileManagerItemsRequest) {
		return await this.api.execute(request, MoveFileManagerItemsResponse);
	}

	restore = (ids: string[]) => this.api.post<string[]>(`/file-manager/restore`, { ids });
}

export enum ChatChannelType {
    Accel = 'accel',
    Vk = 'vk',
    Tg = 'tg',
    Vb = 'vb',
    Fb = 'fb',
    Wazzup24 = 'wazzup24',
    Wa = 'wa',
    Ig = 'ig',
    Line = 'line',
}

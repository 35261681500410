import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { AccelContext, Context } from '../AccelProvider/AccelProvider';
import { AccelFile } from '../../models';

export type ImageResizeOptions = {
    w?: number;
    h?: number;
}

type ImageProps = {
    src?: string | null;
    file?: AccelFile;
    cloudKey?: string;
    width?: string | number;
    height?: string | number;
    fit: 'fill' | 'contain' | 'cover' | 'none';
    alt?: string;
    placeholder?: ReactNode;
    className?: string;
    loading?: 'eager' | 'lazy';
    resize?: ImageResizeOptions;
    /** use image preview (blob) if exists */
    usePreview?: boolean;
    ctx?: AccelContext;
    onLoad?: () => void;
    onError?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
}
const Image: React.FC<ImageProps> =
    ({ fit, alt, file, height, width, src, cloudKey, placeholder, className, loading, resize, usePreview, ctx,
        onError, onMouseEnter, onMouseLeave, onClick, onLoad }: ImageProps) => {
        const { fileProvider } = ctx ?? useContext(Context);
        const [success, setSuccess] = useState(true);
        const [url, setUrl] = useState(src ?? fileProvider.getUrl(file!));

        useEffect(() => {
            let url = src ?? (cloudKey
                ? fileProvider.getUrlByKey(cloudKey!)
                : fileProvider.getUrl(file!, usePreview ?? false));
            if (url?.startsWith('http') && resize) {
                try {
                    const parsedUrl = new URL(url);
                    if (resize.h) parsedUrl.searchParams.append('d', `h${resize.h}`);
                    else if (resize.w) parsedUrl.searchParams.append('d', `w${resize.w}`);
                    url = parsedUrl.toString();
                } catch { }
            }
            setUrl(url);
        }, [file, src, resize, usePreview, cloudKey]);

        const handleError = useCallback(() => {
            setSuccess(false);
            onError?.();
        }, []);

        const getImageStyle = useCallback(() => {
            return {
                width: width,
                height: height,
                objectFit: fit
            };
        }, [width, height, fit]);

        if ((!success || !url) && placeholder)
            return <>{placeholder}</>;

        return <img
            loading={loading}
            src={url}
            style={getImageStyle()}
            alt={alt}
            onLoad={() => onLoad?.()}
            onError={() => handleError()}
            onMouseEnter={() => onMouseEnter?.()}
            onMouseLeave={() => onMouseLeave?.()}
            className={className}
            onClick={() => onClick?.()}
        />;
    }
export default Image;

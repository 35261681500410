import Api from ".";
import { HttpStatusCode } from "../enums";
import { BaseFilter, Entity } from "../models";
import { ISerializable } from "../models/Entity";
import { KeyGenerator, TimeSpan } from "../utils";
import { toOptionalObject } from "./core/optional";
import { BadApiResult, OkApiResult } from "./core/result";

export type CrudApiSettings<TEntity extends Entity & ISerializable> = {
    endpoint: string;
    defaultFields?: string;
    serialize?: (e: Partial<TEntity>) => any;
    parse: (e: any) => TEntity;
}

export default class CrudApi<TEntity extends Entity & ISerializable> {
    constructor(protected baseApi: Api, protected settings: CrudApiSettings<TEntity>) {
    }

    create<T = string>(e: TEntity, parse?: (json: any) => T) {
        return this.baseApi.post<T>(`${this.settings.endpoint}`, this.settings.serialize ? this.settings.serialize(e) : e.toJson(), parse);
    }
    fetch = <TFilter extends BaseFilter<TEntity> = BaseFilter<TEntity>>(fields?: string, filter?: TFilter) => this.baseApi.getList<TEntity, TFilter>(`${this.settings.endpoint}`, fields ?? this.settings.defaultFields, filter as any,
        (json: any) => ({
            filter: new BaseFilter<TEntity>(json.filter) as TFilter,
            items: json.items.map((e: any) => this.settings.parse(e)) as TEntity[],
        }));
    fetchItem = (id: string, fields?: string) => this.baseApi.get<TEntity>(`${this.settings.endpoint}/${id}`, fields ?? this.settings.defaultFields, this.settings.parse);
    save(e: Partial<TEntity> & Pick<TEntity, 'id'>) {
        return this.baseApi.put(`${this.settings.endpoint}`, this.settings.serialize ? this.settings.serialize(e) : { ...toOptionalObject({ ...e }), id: e.id });
    }
    delete<T = void>(id: string, parse?: (json: any) => T) {
        return this.baseApi.delete(`${this.settings.endpoint}/${id}`, parse);
    }
}

export type CrudApiMockSettings<TEntity extends Entity & ISerializable> = {
    items: TEntity[];
    fetchDelay?: TimeSpan;
}
export class CrudApiMock<TEntity extends Entity & ISerializable> {
    constructor(protected baseApi: Api, protected settings: CrudApiMockSettings<TEntity>) {
        if (this.settings.fetchDelay === undefined)
            this.settings.fetchDelay = TimeSpan.fromMs(300);
    }

    async create(e: TEntity, parse?: (json: any) => BaseFilter<TEntity>) {
        console.logDev('[ApiMock] create', e);
        this.settings.items = [...this.settings.items, e];
        return new OkApiResult(KeyGenerator.generate(5));
    }

    async fetch(fields?: string | undefined, filter?: BaseFilter<TEntity>) {
        console.logDev('[ApiMock] fetch', fields, filter);
        const res = new OkApiResult({
            filter: new BaseFilter(filter),
            items: this.settings.items
        });
        if (this.settings.fetchDelay) {
            return new Promise((resolve) => {
                setTimeout(() => resolve(res), this.settings.fetchDelay!.milliseconds);
            });
        }
        return res;
    }

    fetchItem = async (id: string, fields?: string) => {
        console.logDev('[ApiMock] fetchItem', id, fields);
        const item = this.settings.items.find(x => x.id === id) ?? null;
        return !item ? new BadApiResult(HttpStatusCode.BadRequest, null) : new OkApiResult(item);
    }

    async save(e: Partial<TEntity> & Pick<TEntity, 'id'>) {
        console.logDev('[ApiMock] save', e);
        const item = this.settings.items.find(x => x.id === e.id);
        item && item.update(e);
        return new OkApiResult();
    }

    async delete<T = void>(id: string, parse?: (json: any) => T) {
        console.logDev('[ApiMock] delete', id);
        this.settings.items = this.settings.items.filter(x => x.id !== id);
        return new OkApiResult();
    }
}
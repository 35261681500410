export enum PurchaseOrderStatus {
    Created = 'created',
    CheckoutData = 'checkoutData',
    Payment = 'payment',
    PartiallyPaid = 'partiallyPaid',
    Paid = 'paid',
    PreparingShipment = 'preparingShipment',
    Shipped = 'shipped',
    Fulfilled = 'fulfilled',
    Canceled = 'canceled',
    Refund = 'refund'
}

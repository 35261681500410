import { isEmpty } from "../../../utils";
import { ApplicationSettings } from "./ApplicationSettings";

export default class MetaConversionsApplicationSettings extends ApplicationSettings {

    constructor(s?: Partial<MetaConversionsApplicationSettings>) {
        super();
        if (s) this.update(s);
    }

    public accessToken: string;
    public id: string;
    public name: string;
    public forms: boolean;
    public orders: boolean;
    public siteView: boolean;

    public siteIds: string[];

    get isValid() {
        return !isEmpty(this.accessToken) && this.hasId;
    }

    get hasId() {
        return !isEmpty(this.id) && !isEmpty(this.name);
    }

    update(entity: Partial<MetaConversionsApplicationSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    static fromJson(json: any): MetaConversionsApplicationSettings {
        return new MetaConversionsApplicationSettings(json);
    }
}
import React, { useMemo } from "react";
import { Progress } from 'antd';
import { AccelFile } from '../../../../models';
import { FileListSize, FileListViewType } from '../../Files';
import styles from './FileProgress.module.scss';

type Props = {
    view: FileListViewType;
    file: AccelFile;
    size: FileListSize;
    progress: number;
}
const FileProgress: React.FC<Props> = ({ view, file, size, progress }) => {

    const width = useMemo(() => {
        if (view == 'grid')
            switch (size) {
                case 'xs': return 30;
                case 'sm': return 40;
                case 'md': return 50;
                case 'lg': return 60;
                case 'xl': return 70;
            }
        switch (size) {
            case 'xs':
            case 'sm': return 30;
            case 'md': return 35;
            case 'lg': return 40;
            case 'xl': return 50;
        }

    }, [view, size]);

    return <Progress width={width} type="circle" percent={progress} className={styles.file_progress} />;
}

export default FileProgress;
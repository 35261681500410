import { DependencyList, EffectCallback, useEffect } from "react";
import { useDetectFirstRender } from "./useDetectFirstRender";

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
    const isFirst = useDetectFirstRender();

    useEffect(() => {
        if (!isFirst)
            return effect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
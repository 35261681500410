import React, { useContext, useCallback, useState, useEffect } from "react";
import { type Moment } from 'moment';
import { AccelContext, Context } from '../AccelProvider/AccelProvider';
import type { DateTimeFormat } from '../../utils/converters/DateTimeConverter';
import { DateTimeConverter } from '../../utils/converters/DateTimeConverter';

type DateTimeProps = {
    date: Date | Moment,
    /**
     * LT  1:30 PM
     * 
     * LTS 1:30:05 PM
     * 
     * L 11/25/2021
     * 
     * l    11/25/2021
     * 
     * LL    November 25, 2021
     * 
     * ll    Nov 25, 2021
     * 
     * LLL   November 25, 2021 1:30 PM
     * 
     * lll   Nov 25, 2021 1:30 PM
     * 
     * LLLL  Thursday, November 25, 2021 1:30 PM
     * 
     * llll  Thu, Nov 25, 2021 1:30 PM
     */
    format?: DateTimeFormat;
    customFormat?: string;
    fromNow?: boolean;
    calendar?: boolean;
    className?: string;
    ctx?: AccelContext;
}

const DateTime: React.FC<DateTimeProps> = (props) => {
    const { lang } = props.ctx ?? useContext(Context);

    const getDate = useCallback(() => {
        if (props.fromNow === true) return DateTimeConverter.fromNow(props.date, lang);
        if (props.calendar === true) return DateTimeConverter.calendar(props.date, lang);
        return DateTimeConverter.format(props.date, lang, props.format ?? props.customFormat);
    }, [props]);

    const [date, setDate] = useState(getDate());

    useEffect(() => {
        setDate(getDate());
    }, [props]);

    return <span className={props.className} title={DateTimeConverter.format(props.date, lang)}>{date}</span>;
}
export default DateTime;
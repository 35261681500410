import { Entity } from '../..';
import { computed, action } from 'mobx';
import { ScenarioNodePortButtonPayload, ScenarioNodePortExpiredButtonPayload, ScenarioNodePortPayloadFactory } from './ScenarioNodePortPayloadFactory';
import { KeyGenerator } from '../../../utils';

export type ScenarioNodePortType = 'button' | 'expireButton' | 'custom';

export class ScenarioNodePort extends Entity {
    constructor(port?: Partial<ScenarioNodePort>) {
        super();
        if (port) this.update(port);
    }

    type: ScenarioNodePortType;
    executionCount: number;
    position: number;
    payload: any;

    // dummy fields
    _isNew: boolean;

    @computed get title() {
        switch (this.type) {
            case 'button': return this.buttonPayload?.title;
            case 'expireButton':
            default:
                return null;
        }
    }

    @computed get buttonPayload() {
        if (this.type === 'button')
            return this.payload as ScenarioNodePortButtonPayload;
        return null;
    }
    set buttonPayload(value: ScenarioNodePortButtonPayload | null) {
        this.payload = value;
    }

    @computed get expiredButtonPayload() {
        if (this.type === 'expireButton')
            return this.payload as ScenarioNodePortExpiredButtonPayload;
        return null;
    }
    set expiredButtonPayload(value: ScenarioNodePortExpiredButtonPayload | null) {
        this.payload = value;
    }

    @action
    update(entity: Partial<ScenarioNodePort>) {
        super.update(entity);
    }

    clone(changes?: Partial<ScenarioNodePort>) {
        return new ScenarioNodePort({ ...this, ...changes });
    }

    static default(type: ScenarioNodePortType, position: number = 0) {
        const port = new ScenarioNodePort({
            // will be removed on api request
            id: KeyGenerator.generate(5),
            _isNew: true,
            type,
            executionCount: 0,
            position
        });
        switch (type) {
            case 'button':
                port.payload = new ScenarioNodePortButtonPayload({ type: 'default' });
                break;
            case 'expireButton':
                port.payload = new ScenarioNodePortExpiredButtonPayload({ value: 24, measure: 'hours' });
                break;
            default:
                console.warn('unknown port type');
        }
        return port;
    }

    static fromJson(json: any): ScenarioNodePort {
        return new ScenarioNodePort({
            ...json,
            payload: json.payloadJson ? ScenarioNodePortPayloadFactory.fromJson(JSON.parse(json.payloadJson), json.type) : null,
        });
    }
}

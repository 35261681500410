
export enum QuizItemType {
    // primary
    TextField = 'textField',
    TextArea = 'textArea',
    Number = 'number',
    Phone = 'phone',
    Email = 'email',
    Url = 'url',
    Checkbox = 'checkbox',
    DropdownList = 'dropdownList',
    MultiSelect = 'multiSelect',
    MonoSelect = 'monoSelect',
    HiddenField = 'hiddenField',
    Rating = 'rating',
    UploadFile = 'uploadFile',
    DateTime = 'dateTime',
    Slider = 'slider',
    NameField = 'nameField',

    // additional
    Header = 'header',
    Paragraph = 'paragraph',
    Separator = 'separator',
    Indent = 'indent',
    Image = 'picture',
    Video = 'video',
    Social = 'socialButton'
}

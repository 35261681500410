import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { AccelFile, Entity, Site, Video } from '..';
import { TimeSpan } from '../../utils';
import { FileUploaderHandler } from '../../components/FileUploader/FileUploader';

export type FileManagerItemBreadcrumbItem = {
    id: string;
    parentId: string;
    name: string;
	softDeleted: boolean;
}
export type FileManagerSection = 'fileSystem' | 'videos' | 'widgets';
export enum FileManagerItemType {
	Directory = 'directory',
	File = 'file'
}
export default class FileManagerItem extends Entity {
	constructor(item?: Partial<FileManagerItem>) {
		super(item);
		if (item) this.update(item);
	}
	@observable name: string;
	@observable file?: AccelFile;
	@observable video?: Video;
	@observable type: FileManagerItemType;
	@observable createdDate: Moment;
	@observable updatedDate: Moment;
	@observable parentId: string | null;
	@observable site?: Site;
	@observable sectionType: FileManagerSection;
	@observable size: number;
	@observable duration: TimeSpan;
	@observable folderCount: number;
	@observable itemCount: number;
	breadcrumbs: FileManagerItemBreadcrumbItem[];

	@computed get isDir() {
		return this.type == FileManagerItemType.Directory;
	}

	@computed get totalItemCount() {
		return (this.folderCount ?? 0) + (this.itemCount ?? 0);
	}

	// dummy fields
	_key: string;
	/**
	 * Handler ref to cancel (abort) all uploading videos
	 * ps. Maybe should stored somewhere else (e.g. in FileManagerStore)
	 */
	_uploadHandler: FileUploaderHandler | null;
	@observable _renaming: boolean = false;

	static fromJson(json: any): FileManagerItem {
		return new FileManagerItem({
			...json,
			createdDate: json.createdDate ? moment(json.createdDate) : undefined,
			updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
			file: json.file ? AccelFile.fromJson(json.file) : undefined,
			video: json.video ? Video.fromJson(json.video) : undefined,
			site: json.site ? Site.fromJson(json.site) : undefined,
			duration: json.duration ? TimeSpan.fromSeconds(json.duration) : undefined,
			breadcrumbs: json.path ? json.path : []
		});
	}

	@action update(item: Partial<FileManagerItem>) {
		super.update(item);
	}
}

import { action, computed, observable } from "mobx";
import { Updatable, WebinarRegistrationEmailField, WebinarRegistrationField, WebinarRegistrationTermOfServiceField } from "../..";
import { WebinarRegistrationFieldType } from "../../../enums";

export default class WebinarRegistrationSettings extends Updatable {
    constructor(settings?: Partial<WebinarRegistrationSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable fields: WebinarRegistrationField[] = [];

    @computed get needEmailConfirmation() {
        const email = this.email;
        return email != null && email.required && email.settings?.confirmation;
    }

    @computed get email(): WebinarRegistrationEmailField | undefined {
        return this.fields.find(i => i.type == WebinarRegistrationFieldType.Email) as WebinarRegistrationEmailField;
    }

    @computed get hasEmailField() { return this.email != undefined; }

    @computed get phone() {
        return this.fields.find(i => i.type == WebinarRegistrationFieldType.Phone);
    }

    @computed get firstName() {
        return this.fields.find(i => i.type == WebinarRegistrationFieldType.FirstName);
    }

    @computed get lastName() {
        return this.fields.find(i => i.type == WebinarRegistrationFieldType.LastName);
    }

    @computed get birthday() {
        return this.fields.find(i => i.type == WebinarRegistrationFieldType.Birthday);
    }

    @computed get gender() {
        return this.fields.find(i => i.type == WebinarRegistrationFieldType.Gender);
    }

    @computed get termsOfService(): WebinarRegistrationTermOfServiceField | undefined {
        //@ts-ignore
        return this.fields.find(i => i.type == WebinarRegistrationFieldType.TermOfService);
    }

    @action update(settings: Partial<WebinarRegistrationSettings>) {
        super.update(settings);
    }

    hasChanges(settings: WebinarRegistrationSettings) {
        if (this.fields.length != settings.fields.length)
            return true;

        let index = 0;
        for (const field of this.fields) {
            const compareFieldIndex = settings.fields.findIndex(i => i.type == field.type);
            if (compareFieldIndex < 0 || index != compareFieldIndex)
                return true;
            if (field.hasChanged(settings.fields[compareFieldIndex]))
                return true;
            index++;
        }
        return false;
    }

    copy(): WebinarRegistrationSettings {
        return new WebinarRegistrationSettings({
            ...this,
            fields: this.fields.map(i => i.copy()),
        });
    }

    static fromJson(json: any) {
        return new WebinarRegistrationSettings({
            ...json,
            fields: json.fields
                ? json.fields.map((i: any) => {
                    switch (i.type as WebinarRegistrationFieldType) {
                        case WebinarRegistrationFieldType.Email:
                            return WebinarRegistrationEmailField.fromJson(i);
                        case WebinarRegistrationFieldType.TermOfService:
                            return WebinarRegistrationTermOfServiceField.fromJson(i);
                        default:
                            return WebinarRegistrationField.fromJson(i);
                    }
                })
                : [],
        });
    }
}
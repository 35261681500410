import { TicksPer, HoursPer, MinutesPer, SecondsPer, MillisecondsPer } from './HowMany';

export enum TimeUnit {
    Ticks,
    Milliseconds,
    Seconds,
    Minutes,
    Hours,
    Days
}

export class TimeUnitUtils {

    static toMilliseconds(
        value: number,
        units: TimeUnit = TimeUnit.Milliseconds): number {
        // noinspection FallThroughInSwitchStatementJS

        switch (units) {
            //@ts-ignore
            case TimeUnit.Days:
                value *= HoursPer.Day;
            //@ts-ignore
            case TimeUnit.Hours:
                value *= MinutesPer.Hour;
            //@ts-ignore
            case TimeUnit.Minutes:
                value *= SecondsPer.Minute;
            //@ts-ignore
            case TimeUnit.Seconds:
                value *= MillisecondsPer.Second;
            case TimeUnit.Milliseconds:
                return value;
            case TimeUnit.Ticks:
                return value / TicksPer.Millisecond;
            default:
                throw new Error("Invalid TimeUnit.");
        }
    }

    static fromMilliseconds(
        ms: number,
        units: TimeUnit) {
        switch (units) {
            case TimeUnit.Days:
                return ms / MillisecondsPer.Day;
            case TimeUnit.Hours:
                return ms / MillisecondsPer.Hour;
            case TimeUnit.Minutes:
                return ms / MillisecondsPer.Minute;
            case TimeUnit.Seconds:
                return ms / MillisecondsPer.Second;
            case TimeUnit.Milliseconds:
                return ms;
            case TimeUnit.Ticks:
                return ms * TicksPer.Millisecond;
            default:
                throw new Error("Invalid TimeUnit.");
        }
    }
}
export default TimeUnit;
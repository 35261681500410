import React from 'react';
import { TimeSpan } from '../../../../../../utils';
import Duration from '../../../../../Duration/Duration';
import styles from './VoiceRecordDuration.module.scss';

type Props = {
    duration: TimeSpan;
}
const VoiceRecordDuration: React.FC<Props> = ({ duration }) => {

    return <div className='flex align-center gap-5'>
        <div className={styles.record_dot}></div>
        <Duration duration={duration} />
    </div>;
}
export default VoiceRecordDuration;
import { observable, action } from 'mobx';
import { Entity } from '..';
import { Currency, Lang } from '../../enums';

export default class Requisite extends Entity {
    constructor(requisite?: Partial<Requisite>) {
        super(requisite);
        if (requisite) this.update(requisite);
    }

    @observable requisites: string;
    @observable licensor: string;

    @observable language: Lang;
    @observable currency: Currency;

    @observable createdDate: Date;
    @observable updatedDate: Date;

    static fromJson(json: any): Requisite {
        return new Requisite({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }

    static default(lang: Lang) {
        return new Requisite({
            requisites: '',
            licensor: '',
            language: lang,
            currency: lang === Lang.ru
                ? Currency.RUB
                : Currency.USD
        });
    }

    @action
    update(doc: Partial<Requisite>) {
        super.update(doc);
    }
}


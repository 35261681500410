import { action, observable } from "mobx";
import moment, { type Moment } from "moment";
import { Entity, Pipeline, PipelineStageItem } from "../..";


export declare type PipelineStageType = 'custom' | 'crmCompleted' | 'crmNotCompleted';

export default class PipelineStage extends Entity {
    constructor(p?: Partial<PipelineStage>) {
        super(p);
        if (p) this.update(p);
    }

    @observable name: string;
    @observable type: PipelineStageType;
    @observable inArchive: boolean;

    @observable lexorankPosition: string;

    @observable color: string;
    @observable pipeline: Pipeline;

    @observable totalCount: number;
    @observable totalAmount: number;

    createdDate: Moment;
    updatedDate?: Moment;

    @observable items: PipelineStageItem[];

    // dummy fields
    _newlyAdded: boolean;

    static fromJson(json: any) {
        return new PipelineStage({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            pipeline: json.pipeline ? Pipeline.fromJson(json.pipeline) : undefined,
            items: json.items ? json.items.map(PipelineStageItem.fromJson) : undefined
        });
    }

    @action
    update(session: Partial<PipelineStage>) {
        super.update(session);
    }

    static default() {
        return new PipelineStage({
            type: 'custom',
            color: '#0693E3',
            inArchive: false,
            totalAmount: 0,
            totalCount: 0,
            _newlyAdded: true
        });
    }
}
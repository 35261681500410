import { action, observable } from "mobx";
import moment, { type Moment } from "moment";
import { Entity, SitePage, SharedSettingsExport } from "../..";
import { SharedSettingsEntityType } from "../EntityType";

export default class SharedSettingsExportEntity extends Entity {
    constructor(en?: Partial<SharedSettingsExportEntity>) {
        super();
        if (en)
            this.update(en);
    }

    @observable type: SharedSettingsEntityType;
    @observable export: SharedSettingsExport;

    @observable sitePage: SitePage | null;

    createdDate: Moment;

    @action
    update(session: Partial<SharedSettingsExportEntity>) {
        super.update(session);
    }

    toJson() {
        return {
            sitePageId: this.sitePage?.id,
        };
    }

    static fromJson(json: any) {
        return new SharedSettingsExportEntity({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            export: json.export ? SharedSettingsExport.fromJson(json.export) : undefined,
            sitePage: json.sitePage ? SitePage.fromJson(json.sitePage) : undefined,
        });
    }
}

import { AccelFile } from '../../models';

export type FileProviderConfig = {
    cloudFront: string,
    videoCloudFront: string,
}
export default class FileProvider {
    constructor(private config: FileProviderConfig) {
    }

    public getUrl(file: AccelFile, usePreview = false) {
        if (!file) return undefined;
        if (usePreview == true && file.previewImage) return file.previewImage;
        if (usePreview == true && file.previewAudio) return file.previewAudio;
        if (file.isExternalFile)
            return file.url;
        return this.getUrlByKey(file.cloudKey);
    }

    public getVideoUrl(file: AccelFile) {
        if (!file) return undefined;
        if (file.isExternalFile)
            return file.url;
        return this.getVideoUrlByKey(file.cloudKey);
    }

    public getUrlByKey(cloudKey: string) {
        if (!cloudKey) return undefined;
        return `${this.config.cloudFront}/${cloudKey}`;
    }

    public getVideoUrlByKey(cloudKey: string) {
        if (!cloudKey) return undefined;
        if (cloudKey.startsWith('http'))
            return cloudKey;
        return `${this.config.videoCloudFront}/${cloudKey}`;
    }
}
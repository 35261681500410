export enum HoursPer {
    Day = 24
}

export enum MinutesPer {
    Hour = 60,
    Day = MinutesPer.Hour * HoursPer.Day
}
export enum SecondsPer {
    Minute = 60,
    Hour = SecondsPer.Minute * MinutesPer.Hour,
    Day = SecondsPer.Hour * HoursPer.Day
}

export enum MillisecondsPer {
    Second = 1000,
    Minute = MillisecondsPer.Second * SecondsPer.Minute,
    Hour = MillisecondsPer.Minute * MinutesPer.Hour,
    Day = MillisecondsPer.Hour * HoursPer.Day
}

export enum TicksPer {
    Millisecond = 10000,
    Second = TicksPer.Millisecond * MillisecondsPer.Second,
    Minute = TicksPer.Second * SecondsPer.Minute,
    Hour = TicksPer.Minute * MinutesPer.Hour,
    Day = TicksPer.Hour * HoursPer.Day
}
import { observable, action, computed } from 'mobx';
import { AccelFile, Admin, BaseFilter, Contact, Course, CourseItem, Entity, Library, LibraryItem, Product, Scenario, ScenarioNode } from '..';
import { ISerializable } from '../Entity';
import moment from 'moment';
import { FieldDateConditionType } from '../BaseFilter';

export type GamificationLeaderboardDurationType = 'day' | 'month' | 'year';
export type GamificationPointsExpireDurationType = 'day' | 'month' | 'year' | 'endless';
export type GamificationPointsIconType = 'system' | 'custom';

export class Gamification extends Entity implements ISerializable {
    constructor(g?: Partial<Gamification>) {
        super();
        if (g) this.update(g);
    }

    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable enabled: boolean;

    @observable shopEnabled: boolean;

    @observable leaderboardEnabled: boolean;
    @observable leaderboardTakeCount: number;
    @observable leaderboardDuration: number;
    @observable leaderboardDurationType: GamificationLeaderboardDurationType

    @observable pointsName: string
    @observable pointsShortName: string
    @observable pointsExpireDuration: number;
    @observable pointsExpireDurationType: GamificationPointsExpireDurationType;

    @observable pointsIconType: GamificationPointsIconType;
    @observable pointsIconCloudKey: string;
    @observable pointsCustomIcon: AccelFile | null;

    @observable achievementStyle: GamificationAchievementStyle;

    @observable scenario: Scenario;

    toJson() {
        return {
            ...this,
            pointsCustomIconId: this.pointsCustomIcon === null ? null : this.pointsCustomIcon?.id,
            // skip some fields just in case,
            pointsCustomIcon: undefined,
            scenario: undefined
        }
    }

    clone() {
        return new Gamification({
            ...this,
            pointsCustomIcon: this.pointsCustomIcon?.clone(),
        });
    }

    @action update(partnership: Partial<Gamification>, allowUndefined = false) {
        super.update(partnership, allowUndefined);
    }

    hasChanges(settings: Gamification) {
        return this.achievementStyle !== settings.achievementStyle
            || this.pointsName !== settings.pointsName
            || this.pointsShortName !== settings.pointsShortName
            || this.pointsExpireDuration !== settings.pointsExpireDuration
            || this.pointsExpireDurationType !== settings.pointsExpireDurationType
            || this.pointsIconType !== settings.pointsIconType
            || this.pointsIconCloudKey !== settings.pointsIconCloudKey
            || this.pointsCustomIcon?.id !== settings.pointsCustomIcon?.id
            || this.leaderboardEnabled !== settings.leaderboardEnabled
            || this.leaderboardTakeCount !== settings.leaderboardTakeCount
            || this.leaderboardDuration !== settings.leaderboardDuration
            || this.leaderboardDurationType !== settings.leaderboardDurationType;
    }

    static fromJson(json: any): Gamification {
        const gamification = new Gamification({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            pointsCustomIcon: json.pointsCustomIcon ? AccelFile.fromJson(json.pointsCustomIcon) : undefined
        });

        if (gamification.scenario)
            gamification.scenario.update({ gamification });

        return gamification;
    }
}


export type GamificationAchievementType = 'system' | 'custom';
export type GamificationAchievementStyle = 'one' | 'two' | 'three';
export type GamificationAchievementImageType = 'system' | 'custom';
export type GamificationAchievementSubtype = 'student' // 25 Студент - начал курс,
    | 'firstStep' // 25 Первый Шаг - За завершение первого урока
    | 'activeStudent' // 30 Активный студент - За один день завершил 2 урока
    | 'nightStudent' // За учебу в ночное время. С 00 до 4 утра по времени школы
    | 'earlyBird' // За учебу в ранние утренние часы. С 4 до 8 утра по времени школы
    | 'firstTest' // Успешно пройден первый тест
    | 'firstTask' // Успешно сдано первое задание
    | 'buyer1' // 1 покупка
    | 'sprint' // За учебу без перерывов 7 и более дней
    | 'buyer2' // 3 покупки
    | 'halfMarathonRunner' // За учебу без перерывов 14 и более дней
    | 'buyer3' // 5 покупок
    | 'marathonRunner' // За учебу без перерывов 30 и более дней
    | 'serialStudent' // За прохождение 3 курсов на платформе

export class GamificationAchievement extends Entity implements ISerializable {
    constructor(g?: Partial<GamificationAchievement>) {
        super();
        if (g) this.update(g);
    }

    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable enabled: boolean;
    @observable points: number;
    @observable title: string;
    @observable description: string;

    @observable type: GamificationAchievementType;
    @observable subtype: GamificationAchievementSubtype;
    @observable style: GamificationAchievementStyle;
    @observable imageType: GamificationAchievementImageType;
    @observable imageCloudKey: string;

    @observable customImage: AccelFile | null;
    @observable systemAchievement: GamificationAchievement | null;

    gamificationId: string;
    achievedDate: moment.Moment | null;
    count: number;

    @computed get isOverrided() {
        return this.systemAchievement !== null;
    }

    @computed get isSystem() {
        return this.type == 'system';
    }

    get executionMethod() {
        switch (this.subtype) {
            case 'firstStep':
            case 'firstTask':
            case 'firstTest':
                return 'once-material';
            case 'activeStudent':
                return 'many';
            default:
                return 'once';
        }
    }

    toJson() {
        return {
            ...this,
            customImageId: this.customImage === null ? null : this.customImage?.id,
            systemAchievementId: this.systemAchievement === null ? null : this.systemAchievement?.id,
            // skip some fields just in case,
            customImage: undefined,
            systemAchievement: undefined
        }
    }

    clone(changes?: Partial<GamificationAchievement>): GamificationAchievement {
        return new GamificationAchievement({
            ...this,
            customImage: this.customImage?.clone(),
            systemAchievement: this.systemAchievement?.clone(),
            ...changes
        });
    }

    @action update(partnership: Partial<GamificationAchievement>, allowUndefined = false) {
        super.update(partnership, allowUndefined);
    }

    hasChanges(settings: GamificationAchievement) {
        return Object.keys(settings).some(key => settings[key] !== this[key]);
    }

    static fromJson(json: any): GamificationAchievement {
        return new GamificationAchievement({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            customImage: json.customImage ? AccelFile.fromJson(json.customImage) : null,
            systemAchievement: json.systemAchievement ? GamificationAchievement.fromJson(json.systemAchievement) : null,
            achievedDate: json.achievedDate ? moment(json.achievedDate) : undefined
        });
    }
}

export class GamificationAchievementFilter extends BaseFilter<GamificationAchievement> {
    constructor(filter?: Partial<GamificationAchievementFilter>) {
        super();
        if (filter) this.update(filter);
    }

    style: GamificationAchievement['style'];
    gamificationId: string;
    showAll: boolean;
    enabled: boolean;

    update(changes: Partial<GamificationAchievementFilter>) {
        super.update(changes);
    }
}


export type GamificationProductContentType = 'noContent' | 'product' | 'bonusBalance';

export class GamificationProduct extends Entity implements ISerializable {
    constructor(g?: Partial<GamificationProduct>) {
        super();
        if (g) this.update(g);
    }

    @observable title: string;
    @observable description: string;

    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable enabled: boolean;

    @observable points: number;
    @observable useAvailableCount: boolean;
    @observable availableCount: number;

    @observable contentType: GamificationProductContentType;
    @observable bonusAmount: number;

    @observable image: AccelFile | null;
    @observable product: Product | null;

    gamification: Gamification;

    @observable scenario: Scenario;
    purchasedDate: moment.Moment | null;

    toJson() {
        return {
            ...this,
            imageId: this.image === null ? null : this.image?.id,
            productId: this.product === null ? null : this.product?.id,
            gamificationId: this.gamification === null ? null : this.gamification?.id,
            // skip some fields just in case,
            image: undefined,
            product: undefined,
            scenario: undefined,
            gamification: undefined
        }
    }

    clone(): GamificationProduct {
        return new GamificationProduct({
            ...this,
            image: this.image?.clone(),
            product: this.product?.clone(),
            scenario: this.scenario?.copy(),
            gamification: this.gamification?.clone(),
        });
    }

    @action setContentType(type: GamificationProductContentType) {
        this.contentType = type;
    }

    @action update(product: Partial<GamificationProduct>, allowUndefined = false) {
        super.update(product, allowUndefined);
    }

    hasChanges(settings: GamificationProduct) {
        return this.title !== settings.title
            || this.description !== settings.description
            || this.points !== settings.points
            || this.useAvailableCount !== settings.useAvailableCount
            || this.availableCount !== settings.availableCount
            || this.contentType !== settings.contentType
            || this.bonusAmount !== settings.bonusAmount
            || this.image?.id !== settings.image?.id
            || this.product?.id !== settings.product?.id
            || this.enabled !== settings.enabled;
    }

    static fromJson(json: any): GamificationProduct {
        const product = new GamificationProduct({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            image: json.image ? AccelFile.fromJson(json.image) : null,
            product: json.product ? Product.fromJson(json.product) : null,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            gamification: json.gamification ? Gamification.fromJson(json.gamification) : undefined,
            purchaseDate: json.purchaseDate ? moment(json.purchaseDate) : undefined,
        });

        if (product.scenario)
            product.scenario.update({ gamificationProduct: product });

        return product;
    }
}

export class GamificationProductFilter extends BaseFilter<GamificationProduct> {
    constructor(filter?: Partial<GamificationProductFilter>) {
        super();
        if (filter) this.update(filter);
    }

    gamificationId: string;

    update(changes: Partial<GamificationProductFilter>) {
        super.update(changes);
    }
}

export type GamificationTransactionType = 'deposit' | 'withdrawal';
export enum GamificationTransactionDurationType {
    Day = 'day',
    Month = 'month',
    Year = 'year'
}
export enum GamificationTransactionValidityType {
    Endless = 'endless',
    Duration = 'duration',
    Date = 'date'
}

export class GamificationTransaction extends Entity implements ISerializable {
    constructor(g?: Partial<GamificationTransaction>) {
        super();
        if (g) this.update(g);
    }

    @observable points: number;
    @observable type: GamificationTransactionType;
    @observable comment: string;

    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable expiredDate?: moment.Moment;

    @observable contact: Contact;
    @observable adminCreator: Admin;
    @observable achievement: GamificationContactAchievement;
    @observable product: GamificationProduct;

    @observable scenarioNode: ScenarioNode;

    @observable duration: number | null;
    @observable durationType: GamificationTransactionDurationType | null;
    @observable validUntilDate: moment.Moment | null;

    toJson() {
        return {
            ...this,
            contactId: this.contact === null ? null : this.contact?.id,
            studentAchievementId: this.achievement === null ? null : this.achievement?.id,
            productId: this.product === null ? null : this.product?.id,
            scenarioNodeId: this.scenarioNode === null ? null : this.scenarioNode?.id,
            // skip some fields just in case,
            contact: undefined,
            adminCreator: undefined,
            achievement: undefined,
            product: undefined,
            scenarioNode: undefined
        }
    }

    clone(): GamificationTransaction {
        return new GamificationTransaction({
            ...this,
            contact: this.contact?.clone(),
            adminCreator: this.adminCreator?.clone(),
            achievement: this.achievement?.clone(),
            product: this.product?.clone(),
            scenarioNode: this.scenarioNode?.clone(),
        });
    }

    @action update(partnership: Partial<GamificationTransaction>, allowUndefined = false) {
        super.update(partnership, allowUndefined);
    }

    hasChanges(settings: GamificationTransaction) {
        return Object.keys(settings).some(key => settings[key] !== this[key]);
    }

    static fromJson(json: any): GamificationTransaction {
        return new GamificationTransaction({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            expiredDate: json.expiredDate ? moment(json.expiredDate) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : null,
            adminCreator: json.adminCreator ? Admin.fromJson(json.adminCreator) : null,
            achievement: json.studentAchievement ? GamificationContactAchievement.fromJson(json.studentAchievement) : null,
            product: json.product ? GamificationProduct.fromJson(json.product) : null,
            course: json.course ? Course.fromJson(json.course) : null,
            courseItem: json.courseItem ? CourseItem.fromJson(json.courseItem) : null,
            scenarioNode: json.scenarioNode ? ScenarioNode.fromJson(json.scenarioNode) : null
        });
    }
}

export class GamificationTransactionFilter extends BaseFilter<GamificationTransaction> {
    constructor(filter?: Partial<GamificationTransactionFilter>) {
        super();
        if (filter) this.update(filter);
    }

    gamificationId: string;

    createdDateCondition: FieldDateConditionType | null;
    createdDateFrom: moment.Moment | null;
    createdDateTo: moment.Moment | null;

    studentId: string;
    studentIds: string[];

    type: GamificationTransactionType;
    pointsFrom: number;
    pointsTo: number;

    productId: string;

    update(changes: Partial<GamificationTransactionFilter>) {
        super.update(changes);
    }
}

export class GamificationContactAchievement extends Entity {
    constructor(g?: Partial<GamificationContactAchievement>) {
        super();
        if (g) this.update(g);
    }

    @observable course: Course | null;
    @observable courseItem: CourseItem | null;
    @observable library: Library | null;
    @observable libraryItem: LibraryItem | null;
    @observable achievement: GamificationAchievement;
    @observable points: number;

    static fromJson(json: any): GamificationContactAchievement {
        return new GamificationContactAchievement({
            ...json,
            achievement: json.achievement ? GamificationAchievement.fromJson(json.achievement) : undefined,
            course: json.course ? Course.fromJson(json.course) : null,
            courseItem: json.courseItem ? CourseItem.fromJson(json.courseItem) : null,
            library: json.library ? Library.fromJson(json.library) : null,
            libraryItem: json.libraryItem ? LibraryItem.fromJson(json.libraryItem) : null,
        });
    }
}

export class GamificationLeaderAchievement extends Entity {
    constructor(g?: Partial<GamificationLeaderAchievement>) {
        super();
        if (g) this.update(g);
    }

    title: string;
    subtype: string;
    imageCloudKey: string;
    count: number;

    static fromJson(json: any): GamificationLeaderAchievement {
        return new GamificationLeaderAchievement({
            ...json,
        });
    }
}

export class GamificationLeader extends Entity implements ISerializable {
    constructor(g?: Partial<GamificationLeader>) {
        super();
        if (g) this.update(g);
    }

    @observable place: number;
    @observable score: number;

    @observable createdDate: moment.Moment;

    @observable contact: Contact;
    @observable achievements: GamificationLeaderAchievement[];

    toJson() {
        return {
            ...this,
        }
    }

    clone(): GamificationLeader {
        return new GamificationLeader({
            ...this,
            contact: this.contact?.clone(),
        });
    }

    @action update(partnership: Partial<GamificationLeader>, allowUndefined = false) {
        super.update(partnership, allowUndefined);
    }

    static fromJson(json: any): GamificationLeader {
        return new GamificationLeader({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : null,
            achievements: json.achievements ? json.achievements.map(GamificationLeaderAchievement.fromJson) : null,
        });
    }
}

export class GamificationLeaderFilter extends BaseFilter<GamificationTransaction> {
    constructor(filter?: Partial<GamificationLeaderFilter>) {
        super();
        if (filter) this.update(filter);
    }

    gamificationId: string;

    registrationDateCondition: FieldDateConditionType | null;
    registrationDateFrom: moment.Moment | null;
    registrationDateTo: moment.Moment | null;

    studentIds: string[];

    scoreFrom: number;
    scoreTo: number;

    update(changes: Partial<GamificationLeaderFilter>) {
        super.update(changes);
    }
}


import { observable, action } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Entity, BillingPlanPrice, BillingPlanPriceType } from '../..';
import { Currency } from '../../../enums';

export default class BillingPlan extends Entity {
    constructor(plan?: Partial<BillingPlan>) {
        super(plan);
        if (plan) this.update(plan);
    }

    @observable name: string;
    @observable description: string;
    @observable prices: Map<BillingPlanPriceType, BillingPlanPrice[]>;
    currency: Currency;
    defaultTrialDaysCount: number;
    defaultCreditAmount: number;
    minimumExpensesAmount: number;
    createdDate: Moment;
    updatedDate?: Moment;
    isPrimary: boolean;
    isAgreementRequired: boolean;
    fallbackPlan: BillingPlan | null;

    static fromJson(json: any): BillingPlan {
        const prices: Map<BillingPlanPriceType, BillingPlanPrice[]> = new Map();
        if (json.billingPlanPriceJson) {
            const raw = JSON.parse(json.billingPlanPriceJson);
            Object.keys(raw).forEach(key => prices.set(key as BillingPlanPriceType, raw[key].map(BillingPlanPrice.fromJson)),);
        }
        return new BillingPlan({
            id: json.id,
            name: json.name,
            description: json.description,
            currency: json.currency,
            defaultTrialDaysCount: json.defaultTrialDaysCount,
            defaultCreditAmount: json.defaultCreditAmount,
            minimumExpensesAmount: json.minimumExpensesAmount,
            prices: prices,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            isPrimary: json.isPrimary,
            isAgreementRequired: json.isAgreementRequired,
            fallbackPlan: json.fallbackPlan ? BillingPlan.fromJson(json.fallbackPlan) : null,
        });
    }

    @action
    update(plan: Partial<BillingPlan>, allowUndefined = false) {
        super.update(plan, allowUndefined);
    }

    clone() {
        return new BillingPlan({
            ...this,
            prices: new Map(this.prices),
        });
    }
}


import { observable, action } from "mobx";
import { Updatable } from "..";

export default class SiteCounters extends Updatable {
    constructor(siteCounters?: Partial<SiteCounters>) {
        super();
        if (siteCounters) this.update(siteCounters);
    }

    @observable today: number;
    @observable sevenDays: number;
    @observable thirtyDays: number;

    static fromJson(json: any): SiteCounters {
        return new SiteCounters({
            today: json?.today ? json.today : 0,
            sevenDays: json?.sevenDays ? json.sevenDays : 0,
            thirtyDays: json?.thirtyDays ? json.thirtyDays : 0
        });
    }

    @action
    update(siteCounters: Partial<SiteCounters>) {
        super.update(siteCounters);
    }
}
import { ResponseType as AxiosResponseType } from 'axios'

export enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
}

export default class ApiRequest {
    public method: HttpMethod = HttpMethod.GET;
    public url: string;
    public fields: string;
    public get resultFields(): string | null {
        return this.fields
            ? this.fields.replace(new RegExp('\r?\n| ', 'g'), '')
            : null;
    }
    public body: any;
    /**
     * Auto update user on every token change
     */
    public autoUpdateUser: boolean = true;
    /**
     * Can use without [Authorized] attribute
     */
    public authorized: boolean = true;
    /**
     * Api version
     */
    public v: number = 1;
    public cancellationToken: AbortController;
    public headers: any = null;
    public responseType: AxiosResponseType = 'json';

    constructor(options?: Partial<ApiRequest>) {
        Object.assign(this, options);
        this.cancellationToken = new AbortController();
    }

    public cancel(): void {
        if (!this.cancellationToken)
            return;
        this.cancellationToken.abort('Cancelled by user');
    }
}
import React, { useCallback, useContext, useEffect } from "react";
import { AccelFile, FileType } from '../../models';
import styles from './FileDropzone.module.scss';
import { Context } from '../AccelProvider/AccelProvider';
import { useDropzone } from 'react-dropzone';
import { UploadOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { FileSize, toMimeTypesString } from '../../utils';
// DO NOT IMPORT FROM index.ts
import withHandler from '../HOC/withHandler';

export type FileDropzoneHandler = {
    open: () => void;
}

type FileDropzoneProps = {
    accept?: string;
    acceptTypes?: FileType[];
    onUpload?: (files: AccelFile[]) => void;
    onCancel?: () => void;
    minSize?: FileSize;
    maxSize?: FileSize;
    maxFiles?: number;
    disabled?: boolean;
    multiple?: boolean;
    noClick?: boolean;
    defaultOpen?: boolean;
    noDrag?: boolean;
    className?: string;
    placeholder?: React.ReactNode;
    handle?: FileDropzoneHandler;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({ accept, acceptTypes, children, onUpload, onCancel, disabled, multiple, minSize, maxSize, maxFiles, noClick, noDrag, defaultOpen, className, handle, placeholder }) => {
    const { loc } = useContext(Context);

    const acceptMimeTypes = useMemo(() => {
        if (accept) return accept;
        if (!acceptTypes) return undefined;
        return toMimeTypesString(acceptTypes, true);
    }, [accept, acceptTypes]);

    const onDrop = useCallback((files: File[]) => {
        if (files.length == 0)
            return;
        const accelFiles = files.map((x: any) => AccelFile.fromFile(x));
        onUpload?.(accelFiles);
    }, []);

    const onFileDialogCancel = useCallback(() => {
        onCancel?.();
    }, []);

    const { open, getRootProps, getInputProps } = useDropzone({
        onDrop,
        onFileDialogCancel,
        multiple,
        accept: acceptMimeTypes,
        disabled,
        minSize: minSize?.bytes,
        maxSize: maxSize?.bytes,
        maxFiles,
        noClick,
        noDrag
    })

    useEffect(() => {
        if (handle)
            handle.open = () => open();

        if (defaultOpen)
            open();
    }, []);

    return <div {...getRootProps()} className={className}>
        <input {...getInputProps()} />
        {children ??
            <div className={styles.dropzone_holder} data-disabled={disabled} data-testid='file-dropzone'>
                <UploadOutlined className='mr-20' />
                <span>{placeholder ?? loc.word('FileUploader.uploadMsg', { default: 'Click or drag files to upload' })}</span>
            </div>}
    </div>;
}
FileDropzone.defaultProps = {
    multiple: true
}
// TODO replace with withHandler2
export default withHandler<FileDropzoneProps, FileDropzoneHandler>(FileDropzone);
import { observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Entity, School } from '..';
import Scenario from '../scenario/scenario';
import ChatBotPhrase from './ChatBotPhrase';

export enum ChatBotType {
    Simple = 'simple',
    Webinar = 'webinar'
}
export default class ChatBot extends Entity {
    constructor(item?: Partial<ChatBot>) {
        super(item);
        if (item) this.update(item);
    }

    @observable name: string;
    @observable description: string;
    @observable createdDate: Moment;
    @observable type: ChatBotType;
    @observable scenario: Scenario;
    @observable number: number;
    @observable phrases: ChatBotPhrase[];
    @observable school: School | null;

    update(bot: Partial<ChatBot>) {
        super.update(bot);
    }

    copy() {
        return new ChatBot({
            id: this.id,
            name: this.name,
            description: this.description,
            type: this.type,
            phrases: this.phrases.slice()
        });
    }

    static fromJson(json: any): ChatBot {
        const chatBot = new ChatBot({
            id: json.id,
            softDeleted: json.softDeleted,
            name: json.name,
            description: json.description,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            type: json.type,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            number: json.number,
            phrases: json.keyPhrases ? json.keyPhrases.map(ChatBotPhrase.fromJson) : undefined,
            school: json.school ? School.fromJson(json.school) : null,
        });
        if (chatBot.scenario) chatBot.scenario.update({ chatBot });
        return chatBot;
    }

}
// import { Console } from 'node:console';
import { isDev } from '../../enums';

export { }

declare global {
    interface Console {
        logDev(message?: any, ...optionalParams: any[]): void;
    }
}
if (!console.logDev) {
    console.logDev = function <T>(message?: any, ...optionalParams: any[]): void {
        if (isDev)
            console.log(message, optionalParams);
    }
}
import { action, computed, observable } from 'mobx';
import { isEmpty } from '../../../../utils';
import QuizItemSettings from './QuizItemSettings';


export class SeparatorItemSettings extends QuizItemSettings {
    constructor(s?: Partial<SeparatorItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable text: string;
    @observable borderThickness: number;
    @observable borderColor: string;
    @observable align: 'left' | 'center' | 'right';

    @computed get hasText() {
        return !isEmpty(this.text);
    }

    @action update(q: Partial<SeparatorItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<SeparatorItemSettings>) {
        return new SeparatorItemSettings({
            ...QuizItemSettings.default,
            text: '',
            borderThickness: 1,
            borderColor: '#0000000f',
            align: 'center',
            ...settings
        });
    }

    clone() {
        return new SeparatorItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return SeparatorItemSettings.build(json);
    }
}

import React, { useCallback, useEffect, useRef } from 'react';

export type AudioWaveformLevel = number;

export type AudioWaveformProps = {
    levels: AudioWaveformLevel[];
    playedPercent: number;
    onSeek: (percent: number) => void;
}
export const AudioWaveform: React.FC<AudioWaveformProps> = ({ levels, playedPercent, onSeek }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const drawWave = useCallback((playedPercent = 0) => {
        const canvas = canvasRef.current!;

        const width = canvas.parentElement?.clientWidth ?? 300;
        const height = 22;
        canvas.width = width;
        canvas.height = height;

        let context = canvas.getContext('2d')!;
        const barWidth = (width / (levels.length - 1)) / 2;

        for (let i = 0; i < levels.length; i++) {
            let h = Math.max(levels[i] * height, 0.1 * height);

            const isFilled = Math.floor(playedPercent / 100 * levels.length) >= i;
            context.fillStyle = isFilled ? '#3098ff' : '#ccc';
            const x = i * barWidth * 2;
            context.fillRect(x, height - h, barWidth, h);
        }
    }, [levels]);

    const handleSeek = useCallback((e: React.MouseEvent) => {
        const rect = canvasRef.current!.getBoundingClientRect();
        const relativeX = e.clientX - rect.x;
        onSeek(relativeX / rect.width * 100);
    }, [onSeek]);

    useEffect(() => {
        drawWave(playedPercent);
    }, [levels, playedPercent]);

    return <canvas ref={canvasRef} onClick={handleSeek} width={100} height={22} className='max-w-100' />;
};

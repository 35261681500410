export default class AxlClipboard {

    static copyText(text: string) {
        try {
            if (typeof window.ClipboardItem == 'undefined') {
                navigator.clipboard.writeText(text);
                return true;
            }

            navigator.clipboard.write([new ClipboardItem({ "text/plain": new Blob([text], { type: "text/plain" }) })]);
            return true;
        }
        catch (e) {
            console.logDev(e);
            return false;
        }
    };

    /* Метод для копировния в буфер обмена текста, который получается асинхронно. 
    Метод принимает Промис, в зависимости от наличия window.ClipboardItem применяются разные способы копирвоания. 
    writeText нужен для корректной работы в Mozila, так как у нее отсутсвтует поддержка write(). Так же доступа к Clipboard не будет,
    при подключении через http, работает только при https.
    
    Так же принимаем промис, чтобы обрабатывать копирование в Safari, так как он позволяет его делать только вызывая await внутри write()
    https://wolfgangrittner.dev/how-to-use-clipboard-api-in-safari/
    */
    static async copyTextAsync(getText: Promise<string>) {
        try {
            if (typeof window.ClipboardItem == 'undefined') {
                await navigator.clipboard.writeText(await getText);
                return true;
            }

            await navigator.clipboard.write([new ClipboardItem({
                "text/plain": getText
                    .then(response => response)
                    .then(text => new Blob([text], { type: "text/plain" }))
            })]);
            return true;
        }
        catch (e) {
            console.logDev(e);
            return false;
        }
    };
}
import { Entity, Product, PurchaseOrder } from '../..';
import { computed, observable } from 'mobx';

export default class PurchaseOrderContent extends Entity {
    constructor(purchaseOrder?: Partial<PurchaseOrderContent>) {
        super(purchaseOrder);
        if (purchaseOrder) this.update(purchaseOrder);
    }

    @observable quantity: number;
    @observable priceEach: number;
    @observable savingsEach: number;

    @observable product: Product;
    @observable purchaseOrder: PurchaseOrder;

    @computed get finalPriceEach(): number {
        return this.priceEach - this.savingsEach;
    }
    @computed get totalPriceNoSavings(): number {
        return this.quantity * this.priceEach;
    }
    @computed get totalSavings(): number {
        return this.quantity * this.savingsEach;
    }
    @computed get totalPriceFinal(): number {
        return this.quantity * this.finalPriceEach;
    }

    update(product: Partial<PurchaseOrderContent>) {
        super.update(product);
    }

    copy(): PurchaseOrderContent {
        return new PurchaseOrderContent({
            id: this.id,
            quantity: this.quantity,
            priceEach: this.priceEach,
            savingsEach: this.savingsEach,
            product: this.product,
            purchaseOrder: this.purchaseOrder
        });
    }

    static fromJson(json: any): PurchaseOrderContent {
        return new PurchaseOrderContent({
            ...json,
            product: json.product ? Product.fromJson(json.product) : undefined,
            purchaseOrder: json.purchaseOrder ? PurchaseOrder.fromJson(json.purchaseOrder) : undefined
        });
    }
}
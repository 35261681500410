import React, { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { observer } from 'mobx-react';

import {
    InfoCircleOutlined
} from '@ant-design/icons';

import { notification } from 'antd';
import { DateTime, FileUploader } from '../..';
import Humanize from 'humanize-plus';
import { FileUploadStatus } from '../../../enums';
import styles from './FileGridItem.module.scss';
import { combineClasses, isEmpty } from '../../../utils';
import { FileProps } from '../Files';
import FileName from '../components/FileName/FileName';
import { FileUploaderHandler } from '../../FileUploader/FileUploader';
import FileDescriptionPopover from '../FileDescriptionPopover/FileDescriptionPopover';
import FilePreview from '../components/FilePreview/FilePreview';
import moment from 'moment';
import FileActions from '../components/FileActions/FileActions';

const FileGridItem: React.FC<FileProps> = ({ file, ...props }) => {

    const uploaderHandler = useRef<FileUploaderHandler>();
    const [progress, setProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>(file?.nativeFile != null ? FileUploadStatus.Uploading : FileUploadStatus.Uploaded);

    const retryUpload = useCallback(() => {
        setProgress(0);
        setUploadStatus(FileUploadStatus.Uploading);
    }, []);

    const handleFileReadyToUpload = useCallback(async () => {
        const res = await props.beforeUpload?.(file);
        if (res !== undefined && res === false) return;  // cancel uploading
        await uploaderHandler.current?.upload();
    }, []);

    const fileContent = useMemo(() => <div className={combineClasses(styles.fl_grid_item, 'flex align-center p-5 relative')} data-size={props.size}>

        <div className='flex flex-col flex-center flex-1 w-100'>

            <div className='relative w-100 h-100'>
                <FilePreview view='grid'
                    file={file}
                    fileProvider={props.fileProvider}
                    loc={props.loc}
                    size={props.size}
                    progress={progress}
                    uploadStatus={uploadStatus}
                    onPreview={() => props.onPreview(file)}
                    onRetryUpload={() => retryUpload()}
                    fileIconColor={props.fileIconColor}
                    className={styles.fl_grid_item__preview} />

                <div className={styles.fl_grid_item_menu}>
                    <FileActions
                        file={file}
                        theme='light'
                        size={props.size != 'xs' ? 'middle' : 'small'}
                        deletable={props.deletable}
                        downloadable={props.downloadable}
                        replaceable={props.replaceable}
                        uploadStatus={uploadStatus}
                        loc={props.loc}
                        onDownload={() => props.onDownload?.(file)}
                        onRemove={() => props.onRemove?.(file)}
                        onReplace={() => props.onReplace?.(file)}
                        onUpload={() => retryUpload()}
                        onAbort={() => uploaderHandler.current?.abort()} />
                </div>
            </div>

            <div className='w-100 ellipsis'>
                <FileName file={file}
                    editable={props.editable}
                    useExtension
                    onRename={async name => await props.onChange?.(file, { name }) ?? true} loc={props.loc}
                    className='text-center' />

            </div>

            <div className='w-100 fs-12'>
                <div className='flex flex-center ellipsis'>
                    {uploadStatus == FileUploadStatus.Error
                        ? <span className='fs-12 color-danger'>{props.loc.word('File.uploadFailedLabel', { default: 'Upload failed' })}</span>
                        : <>
                            {(props.dateCreatedVisible == true || props.descriptionVisible == true) &&
                                <span className='flex-1 mr-5'>
                                    {props.dateCreatedVisible == true && <DateTime date={file.createdDate}
                                        format={file.createdDate?.isSame(moment(), 'day') ? 'LT' : 'L'}
                                        className='color-secondary' />}

                                    {(props.descriptionVisible == true && uploadStatus == FileUploadStatus.Uploaded)
                                        && <FileDescriptionPopover
                                            loc={props.loc}
                                            file={file}
                                            onSave={async description => await props.onChange?.(file, { description }) ?? true}>
                                            <InfoCircleOutlined className={combineClasses('ml-5', !isEmpty(file.description) ? 'color-success' : undefined)} />
                                        </FileDescriptionPopover>
                                    }
                                </span>}
                            <span className='color-secondary'>{Humanize.fileSize(file.size)}</span>
                        </>}
                </div>
            </div>
        </div>
    </div>, [file, props]);

    return <>
        {fileContent}

        {uploadStatus == FileUploadStatus.Uploading &&
            <FileUploader handler={x => uploaderHandler.current = x}
                autostart={false}
                file={file}
                onReady={handleFileReadyToUpload}
                onProgress={x => setProgress(Math.min(x.percent, 90))}
                onComplete={async ({ file }) => {
                    file.update(file);
                    setUploadStatus(FileUploadStatus.Uploaded);
                    props.onUploaded?.(file);
                }}
                uploadArgs={props.uploadArgs}
                onAbort={() => {
                    if (file.nativeFile) {
                        setUploadStatus(FileUploadStatus.Error);
                        props.onAborted?.(file);
                    }
                }}
                onError={(a) => {
                    setUploadStatus(FileUploadStatus.Error);
                    notification.error({ message: props.loc.word('Error occured'), duration: 2 });
                }} />}
    </>;
}

export default observer(FileGridItem);


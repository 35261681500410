
import { BaseFilter } from '..';
import Contact from './contact';

export default class ContactFilter extends BaseFilter<Contact> {
    constructor(filter?: Partial<ContactFilter>) {
        super();
        if (filter) this.update(filter);
    }

    id?: string;
    schoolId?: string;
    email?: string;
    moderateForumThreadId: string | null;
    similarChatUserId: string;

    update(changes: Partial<ContactFilter>) {
        super.update(changes);
    }
}
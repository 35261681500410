import { BaseFilter } from '../..';
import TopicComment from './topic-comment';

export default class TopicCommentFilter extends BaseFilter<TopicComment>{
    constructor(filter?: Partial<TopicCommentFilter>) {
        super();
        if (filter) this.update(filter);
    }

    id: string;
    topicId: string;
    studentId: string;
    replyToCommentId: string;

    update(changes: Partial<TopicCommentFilter>) {
        super.update(changes);
    }
}
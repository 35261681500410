import moment, { type Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { TimeSpan } from '../../utils';

export type CountdownDuration = {
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
    span: TimeSpan
};

const useCountdown = (targetDate: Moment, onFinish: () => void) => {
    const countDownDate = useMemo(() => targetDate.valueOf(), [targetDate]);

    const [countDown, setCountDown] = useState(
        Math.max(countDownDate - moment().valueOf(), 0)
    );

    useEffect(() => {
        if (countDown == 0) return onFinish();
        const interval = setInterval(() => {
            const diff = Math.max(countDownDate - moment().valueOf(), 0);
            if (diff == 0) {
                onFinish();
                clearInterval(interval);
            }
            setCountDown(diff);

        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(TimeSpan.fromMilliseconds(countDown));
};

const msInDay = TimeSpan.fromDays(1).milliseconds;
const msInHour = TimeSpan.fromHours(1).milliseconds;
const msInMinute = TimeSpan.fromMinutes(1).milliseconds;
const msInSecond = TimeSpan.fromSeconds(1).milliseconds;

const getReturnValues = (span: TimeSpan) => {

    const days = Math.floor(span.ticks / msInDay);
    const hours = Math.floor((span.ticks % msInDay) / msInHour);
    const minutes = Math.floor((span.ticks % msInHour) / msInMinute);
    const seconds = Math.floor((span.ticks % msInMinute) / msInSecond);

    return { days, hours, minutes, seconds, span } as CountdownDuration;
};

export { useCountdown };

import { action, observable } from 'mobx';
import moment from 'moment';
import { ChatChannel, Contact, School, User } from '../..';
import { TimeSpan } from '../../../utils';
import Webinar from '../Webinar';

export enum WebinarUserRole {
    Guest = 'guest',
    Moderator = 'moderator',
    Host = 'host'
}

export default class WebinarUser extends User {
    constructor(webinar?: Partial<WebinarUser>) {
        super(webinar);
        if (webinar) this.update(webinar);
    }

    @observable role: WebinarUserRole;
    @observable contactId: string;
    @observable createdDate: Date;
    @observable updatedDate?: Date;
    @observable isBlocked: boolean;
    @observable isMuted: boolean;
    @observable isChatBlocked: boolean;
    @observable cuepoints: number[];
    @observable viewDuration: TimeSpan;
    @observable presencePercent?: number;
    @observable school: School;
    @observable initialSiteGuestSessionId: string;
    @observable webinar: Webinar;
    @observable chatChannel: ChatChannel;
    @observable chatChannels: ChatChannel[];
    @observable isTeamMember: boolean;
    @observable loginLink: string;
    @observable partner: Contact;

    @action
    update(webinar: Partial<WebinarUser>, allowUndefined: boolean = false) {
        super.update(webinar, allowUndefined);
    }

    static fromJson(json: any): WebinarUser {
        return new WebinarUser({
            ...json,
            cuepoints: json.cuepoints ?? [],
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            school: json.school ? School.fromJson(json.school) : null,
            webinar: json.webinar ? Webinar.fromJson(json.webinar) : null,
            viewDuration: json.viewDuration ? TimeSpan.fromTicks(json.viewDuration) : undefined,
            chatChannel: json.chatChannel ? ChatChannel.fromJson(json.chatChannel) : undefined,
            chatChannels: json.chatChannels ? json.chatChannels.map(ChatChannel.fromJson) : [],
            partner: json.partner ? Contact.fromJson(json.partner) : undefined
        });
    }
}

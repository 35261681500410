import React, { useCallback, useContext, useRef, useState } from "react";
import { Button, Popconfirm } from 'antd';
import { AccelFile, User } from '../../models';
import { DateTime, Loc, SpaceSeparator, UserAvatar } from '..';
import { Context } from '../AccelProvider/AccelProvider';
import CommentFormEditor from "./CommentFormEditor";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { FileListSize, FileListViewType } from "../Files/Files";
import { FileSize } from "../../utils";


export declare type CommentFormHandler = {
    focus: () => void;
    blur: () => void;
    hasUploadingFiles: () => boolean;
    cancelVoiceRecording: () => void;
    setText: (text: string) => void;
    insertText: (text: string) => void;
    setFiles: (files: AccelFile[]) => void;
    setVoices: (files: AccelFile[]) => void;
}

export type CommentFilesProps = {
    forbiddenExtensions?: string[];
    view?: FileListViewType;
    size?: FileListSize;
    draggable?: boolean;
    maxFiles?: number;
    maxFileSize?: FileSize;
}

export type CommentFormProps = {
    handlerRef?: React.MutableRefObject<CommentFormHandler | undefined>;

    author?: User;
    editing?: boolean;
    disabled?: boolean;
    sendDisabled?: boolean;

    defaultText?: string;
    defaultFiles?: AccelFile[];
    defaultVoices?: AccelFile[];

    text?: string;
    files?: AccelFile[];
    voices?: AccelFile[];

    useEmoji?: boolean;
    useVoice?: boolean;
    useFiles?: boolean;
    fileProps?: CommentFilesProps;
    /**
     * 'combined' - can send voice and text/files at the same time
     * 
     * 'split' - can send only voice or text/files
     */
    voiceMode?: 'combined' | 'split';
    audioPlayerStyle?: React.CSSProperties;

    placeholder?: string;
    emptyText?: React.ReactNode;
    textRenderer?: (text: string) => React.ReactNode;

    autoFocus?: boolean;
    autoSize?: { minRows?: number, maxRows?: number };
    maxLength?: number;
    clearAfterSending?: boolean;
    enterToSubmit?: boolean;

    className?: string;
    style?: React.CSSProperties;
    editorClassName?: string;
    editorStyle?: React.CSSProperties;
    inputClassName?: string;
    iconClassname?: string;
    borderless?: boolean;
    
    actionAddons?: (handler: CommentFormHandler) => React.ReactNode[];
    authorExtra?: (user: User) => React.ReactNode;

    onSend?: (text: string, files: AccelFile[]) => Promise<boolean>;
    onTextChange?: (text: string) => void;
    onFilesChange?: (files: AccelFile[],
        changes: {
            added?: AccelFile[];
            deleted?: AccelFile[];
        }) => void;
    onVoicesChange?: (files: AccelFile[],
        changes: {
            added?: AccelFile[];
            deleted?: AccelFile[];
        }) => void;

    // voice recording
    micDeviceId?: string;
    audioPlayerSize?: SizeType;
    onVoiceRecordingStarted?: () => void;
    onVoiceRecordingEnded?: (file: AccelFile) => void;
    onVoiceRecordingCanceled?: () => void;
}

/**
 * Comment editor with edit/remove/cancel controls and readonly mode
 */
const CommentForm: React.FC<CommentFormProps & {
    createdDate?: moment.Moment;
    updatedDate?: moment.Moment;
    canEdit?: boolean;
    canRemove?: boolean;
    author: User;
    onRemove: () => Promise<boolean>;
    onCancel?: () => void;
}> = ({ author, createdDate, updatedDate, canEdit, canRemove, onRemove, onCancel, ...props }) => {
    const ctx = useContext(Context);
    const { loc, user } = ctx;
    const formRef = useRef<CommentFormHandler>();

    const [editing, setEditing] = useState(false);
    // clone value props to support cancelling
    const [editingText, setEditingText] = useState(props.text ?? props.defaultText ?? '');
    const [editingFiles, setEditingFiles] = useState((props.files ?? props.defaultFiles ?? []).slice());
    const [editingVoices, setEditingVoices] = useState((props.voices ?? props.defaultVoices ?? []).slice());

    const edit = useCallback(() => {
        setEditing(true);
    }, []);

    const send = useCallback(async (a: string, b: AccelFile[]) => {
        if (!props.onSend) {
            console.error('CommentForm.onSend is not defined');
            return false;
        }
        const res = await props.onSend(a, b);
        if (res) setEditing(false);
        return res;
    }, [props.onSend]);

    const cancelEdit = useCallback(() => {
        // reset edited values with default values
        formRef.current?.setText(props.text ?? props.defaultText ?? '');
        formRef.current?.setFiles((props.files ?? props.defaultFiles ?? []).slice());
        formRef.current?.setVoices((props.voices ?? props.defaultVoices ?? []).slice());
        setEditing(false);
        onCancel?.();
    }, [props, onCancel]);

    return <div className="flex">
        <UserAvatar user={author} size={40} className='mr-10' />
        <div className="flex-1">
            {author.id === user?.id ? loc.word('You') : author.fullName}

            <CommentFormEditor
                {...props}
                handlerRef={formRef}
                defaultText={editingText}
                defaultFiles={editingFiles}
                defaultVoices={editingVoices}
                onSend={send}
                clearAfterSending={false}
                borderless={editing ? props.borderless : true}
                editing={editing} />

            <div className="fs-11 text-placeholder">
                <SpaceSeparator separator={"dot"} align="center">
                    {createdDate && <span>
                        <Loc word='Sent' />&nbsp;
                        <DateTime date={createdDate} />
                    </span>}
                    {updatedDate && <span>
                        <Loc word='Updated' />&nbsp;
                        <DateTime date={updatedDate} />
                    </span>}

                    {editing && <Button
                        type='link'
                        size='small'
                        className="fs-11"
                        onClick={cancelEdit}>
                        {loc.word('Global.cancel', { case: 'lower' })}
                    </Button>}

                    {(!editing && canEdit) && <Button
                        disabled={editing}
                        type='link'
                        size='small'
                        className="fs-11"
                        onClick={edit}>
                        {loc.word('Edit', { case: 'lower' })}
                    </Button>}

                    {(!editing && canRemove) &&
                        <Popconfirm title={<Loc word="Comment.delete.confirm" default="Are you sure you want to delete this comment?" />}
                            onConfirm={onRemove}>
                            <Button
                                disabled={editing}
                                type='link'
                                size='small'
                                className="fs-11">
                                {loc.word('Remove', { case: 'lower' })}
                            </Button>
                        </Popconfirm>}
                </SpaceSeparator>
            </div>
        </div>
    </div >;
};
export default CommentForm;
import { action } from 'mobx';
import { CustomFieldValue } from '../../..';
import CustomAttribute from '../CustomAttribute';

export default class CustomAttributeValue extends CustomFieldValue<CustomAttribute> {
    constructor(attr?: Partial<CustomAttributeValue>) {
        super(attr);
        if (attr) this.update(attr);
    }


    @action update(attr: Partial<CustomAttributeValue>, allowUndefined = false) {
        super.update(attr, allowUndefined);
    }

    clone(): CustomAttributeValue {
        return new CustomAttributeValue({
            id: this.id,
            customField: this.customField,
            valueString: this.valueString,
            valueNumeric: this.valueNumeric,
            valueBoolean: this.valueBoolean,
            valueDateTime: this.valueDateTime,
            valueDate: this.valueDate,
            valueList: this.valueList,
            valueMultiList: this.valueMultiList?.slice(),
        });
    }

    static fromJson(json: any) {
        const base = CustomFieldValue.fromJson(json);
        return new CustomAttributeValue({
            ...base,
            customField: json.leadAttribute ? CustomAttribute.fromJson(json.leadAttribute) : undefined
        });
    }
}
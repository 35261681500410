import { action, observable, computed } from "mobx";
import { Entity } from '..';

export default class SchoolProfileSettings extends Entity {
    constructor(settings?: Partial<SchoolProfileSettings>) {
        super(settings);
        if (settings) this.update(settings);
    }

    @observable email: boolean;
    @observable emailNeedConfirm: boolean;
    @observable blockAccessIfEmailUnconfirmed: boolean;
    @observable blockEmailChange: boolean;

    @observable phone: boolean;
    @observable phoneRequired: boolean;
    @observable phoneNeedConfirm: boolean;
    @observable blockAccessIfPhoneUnconfirmed: boolean;

    @observable firstName: boolean;
    @observable firstNameRequired: boolean;

    @observable lastName: boolean;
    @observable lastNameRequired: boolean;

    @observable middleName: boolean;
    @observable middleNameRequired: boolean;

    @observable birthday: boolean;
    @observable birthdayRequired: boolean;

    @observable gender: boolean;
    @observable genderRequired: boolean;

    clone() {
        return new SchoolProfileSettings({
            ...this
        });
    
    }

    @action update(settings: Partial<SchoolProfileSettings>) {
        super.update(settings);
    }

    static fromJson(json: any) {
        return new SchoolProfileSettings({
            ...json
        });
    }
}
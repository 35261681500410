export enum Currency {
    USD = 'usd',
    EUR = 'eur',
    RUB = 'rub',
    IDR = 'idr',
    UAH = 'uah',
    BYN = 'byn',
    KZT = 'kzt',
    TWD = 'twd',
    TRY = 'try',
    ARS = 'ars',
    AUD = 'aud',
    BGN = 'bgn',
    BRL = 'brl',
    CLP = 'clp',
    COP = 'cop',
    MXN = 'mxn',
    PEN = 'pen',
    UYU = 'uyu',
    GBP = 'gbp',
    MYR = 'myr',
    CAD = 'cad'
}
import { Cert, CertItemImageSettings, CertItemSettings, CertItemTextSettings } from '../..';
import { CertItemType } from '../CertItem';

export default class CertSettingsFactory {
    static parse(type: CertItemType, settingsJson: string): CertItemSettings {
        const settings = settingsJson ? JSON.parse(settingsJson) : {};
        switch (type) {
            case 'text':
                return new CertItemTextSettings(settings);
            case 'image':
                return new CertItemImageSettings(settings);
            default:
                return new CertItemSettings(settings);
        }
    }

    static default(type: CertItemType, cert: Cert) {
        switch (type) {
            case 'text':
                return CertItemTextSettings.default({ zIndex: cert.items.length + 1 });
            case 'image':
                return CertItemImageSettings.default();
            default:
                return CertItemTextSettings.default();
        }
    }

}

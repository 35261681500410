import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List, ListRowProps, ScrollParams } from 'react-virtualized';
import { RenderedRows } from 'react-virtualized/dist/es/List';
import { BaseFilter, Entity } from '../../models';
import { InfinityListStore } from '../../stores/InfinityListStore';
import { TimeSpan } from '../../utils';

export type ScrollData = ScrollParams & { offsetHeight: number };
export type InfinityVirtualListHandler<T> = {
    insertItem: (item: T, index: number) => void;
    swapItems: (index: number, newIndex: number) => void;
    moveItem: (index: number, newIndex: number) => void;
    updateItem: (item: T, index: number) => void;
    recalcItem: (index: number) => void;
    deleteItem: (index: number) => void;
    scrollTo: (index: number) => void;
    update: (resetMeasurerCache?: boolean) => void;
    reset: () => void;
}

type InfinityVirtualListProps<T extends Entity, TFilter extends BaseFilter<T>> = {
    store: InfinityListStore<T, TFilter>;
    rowHeight?: number;
    defaultRowHeight?: number;
    fetchDebounce?: TimeSpan;
    rowRenderer: (props: ListRowProps & { item: T | undefined, onRendered?: () => void }) => React.ReactNode;
    initialLoader?: React.ReactNode;
    minimumBatchSize?: number;
    overscanRowCount?: number;
    autoFetch?: boolean;
    threshold?: number;
    defaultScrollToIndex?: number;
    scrollToAlignment?: "auto" | "end" | "start" | "center";
    noRowsRenderer?: () => JSX.Element;
    errorRenderer?: () => JSX.Element;
    handlerRef?: React.RefObject<InfinityVirtualListHandler<T>>;
    onRowsRendered?: (rows: RenderedRows) => void;
    onScroll?: (data: ScrollData) => void;
    autosizeClassname?: string;
}
function InfinityVirtualList<T extends Entity>(
    {
        store,
        rowHeight,
        defaultRowHeight,
        rowRenderer,
        fetchDebounce,
        minimumBatchSize,
        threshold,
        overscanRowCount,
        initialLoader,
        autoFetch,
        defaultScrollToIndex,
        scrollToAlignment,
        handlerRef,
        noRowsRenderer,
        errorRenderer,
        onRowsRendered: onRowsRenderedCallback,
        onScroll,
        autosizeClassname
    }: InfinityVirtualListProps<T, BaseFilter<T>>
) {
    const isDynamicHeight = useMemo(() => rowHeight === undefined, [rowHeight]);
    const cellMeasurerCache = useRef(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: defaultRowHeight,
        minHeight: defaultRowHeight,
    }));
    const batchSize = useMemo(() => minimumBatchSize ?? 25, [minimumBatchSize]);
    const infinityLoaderRef = useRef<InfiniteLoader>(null);
    const listRef = useRef<List>(null);

    /**
     * needed to fetch all items around the target index (not working if the target item isn't fetched)
     */
    const prescrollToIndexRef = useRef<number | undefined>(defaultScrollToIndex);
    /**
     * need to scroll to the target after fecth and render items
     */
    const scrollToIndexRef = useRef<number | undefined>();

    useEffect(() => {
        if (!store.isInited && autoFetch === true && !store.hasError)
            store.fetch({ startIndex: 0, stopIndex: batchSize - 1 });
    }, [store.isInited, autoFetch, store.hasError]);

    useEffect(() => {
        if (handlerRef)
            //@ts-ignore
            handlerRef.current = {
                insertItem: (item: T, index: number) => {
                    store.insertItem(item, index);
                },
                swapItems: (index: number, newIndex: number) => {
                    store.swapItems(index, newIndex);
                },
                moveItem: (index: number, newIndex: number) => {
                    const item = store.getItem(index)!;
                    store.deleteItem(index);
                    store.insertItem(item, newIndex);
                },
                updateItem: (item: T, index: number) => {
                    store.updateItem(item, index);
                },
                recalcItem: (index: number) => {
                    cellMeasurerCache?.current?.clear(index, 0);
                },
                deleteItem: (index: number) => {
                    store.deleteItem(index);
                },
                scrollTo: (index: number) => {
                    // dirty hack
                    prescrollToIndexRef.current = undefined;
                    infinityLoaderRef.current?.forceUpdate();
                    setTimeout(() => {
                        prescrollToIndexRef.current = index;
                        infinityLoaderRef.current?.forceUpdate();
                        if (store.isRowLoaded(index)) {
                            setTimeout(() => {
                                prescrollToIndexRef.current = undefined;
                                infinityLoaderRef.current?.forceUpdate();
                            }, 300);
                        }
                    }, 0);
                },
                update: (resetMeasurerCache = false) => {
                    if (resetMeasurerCache)
                        cellMeasurerCache?.current?.clearAll();
                    infinityLoaderRef.current?.forceUpdate();
                },
                reset: () => {
                    store.forceReset();
                }
            };
    }, [handlerRef, cellMeasurerCache, store]);

    const loadMoreRows = useCallback(debounce(async (startIndex: number, stopIndex: number) => {
        await store.fetch({ startIndex, stopIndex });
        if (prescrollToIndexRef.current) {
            scrollToIndexRef.current = prescrollToIndexRef.current;
            prescrollToIndexRef.current = undefined;
        }
        // need to update view after fast scrolling to render visible items
        infinityLoaderRef.current?.forceUpdate();
    }, fetchDebounce?.milliseconds ?? 300), []);

    const scrollToIndex = useCallback((index: number) => {
        setTimeout(() => {
            listRef.current?.scrollToRow(index);
            scrollToIndexRef.current = undefined;
        }, 0);
    }, []);

    if (store.hasError && errorRenderer)
        return <>{errorRenderer()}</>;
    return <>
        <InfiniteLoader
            ref={infinityLoaderRef}
            isRowLoaded={(x) => store.isRowLoaded(x.index)}
            loadMoreRows={(x) => loadMoreRows(x.startIndex, x.stopIndex)!}
            threshold={threshold}
            rowCount={store.totalCount}
            minimumBatchSize={batchSize}>
            {({ onRowsRendered, registerChild }) => (
                <AutoSizer className={autosizeClassname} >
                    {({ width, height }) => (
                        <List
                            rowCount={store.totalCount}
                            width={width}
                            estimatedRowSize={defaultRowHeight}
                            height={height}
                            onScroll={(x: ScrollParams) => {
                                onScroll?.({
                                    ...x,
                                    offsetHeight: x?.clientHeight
                                        ? x?.scrollHeight - x?.clientHeight - x?.scrollTop
                                        : 0
                                });
                            }}
                            scrollToAlignment={scrollToAlignment}
                            scrollToIndex={prescrollToIndexRef.current}
                            rowHeight={isDynamicHeight ? cellMeasurerCache!.current!.rowHeight : rowHeight!}
                            rowRenderer={x => {
                                const item = store.isRowLoaded(x.index) ? store.items[x.index] : undefined;
                                return <CellMeasurer
                                    key={x.key}
                                    cache={cellMeasurerCache!.current!}
                                    parent={x.parent}
                                    columnIndex={0}
                                    rowIndex={x.index}>
                                    {({ measure }) => <div key={x.key} style={x.style}>
                                        {rowRenderer({ ...x, item, onRendered: measure })}
                                    </div>}
                                </CellMeasurer>
                            }}
                            deferredMeasurementCache={cellMeasurerCache!.current!}
                            overscanRowCount={overscanRowCount}
                            noRowsRenderer={noRowsRenderer}
                            onRowsRendered={x => {
                                // update current rendered range
                                store.setViewport({ startIndex: x.overscanStartIndex, stopIndex: x.overscanStopIndex });
                                onRowsRendered(x);
                                onRowsRenderedCallback?.(x);
                            }}
                            style={{ outline: 'none' }}
                            ref={el => {
                                //@ts-ignore
                                listRef.current = el;

                                if (scrollToIndexRef.current)
                                    scrollToIndex(scrollToIndexRef.current);
                                return registerChild(el);
                            }}
                        />
                    )}
                </AutoSizer>
            )}
        </InfiniteLoader>
        {(initialLoader && !store.isInited) && <>{initialLoader}</>}
    </>;
}
export default observer(InfinityVirtualList);
import { Updatable } from '../../..';

export default class VoximplantSettings extends Updatable {
    constructor(settings?: Partial<VoximplantSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<VoximplantSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
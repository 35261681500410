import { Updatable } from '../../..';
import { PaymentGatewayPaymentMethodType, PaymentGatewayPaymentObjectType, PaymentGatewayTaxType } from '../PaymentGatewayInfo';

export default class YandexCheckoutSettings extends Updatable {
    public shopId: string;
    public apiKey: string;
    public tax: PaymentGatewayTaxType = PaymentGatewayTaxType.None;
    public paymentObject: PaymentGatewayPaymentObjectType = PaymentGatewayPaymentObjectType.Payment;
    public paymentMethod: PaymentGatewayPaymentMethodType = PaymentGatewayPaymentMethodType.FullPayment;

    update(entity: Partial<YandexCheckoutSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { action } from 'mobx';
import { Updatable } from '../../Entity';

//#region  rule

// then
export declare type FormThenActionType = 'redirectToPage' | 'redirectToUrl' | 'submitForm';
export type FormThenActionReadonly<T = any> = {
    type: FormThenActionType;
    value: T;
}

//if 
export declare type FormIfFieldCondition = 'contains' | 'notContains' | 'equals' | 'notEquals' | 'lessThan' | 'moreThan' | 'specified' | 'notSpecified' | 'range';
export type FormIfFieldReadonly<T = any> = {
    type: string;
    condition: FormIfFieldCondition;
    value: T;
}
//if group
export type FormIfGroupReadonly = {
    fields: FormIfFieldReadonly[];
}

// form rule
export type FormRuleReadonly = {
    if: FormIfGroupReadonly[];
    then: FormThenActionReadonly | null;
}

//#endregion
export class QuizPageSettings extends Updatable {
    constructor(s?: Partial<QuizPageSettings>) {
        super();
        if (s)
            this.update(s);
    }

    // TODO remove
    rule: FormRuleReadonly | null;
    rules: FormRuleReadonly[] | null;

    @action update(q: Partial<QuizPageSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static get default() {
        return new QuizPageSettings({
            rule: null,
            rules: null
        });
    }

    clone() {
        return new QuizPageSettings({
            ...this
        });
    }

    toJson() {
        return JSON.stringify(this);
    }

    static fromJson(json: any) {
        return new QuizPageSettings({
            rule: json.rule,
            rules: json.rules
                ? json.rules
                : json.rule ? [json.rule] : undefined
        });
    }
}

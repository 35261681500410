import TypedEvent from '../typed-event';
import { AccelFile } from '../../models';
import type { PartETag, UploadFileArgs } from '../../api/methods/file';
import Api, { ApiResponse, BadApiResult } from '../../api';

export class S3UploaderOptions {
    public chunkSize: number = 50 * 1024 * 1024;
    public resume: boolean = true;
    public maxAttempts: number = 3;
    public concurrentUploads: number = 10;
}

export type BeginEventArgs = {
    id: string;
}
export type ProgressEventArgs = {
    id: string;
    percent: number;
    total: number;
    chunk: number;
}
export type CompleteEventArgs = {
    file: AccelFile;
}
export type ErrorEventArgs = {
    errors?: any;
}
export type AbortEventArgs = {
}

type FileMeta = {
    id: string;
}

export default class S3Uploader {
    public onBegin = new TypedEvent<BeginEventArgs>();
    public onProgress = new TypedEvent<ProgressEventArgs>();
    public onComplete = new TypedEvent<CompleteEventArgs>();
    public onPrepare = new TypedEvent<void>();
    public onError = new TypedEvent<ErrorEventArgs>();
    public onAborted = new TypedEvent<AbortEventArgs>();

    public isAborted: boolean = false;
    public isUploading: boolean = false;
    private options: S3UploaderOptions;
    private file: File;
    private args: UploadFileArgs;
    private fileMeta: FileMeta | null;

    constructor(private api: Api, options: Partial<S3UploaderOptions> = new S3UploaderOptions()) {
        this.options = { ...new S3UploaderOptions(), ...options };
    }

    setOptions(options: Partial<S3UploaderOptions>) {
        this.options = { ...new S3UploaderOptions(), ...options };
    }

    setFile(file: File, args: UploadFileArgs = {}): boolean {
        if (this.isUploading)
            return false;
        this.fileMeta = null;
        this.file = file;
        this.args = args;
        return true;
    }

    async upload(): Promise<boolean> {
        if (!this.file)
            throw new Error('File is not specified');
        if (this.isUploading)
            throw new Error('Upload is in progress');

        this.isUploading = true;
        const urls = await this.beginUpload();
        if (urls.length == 0)
            return false;

        const eTags = await this.uploadParts(urls);
        if (eTags.length == 0)
            return false;

        await this.complete(eTags);
        return await this.prepare();
    }

    private async beginUpload(): Promise<string[]> {
        const result = await this.api.file.begin(this.file, this.options.chunkSize, this.args);
        if (!result.success) {
            this.onError.emit({ errors: result.hasError ? [result.error] : result.response.errors })
            return [];
        }
        this.onBegin.emit({
            id: result.response.body.id,
        });
        this.fileMeta = {
            id: result.response.body.id,
        };
        return result.response.body.urls;
    }

    public async abort(): Promise<boolean> {
        if (this.isAborted) return Promise.resolve(true);

        this.isAborted = true;
        // in case 'begin' request is in progress
        if (this.fileMeta == null)
            return true;

        this.isUploading = false;
        const result = await this.api.file.abort(this.fileMeta!.id);
        if (!result.success) {
            this.onError.emit({ errors: result.hasError ? [result.error] : result.response.errors })
            return false;
        }
        this.onAborted.emit({});
        return true;
    }

    private async uploadParts(urls: string[]): Promise<PartETag[]> {
        if (this.isAborted)
            return [];

        let fulfilled = 0;
        let concurentUploads: Promise<PartETag>[] = [];
        let tags: PartETag[] = [];

        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            const partNumber = i + 1;
            const start = i * this.options.chunkSize;
            const end = partNumber * this.options.chunkSize;
            const blob = this.file.slice(start, end);
            const isLastChunk = i == urls.length - 1;

            if (this.isAborted)
                break;

            const promise = this.uploadPart(url, blob, partNumber);
            concurentUploads.push(promise);

            promise.then(() => {
                fulfilled++;
                this.onProgress.emit({
                    chunk: partNumber,
                    id: this.fileMeta!.id,
                    total: urls.length,
                    percent: Math.round((fulfilled / urls.length) * 100)
                });
            }, reason => this.onError.emit({ errors: [{ message: reason, number: partNumber }] }));

            if ((this.options.concurrentUploads > 0 && concurentUploads.length >= this.options.concurrentUploads) || isLastChunk) {
                tags = [...tags, ...await Promise.all(concurentUploads)];
                concurentUploads = [];
                console.logDev('[s3 uploader]', this.fileMeta!.id, tags);
            }
        }
        return tags;
    }

    private async uploadPart(url: string, blob: Blob, partNumber: number): Promise<PartETag> {
        let attempts = 0;
        do {
            attempts++;
            try {
                const response = await fetch(url, {
                    method: 'PUT',
                    body: blob
                });
                if (response != null && response.ok) {
                    const eTag: string = response.headers.get('etag') as string;
                    return Promise.resolve({ eTag, partNumber });
                }
            }
            catch (e: any) {
                let result: BadApiResult;
                if (e.response == null || e.response.data == null) {
                    result = new BadApiResult();
                    console.error('Network error', e);
                } else {
                    const response = new ApiResponse(e.response.data);
                    result = new BadApiResult(e.response.status, response);
                }

                this.onError.emit({ errors: result.hasError ? [result.error] : result.response.errors });
            }
        }
        while (attempts != this.options.maxAttempts && !this.isAborted);
        // auto abort whole file
        await this.abort();
        return Promise.reject('Maximum retries reached');
    }

    private async complete(eTags: PartETag[]): Promise<boolean> {
        if (this.isAborted)
            return false;
        this.isUploading = false;
        const result = await this.api.file.complete(this.fileMeta!.id, eTags);
        if (!result.success) {
            this.onError.emit({ errors: result.hasError ? [result.error] : result.response.errors });
            return false;
        }
        this.onComplete.emit({
            file: result.response.body
        });
        return true;
    }

    private async prepare(): Promise<boolean> {
        if (this.isAborted)
            return false;
        const result = await this.api.file.prepare(this.fileMeta!.id);
        if (!result.success) {
            this.onError.emit({ errors: result.hasError ? [result.error] : result.response.errors });
            return false;
        }
        this.onPrepare.emit();
        return true;
    }
}

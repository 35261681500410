import { action, computed, observable } from 'mobx';
import { isEmpty } from '../../../../utils';
import QuizItemSettings from './QuizItemSettings';

export class VideoItemSettings extends QuizItemSettings {
    constructor(s?: Partial<VideoItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable videoId: string | null;
    @observable videoUrl: string | null;

    @computed get hasVideo() {
        return !isEmpty(this.videoId) || !isEmpty(this.videoUrl);
    }

    @observable widthPercent: number;
    @observable align: 'left' | 'center' | 'right';

    @action update(q: Partial<VideoItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<VideoItemSettings>) {
        return new VideoItemSettings({
            ...QuizItemSettings.default,
            videoId: null,
            videoUrl: null,
            widthPercent: 100,
            align: 'center',
            ...settings
        });
    }

    clone() {
        return new VideoItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return VideoItemSettings.build(json);
    }
}

import React, { ReactNode, useContext, useMemo } from "react";
import { AccelContext, Context } from '../AccelProvider/AccelProvider';
import { LocStringCase, PluralKeys } from '../../utils/localization/localization';
import { MarkdownText, StringTemplate, TimeLoc } from '..';
import { TimeLocProps } from "./TimeLoc";

type LocProps = {
    word?: string,
    wordPlural?: PluralKeys,
    number?: number,
    default?: string,
    defaultPlural?: PluralKeys,
    case?: LocStringCase,
    args?: ReactNode[];
    className?: string;
    ctx?: AccelContext;
}
type LocPluralProps = Omit<LocProps, 'wordPlural' | 'defaultPlural' | 'number'> & { number: number; renderNumber?: boolean; };

const Loc: React.FC<LocProps> & { Plural: React.FC<LocPluralProps>, Md: React.FC<LocProps>, Time: React.FC<TimeLocProps> } = (props) => {
    if (props.word == undefined && props.wordPlural == undefined) {
        console.warn(`Loc 'word' or 'wordPlural' must be specified`);
        return null;
    }

    if (props.wordPlural != undefined && props.number == undefined) {
        console.warn(`Loc 'number' must be specified when use 'wordPlural'`);
        return null;
    }

    const ctx = props.ctx ?? useContext(Context);
    const loc = ctx.loc;

    const word = useMemo(() => props.word
        ? loc.word(props.word, { default: props.default, case: props.case })
        : loc.wordPlural(props.number!, props.wordPlural!, { default: props.defaultPlural, case: props.case }), [props.word, props.number]);

    const args = useMemo(() => props.args ?? React.Children.toArray(props.children), [props.args, props.children]);
    if (args.length == 0) return <span className={props.className}>{word}</span>;
    return <StringTemplate template={word} args={args} className={props.className} />;
}

const LocPlural: React.FC<LocPluralProps> = (props) => {
    const loc = <Loc {...props}
        word={undefined}
        wordPlural={{ one: `${props.word}.plural.one`, two: `${props.word}.plural.two`, other: `${props.word}.plural.other` }}
        defaultPlural={{ one: 'item', two: 'items', other: 'items' }}
        case={props.case ?? 'lower'} />;
    if (props.renderNumber === true)
        return <span className={props.className}>
            {props.number}&nbsp;
            {loc}
        </span>
    return loc;
}
Loc.Plural = LocPlural;


const LocMd: React.FC<LocProps> = (props) => {
    const ctx = props.ctx ?? useContext(Context);
    const loc = ctx.loc;

    const word = useMemo(() => props.word
        ? loc.word(props.word, { default: props.default, case: props.case })
        : loc.wordPlural(props.number!, props.wordPlural!, { default: props.defaultPlural, case: props.case }), [props.word, props.number]);

    return <MarkdownText text={word} />;
}
Loc.Md = LocMd;

Loc.Time = (props) => <TimeLoc {...props} />;

export default Loc;
import { Entity, BaseFilter } from '../../models';

export interface IApiResponse {
    errors: ApiResponseError[];
    body: any;
}

export class ApiResponseError {
    constructor(public code: number, public message: string, public target?: any) {
    }

    static fromJson(json: any) {
        return new ApiResponseError(json.code, json.message, json.target);
    }
}

export class ApiResponse<TBody = any> implements IApiResponse {
    public errors: ApiResponseError[] = [];
    public body: TBody;
    public accessToken: string | null = null;
    public refreshToken: string | null = null;
    public resetToken: boolean = false;

    public get hasTokens() {
        return this.accessToken != null && this.refreshToken != null;
    }

    public get hasErrors(): boolean {
        return this.errors.length > 0;
    }

    public hasError(...error: number[]): boolean {
        return this.errors.some(x => error.includes(x.code));
    }

    public getError(error: number): ApiResponseError | undefined {
        return this.errors.find(x => x.code == error);
    }

    constructor(response: any = null) {
        if (response == null || (response.body == null && response.errors == null))
            return;
        this.errors = response.errors.map(ApiResponseError.fromJson);
        this.body = response.body;
        this.resetToken = response.resetToken;
        // TODO remove from here
        if (this.body != null) {
            //@ts-ignore
            this.accessToken = this.body.accessToken ?? null;
            //@ts-ignore
            this.refreshToken = this.body.refreshToken ?? null;
        }
    }
}

export type TListResponseBody<T extends Entity, TFilter extends BaseFilter<T>> = {
    filter: TFilter;
    items: T[];
}

export class ApiListResponse<T extends Entity, TFilter extends BaseFilter<T>> extends ApiResponse<TListResponseBody<T, TFilter>> {
    public filter: TFilter;
    public items: T[];

    constructor(response: any = null) {
        super(response);
    }
}

export type FileResponseBody = {
    file: Blob;
    url: string;
    fileName: string;
}

export class ApiFileResponse extends ApiResponse<FileResponseBody> {
    public file: Blob;
    public url: string;

    constructor(blob: Blob, fileName: string) {
        super({
            body: blob ? { file: blob, url: URL.createObjectURL(blob), fileName } : new Blob([], { type: 'application/octet-stream' }),
            errors: [],
            resetToken: false
        });
    }
}
declare type NodeType = 'head' | 'body';

export function executeJs(script: string, target: NodeType) {
    const targetNode = document.getElementsByTagName(target)[0];
    if (targetNode == null)
        return;

    const divNode = document.createElement("div");
    divNode.innerHTML = script;

    for (var s = 0; s < divNode.childNodes.length; s++) {
        const node = divNode.childNodes[s] as HTMLElement;
        if (node.nodeName.toLowerCase() == "script" || node.nodeName.toLowerCase() == "style") {
            const newScript = document.createElement(node.nodeName);
            newScript.innerHTML = node.innerHTML;
            targetNode.appendChild(newScript)
        }
    }
}
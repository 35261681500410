
import { CourseItemTaskAnswerComment, BaseFilter } from '../../../../..';
import { observable } from 'mobx';

export default class CourseItemTaskAnswerCommentFilter extends BaseFilter<CourseItemTaskAnswerComment> {
    constructor(filter?: Partial<CourseItemTaskAnswerCommentFilter>) {
        super();
        if (filter) this.update(filter);
    }

    @observable id?: string;
    @observable answerId?: string;
    @observable adminId?: string;
    @observable studentId?: string;
    @observable createdDateLessThan?: moment.Moment;

    update(changes: Partial<CourseItemTaskAnswerCommentFilter>) {
        super.update(changes);
    }
}
﻿import IDisposable from './Disposable/IDisposable';

export interface IListener<T> {
    (event: T): any;
}

/** passes through events as they happen. You will not get events from before you start listening */
export default class TypedEvent<T> {
    private listeners: IListener<T>[] = [];
    private listenersOncer: IListener<T>[] = [];

    on = (listener: IListener<T>): IDisposable => {
        this.listeners.push(listener);
        return {
            dispose: () => this.off(listener)
        };
    }

    once = (listener: IListener<T>): void => {
        this.listenersOncer.push(listener);
    }

    off = (listener: IListener<T>) => {
        var callbackIndex = this.listeners.indexOf(listener);
        if (callbackIndex > -1) this.listeners.splice(callbackIndex, 1);
    }

    emit = (event: T) => {
        /** Update any general listeners */
        this.listeners.forEach((listener) => listener(event));

        /** Clear the `once` queue */
        if (this.listenersOncer.length > 0) {
            this.listenersOncer.forEach((listener) => listener(event));
            this.listenersOncer = [];
        }
    }

    pipe = (filter: (event: T) => boolean, listener: IListener<T>): IDisposable => {
        return this.on(e => filter(e) && listener(e));
    }
}
import { action } from 'mobx';
import Entity from '../../Entity';

/** it's a partial class from admin to use email entity inside accel-framework*/
export default class EmailTemplate extends Entity {
    constructor(profile?: Partial<EmailTemplate>) {
        super(profile);
        if (profile) this.update(profile);
    }

    @action update(item: Partial<EmailTemplate>) {
        super.update(item);
    }
}
import { action, observable } from "mobx";
import { KeyGenerator, isArraysEqual } from "../../../utils";
import Entity from "../../Entity";
import PaymentGateway from "../../Payment/PaymentGateway/PaymentGateway";
import { PaymentGatewayPaymentMethodGroupType, PaymentGatewayPaymentMethodType, PaymentGatewayPaymentObjectType, PaymentGatewayTaxType } from "../../Payment/PaymentGateway/PaymentGatewayInfo";

export declare type ProductPaymentGatewaySettingsType = 'onlineKassa' | 'paymentMethodGroups';
export class ProductPaymentGatewaySettings extends Entity {
    constructor(settings?: Partial<ProductPaymentGatewaySettings>) {
        super(settings);
        if (settings) this.update(settings);
    }

    @observable productId: string;
    @observable gateway: PaymentGateway;
    @observable taxRequired: boolean;
    @observable tax: PaymentGatewayTaxType;
    @observable paymentObjectRequired: boolean;
    @observable paymentObject: PaymentGatewayPaymentObjectType;
    @observable paymentMethodRequired: boolean;
    @observable paymentMethod: PaymentGatewayPaymentMethodType;
    @observable paymentMethodGroupRequired: boolean = false;
    @observable paymentMethodGroups: PaymentGatewayPaymentMethodGroupType[] = [];
    @observable type: ProductPaymentGatewaySettingsType = 'onlineKassa';

    _key: string = KeyGenerator.generate(5);

    @action update(product: Partial<ProductPaymentGatewaySettings>, allowUndefined = false) {
        super.update(product, allowUndefined);
    }

    static fromJson(json: any): ProductPaymentGatewaySettings {
        return new ProductPaymentGatewaySettings({
            ...json,
            gateway: json.gateway ? PaymentGateway.fromJson(json.gateway) : null,
        });
    }

    toJson() {
        return {
            gatewayId: this.gateway.id,
            tax: this.tax,
            paymentObject: this.paymentObject,
            paymentMethod: this.paymentMethod,
            paymentMethodGroups: this.paymentMethodGroups,
            type: this.type
        }
    }

    clone(changes?: Partial<ProductPaymentGatewaySettings>) {
        return new ProductPaymentGatewaySettings({
            ...this,
            ...changes,
        });
    }

    hasChanges(settings: ProductPaymentGatewaySettings) {
        return this.gateway.id != settings.gateway.id
            || this.tax != settings.tax
            || this.type != settings.type
            || this.paymentObject != settings.paymentObject
            || this.paymentMethod != settings.paymentMethod
            || !isArraysEqual(this.paymentMethodGroups, settings.paymentMethodGroups, (a, b) => a == b);
    }
}

import { action, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';

export class SliderItemSettings extends QuizItemSettings {
    constructor(s?: Partial<SliderItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable min: number;
    @observable max: number;

    @action update(q: Partial<SliderItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(def?: Partial<SliderItemSettings>) {
        return new SliderItemSettings({
            ...QuizItemSettings.default,
            min: 0,
            max: 100,
            ...def
        });
    }

    clone() {
        return new SliderItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return SliderItemSettings.build(json);
    }
}

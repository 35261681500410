import { action } from "mobx";
import {CustomField, CustomFieldType} from "../..";


export default class PipelineField extends CustomField {
    constructor(f?: Partial<PipelineField>) {
        super(f);
        if (f) this.update(f);
    }
  
    clone(changes?: Partial<PipelineField>) {
        return new PipelineField({
            ...this,
            ...changes
        });
    }

    @action
    update(f: Partial<PipelineField>, allowUndefined = false) {
        super.update(f, allowUndefined);
    }

    static default(){
        return new PipelineField({
            visible: true,
            required: false,
            type: CustomFieldType.Text,
            additionalData: JSON.stringify([])
        });
    }

    static fromJson(json: any) {
        return new PipelineField({
            id: json.id,
            type: json.type,
            name: json.name,
            keyName: json.key,
            additionalData: json.additionalData,
            position: json.position,
            visible: json.visible,
            required: json.required
        });
    }
}
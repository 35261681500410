import { useMemo, useRef } from "react";

export type FCHandler<TProps = {}, THandler = {}> = React.FunctionComponent<TProps & { handler?: React.MutableRefObject<THandler> }> & { useHandler: () => React.MutableRefObject<THandler> };

// TODO add to FAQ
function withHandler<TProps = {}, THandler = {}>(c: React.FC<TProps & { handler?: React.MutableRefObject<THandler> }>): FCHandler<TProps, THandler> {
    const Component = c as FCHandler<TProps, THandler>;
    Component.useHandler = () => {
        const ref = useRef<THandler>() as React.MutableRefObject<THandler>;
        return ref;
    }
    return Component;
}

function useHandler<THandler>(handler: React.MutableRefObject<THandler> | undefined, handle: THandler, deps: any[]) {
    useMemo(() => {
        if (handler)
            handler.current = handle;
    }, deps);
}

export { withHandler, useHandler };
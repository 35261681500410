import { Updatable } from './../Entity';
import { action, observable } from 'mobx';

export class QuizSettings extends Updatable {
    constructor(s?: Partial<QuizSettings>) {
        super();
        if (s)
            this.update(s);
    }

    //#region common

    @observable showBackButton: boolean;
    @observable nextButtonText: string;
    @observable completeButtonText: string;
    @observable completeFormText: string;

    @observable showCompleteImage: boolean;
    @observable completeImageUrl: string | null;

    @observable redirectEnabled: boolean;
    @observable redirectUrl: string;
    @observable redirectNewTab: boolean;

    @observable codeExecutionEnabled: boolean;
    @observable code: string;

    //#endregion

    // #region text

    // 14px
    @observable textSize: number;
    // #262626
    @observable textColor: string;

    //#endregion

    //#region bg

    // #FFFFFF
    @observable bgColor: string;
    @observable bgImageUrl: string | null;

    //#endregion

    //#region fields

    // 16px
    @observable titleSize: number;
    // #262626
    @observable titleColor: string;
    // 1px
    @observable borderThickness: number;
    // #BFBFBF
    @observable borderColor: string;
    // 3px
    @observable borderRadius: number;

    //#endregion

    //#region navigation buttons

    // #1890FF 
    @observable navBtnBgColor: string;
    // #FFFFFF
    @observable navBtnTextColor: string;
    /**
     * sm: W: 127px H: 24px
     * md: W: 143px H: 32px
     * lg: W: 159px H: 40px
     */
    @observable navBtnSize: 'sm' | 'md' | 'lg' | 'xl';
    @observable navBtnBorderStyle: 'solid' | 'dashed' | 'dotted';
    @observable navBtnBorderThickness: number;
    @observable navBtnBorderColor: string;
    @observable navBtnBorderRadius: number;
    @observable navBtnFontSize: number;

    //#endregion

    //#region el buttons (mono, multi)

    @observable elBtnBgColor: string;
    @observable elBtnTextColor: string;
    @observable elBtnSize: 'sm' | 'md' | 'lg' | 'xl';
    @observable elBtnBorderStyle: 'solid' | 'dashed' | 'dotted';
    @observable elBtnBorderThickness: number;
    @observable elBtnBorderColor: string;
    @observable elBtnBorderRadius: number;
    @observable elBtnFontSize: number;

    // #endregion

    //#region progress bar

    @observable progressBarVisible: boolean;
    @observable progressBarBgColor: string;
    @observable progressBarFillColor: string;
    @observable progressBarHeight: number;

    @observable progressBarPercentVisible: boolean;
    @observable progressBarPercentColor: string;
    @observable progressBarPercentFontSize: number;
    @observable progressBarPercentPlacement: 'left' | 'center' | 'right';

    // #endregion

    @action update(q: Partial<QuizSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    copy() {
        return new QuizSettings({
            ...this
        });
    }

    static get default() {
        return new QuizSettings({
            nextButtonText: undefined,
            completeButtonText: undefined,
            showBackButton: false,
            completeFormText: undefined,
            showCompleteImage: true,
            completeImageUrl: null,
            redirectEnabled: false,
            redirectUrl: undefined,
            redirectNewTab: false,
            codeExecutionEnabled: false,
            code: undefined,
            titleSize: 16,
            textSize: 14,
            textColor: '#262626',
            titleColor: '#262626',
            bgColor: '#FFFFFF',
            bgImageUrl: null,
            borderThickness: 1,
            borderColor: '#BFBFBF',
            borderRadius: 3,
            navBtnBgColor: '#1890FF',
            navBtnTextColor: '#FFFFFF',
            navBtnSize: 'md',
            navBtnBorderStyle: 'solid',
            navBtnBorderThickness: 1,
            navBtnBorderColor: '#0063BF',
            navBtnBorderRadius: 2,
            navBtnFontSize: 16,
            elBtnBgColor: '#1890FF',
            elBtnTextColor: '#262626',
            elBtnSize: 'md',
            elBtnBorderStyle: 'solid',
            elBtnBorderThickness: 1,
            elBtnBorderColor: '#0063BF',
            elBtnBorderRadius: 2,
            elBtnFontSize: 16,
            progressBarVisible: false,
            progressBarBgColor: '#8ED1FC',
            progressBarFillColor: '#0693E3',
            progressBarHeight: 6,
            progressBarPercentVisible: false,
            progressBarPercentColor: '#262626',
            progressBarPercentFontSize: 14,
            progressBarPercentPlacement: 'right'
        });
    }

    toJson() {
        return JSON.stringify(this);
    }

    static fromJson(json: any): QuizSettings {
        return new QuizSettings({
            ...this.default,
            ...json
        });
    }
}

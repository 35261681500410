import { computed } from "mobx";
import { Currency } from "../../../enums";
import { Updatable } from "../../Entity";

export enum PaymentGatewayPaymentObjectType {
    Unknown = 'unknown',
    Payment = 'payment',
    Commodity = 'commodity',
    Excise = 'excise',
    Job = 'job',
    Service = 'service',
    GamblingBet = 'gamblingBet',
    GamblingPrize = 'gamblingPrize',
    Lottery = 'lottery',
    LotteryPrize = 'lotteryPrize',
    IntellectualActivity = 'intellectualActivity',
    AgentCommission = 'agentCommission',
    Composite = 'composite',
    Another = 'another',
    PropertyRight = 'propertyRight',
    NonOperatingGain = 'nonOperatingGain',
    InsurancePremium = 'insurancePremium',
    SalesTax = 'salesTax',
    ResortFee = 'resortFee'
}

export enum PaymentGatewayPaymentMethodType {
    Unknown = 'unknown',
    FullPayment = 'fullPayment',
    PartialPayment = 'partialPayment',
    FullPrepayment = 'fullPrepayment',
    PartialPrepayment = 'partialPrepayment',
    Advance = 'advance',
    Credit = 'credit',
    CreditPayment = 'creditPayment'
}

export enum PaymentGatewayPaymentMethodGroupType {
    Ru = 'ru',
    World = 'world',
    InstallmentMonthAndHalf = 'installmentMonthAndHalf',
    Installment3Months = 'installment3Months',
    Installment4Months = 'installment4Months',
    Installment6Months = 'installment6Months',
    Installment10Months = 'installment10Months',
    Installment12Months = 'installment12Months',
    Installment24Months = 'installment24Months',
    Installment36Months = 'installment36Months',
    Bank = 'bank',
    Others = 'others',
}

export enum PaymentGatewayTaxationType {
    Osn = 'osn',
    UsnIncome = 'usnIncome',
    UsnIncomeOutcome = 'usnIncomeOutcome',
    Envd = 'envd',
    Esn = 'esn',
    Patent = 'patent'
}

export enum PaymentGatewayTaxType {
    None = 'none',
    Vat0 = 'vat0',
    Vat10 = 'vat10',
    Vat12 = 'vat12',
    Vat18 = 'vat18',
    Vat20 = 'vat20',
    Vat110 = 'vat110',
    Vat118 = 'vat118',
    Vat120 = 'vat120',
}

export default class PaymentGatewayInfo extends Updatable {
    constructor(info?: Partial<PaymentGatewayInfo>) {
        super();
        if (info) this.update(info);
    }

    supportSalesSettings: boolean;
    supportSaveCard: boolean;
    taxationRequired: boolean;
    taxRequired: boolean;
    paymentMethodRequired: boolean;
    paymentObjectRequired: boolean;
    paymentMethodGroupRequired: boolean;
    currencies: Currency[];
    taxations: PaymentGatewayTaxationType[];
    taxes: PaymentGatewayTaxType[];
    paymentObjects: PaymentGatewayPaymentObjectType[];
    paymentMethods: PaymentGatewayPaymentMethodType[];
    paymentMethodGroups: PaymentGatewayPaymentMethodGroupType[];

    @computed get hasPaymentMethodGroups() {
        return this.paymentMethodGroups?.length > 0;
    }

    update(product: Partial<PaymentGatewayInfo>) {
        super.update(product);
    }

    static fromJson(json: any): PaymentGatewayInfo {
        return new PaymentGatewayInfo({
            ...json,
        });
    }
}
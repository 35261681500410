import { observable, action } from 'mobx';
export default class CustomEditableFieldStore<T> {
    @observable value: T;
    @observable editing: boolean;
    @observable saving: boolean;
    @observable savedIconVisible: boolean;

    constructor(obj?: Partial<CustomEditableFieldStore<T>>) {
        Object.assign(this, obj);
    }

    @action
    setValue(value: T) {
        this.value = value;
    }

    @action
    setEditing(value: boolean) {
        this.editing = value;
    }

    @action
    setSaving(value: boolean) {
        this.saving = value;
    }

    @action
    setSavedIconVisible(value: boolean) {
        this.savedIconVisible = value;
    }
}

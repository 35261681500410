import { Lang } from '../../enums';
import cookies from 'js-cookie';
import { action, observable } from 'mobx';

type LangDetectorOptions = {
    /**
     * auto: cookie => domain => browser
     * 
     * manual: do not forget to set 'manualLang'
     */
    detectLangStrategy?: 'auto' | 'cookie' | 'manual';
    locCookieName?: string;
    domainCookieName?: string;
    manualLang?: Lang;
    domains?: Map<string, Lang>;
}
export default class LangDetector {
    constructor(private opts: LangDetectorOptions = {
        detectLangStrategy: 'auto',
        manualLang: Lang.en,
    }) {
        if (!opts.locCookieName) opts.locCookieName = 'loc';
        if (!opts.domainCookieName) opts.domainCookieName = 'originDomain';
        switch (opts.detectLangStrategy) {
            case 'auto':
                this.lang = this.autoLang;
                break;
            case 'cookie':
                this.lang = this.cookieLang!;
                break;
            case 'manual':
                this.setLang(opts.manualLang!);
                break;
        }
    }

    @observable lang: Lang;

    public get autoLang(): Lang {
        return this.cookieLang ?? this.domainLang ?? this.browserLang;
    }

    /**
     * @deprecated use autoLang
     * just for backwards compatibility
     * remove later
     */
    public get currentLang(): Lang {
        return this.autoLang;
    }

    public get cookieLang(): Lang | null {
        let cookieLang = cookies.get(this.opts.locCookieName!);
        if (cookieLang != null)
            return cookieLang as Lang;
        return null;
    }

    public get browserLang(): Lang {
        let lang = window.navigator.languages ? window.navigator.languages[0] : null;
        if (!lang)
            //@ts-ignore
            lang = window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
        if (lang == null)
            return Lang.en;
        if (lang.indexOf('-') !== -1)
            lang = lang.split('-')[0];
        if (lang.indexOf('_') !== -1)
            lang = lang.split('_')[0];
        switch (lang) {
            case 'ru': return Lang.ru;
            case 'uk': return Lang.uk;
            case 'zh': return Lang.zhtw;
            case 'id':
            case 'id, in':
                return Lang.id;
            case 'es':
                return Lang.es;
            default: return Lang.en;
        }
    }

    public get domainLang(): Lang | null {
        if (!this.opts.domains) return null;
        const originDomain = this.getDomain();
        return this.opts.domains.get(originDomain) ?? null;
    }

    @action setLang(lang: Lang) {
        cookies.set(this.opts.locCookieName!, lang);
        this.lang = lang;
    }

    /**
     * temporary solution for detection origin domain
     * 'from' url OR domain cookie
     */
    private getDomain() {
        let domain = new URLSearchParams(window.location.search).get('from');
        if (domain) {
            cookies.set(this.opts.domainCookieName!, domain);
        } else {
            domain = cookies.get(this.opts.domainCookieName!) ?? null;
        }
        return domain || window.location.host;
    }
}
import { observable, action } from 'mobx';
import { AccelFile, Entity } from '../..';

export enum CallRecordState {
    AwaitingDownload = 'awaitingDownload',
    Downloading = 'downloading',
    DownloadingFailed = 'downloadingFailed',
    Uploading = 'uploading',
    UploadingFailed = 'uploadingFailed',
    Deleting = 'deleting',
    DeletingFailed = 'deletingFailed',
    Completed = 'completed',
}

export default class CallRecord extends Entity {
    constructor(schoolCallRecord?: Partial<CallRecord>) {
        super(schoolCallRecord);
        if (schoolCallRecord) this.update(schoolCallRecord);
    }

    @observable durationInSec: number;
    @observable providerCost: number;
    @observable file: AccelFile;
    @observable state: CallRecordState;

    static fromJson(json: any) {
        return new CallRecord({
            ...json,
            file: json.file ? AccelFile.fromJson(json.file) : undefined
        });
    }

    @action
    update(contact: Partial<CallRecord>) {
        super.update(contact);
    }
}
import { action, observable } from "mobx";
import moment, { type Moment } from "moment";
import { Admin, Entity, ExtendedFilter, SharedSettingsExportEntity } from "../..";
import { SharedSettingsEntityType } from "../EntityType";

export type SharedSettingsExportTarget = 'site';

export enum SharedSettingsAccessType {
    Public = 'free',
    Private = 'contact'
}

export default class SharedSettingsExport extends Entity {
    constructor(ex?: Partial<SharedSettingsExport>) {
        super();
        if (ex) this.update(ex);
    }

    @observable name: string;
    @observable description: string;
    @observable reexportProhibited: boolean;
    @observable contactFilter: ExtendedFilter;

    @observable accessType: SharedSettingsAccessType;

    createdDate: Moment;
    updatedDate?: Moment;
    importsCount: number;

    createdByAdmin: Admin;
    @observable entities: SharedSettingsExportEntity[];

    get targets() {
        const map: Map<SharedSettingsExportTarget, boolean> = new Map();
        this.entities.forEach(e => {
            switch (e.type) {
                case SharedSettingsEntityType.SitePage:
                    map.set('site', true);
                    break;
            }
        });
        return Array.from(map.keys());
    }

    @action
    update(session: Partial<SharedSettingsExport>) {
        super.update(session);
    }

    static fromJson(json: any) {
        return new SharedSettingsExport({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            createdByAdmin: json.createdByAdmin ? Admin.fromJson(json.createdByAdmin) : undefined,
            entities: json.entities ? json.entities.map(SharedSettingsExportEntity.fromJson) : undefined,
            contactFilter: new ExtendedFilter({ filterJson: json.contactFilterJson })
        });
    }
}


import { inject } from 'react-ioc';
import { CookieStorage } from '..';

export type CookieClientSettings = {
    accessTokenName: string;
    refreshTokenName: string;
}

export default class AuthCookieClient {
    constructor(protected cookieStorage: CookieStorage,
        protected settings: CookieClientSettings) {
        this.cookieStorage = cookieStorage || inject(this, CookieStorage);
    }

    getAccessToken(): string | undefined {
        return this.cookieStorage.get(this.settings.accessTokenName);
    }

    getRefreshToken(): string | undefined {
        return this.cookieStorage.get(this.settings.refreshTokenName);
    }

    setAccessToken(token: string, expiresDate?: Date) {
        this.cookieStorage.set(this.settings.accessTokenName, token, {
            path: this.getCookiePath(),
            expires: expiresDate
        });
    }

    setRefreshToken(token: string, expiresDate?: Date) {
        this.cookieStorage.set(this.settings.refreshTokenName, token, {
            path: this.getCookiePath(),
            expires: expiresDate ?? 365,
            // We cannot use 'secure' attr, it blocks cookie access from js
            // secure: true
        });
    }

    removeAccessToken() {
        this.cookieStorage.remove(this.settings.accessTokenName, {
            path: this.getCookiePath()
        });
    }

    removeRefreshToken() {
        this.cookieStorage.remove(this.settings.refreshTokenName, {
            path: this.getCookiePath()
        });
    }

    getCookiePath() {
        return '/';
    }
}
import { action, computed, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';


export class DateTimeItemSettings extends QuizItemSettings {
    constructor(s?: Partial<DateTimeItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable type: 'dateTime' | 'date' | 'time';

    @action update(q: Partial<DateTimeItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<DateTimeItemSettings>) {
        return new DateTimeItemSettings({
            ...QuizItemSettings.default,
            type: 'dateTime',
            ...settings
        });
    }

    clone() {
        return new DateTimeItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return DateTimeItemSettings.build(json);
    }
}

import { action, observable } from 'mobx';
import { Entity } from '../..';
import { Site } from '../..';

export default class SiteDomain extends Entity {
    constructor(siteDomain?: Partial<SiteDomain>) {
        super(siteDomain);
        if (siteDomain) this.update(siteDomain);
    }
    @observable name: string;
    @observable redirectTo: string | null;
    @observable movedPermanently: boolean;
    @observable isMain: boolean;
    @observable isSslCertIssued: boolean;
    @observable isConfirmed: boolean;
    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable site: Site;
    /**
     * using as local field
     */
    isSystem: boolean;

    clone(): SiteDomain {
        return new SiteDomain({
            ...this,
            site: this.site?.clone()
        });
    }

    static fromJson(json: any): SiteDomain {
        return new SiteDomain({
            ...json,
            site: json.site ? Site.fromJson(json.site) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            isSystem: false
        });
    }

    @action
    update(siteDomain: Partial<SiteDomain>) {
        super.update(siteDomain);
    }
}
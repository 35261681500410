import { useEffect, useState } from "react";

export default function useScrollDirection(settings: { scrollContainer?: HTMLElement, threshold?: number } = { scrollContainer: document.body, threshold: 0 }) {
    const [scrollDirection, setScrollDirection] = useState<'down' | 'up' | null>(null);
    const [container] = useState(settings.scrollContainer ?? document.body);
    const [threshold] = useState(settings.threshold ?? 0);

    useEffect(() => {
        let lastScrollY = container.scrollTop;

        const updateScrollDirection = () => {
            const scrollY = container.scrollTop;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > threshold || scrollY - lastScrollY < -threshold)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        container.addEventListener("scroll", updateScrollDirection);
        return () => {
            container.removeEventListener("scroll", updateScrollDirection);
        }
    }, [scrollDirection]);

    return scrollDirection;
};

import { observable, action, computed } from 'mobx';
import { Entity } from '..';
import { FieldGroup } from '../BaseFilter';
import { isEmpty } from '../../utils';

export enum ExtendedFilterType {
    Personal = 'personal',
    Global = 'global'
}

export default class ExtendedFilter extends Entity {
    constructor(exfilter?: Partial<ExtendedFilter>) {
        super(exfilter);
        if (exfilter) this.update(exfilter);
    }

    @observable name: string;
    @observable type: ExtendedFilterType;
    @observable filterJson: string | null;

    @computed get groups(): FieldGroup[] {
        if (isEmpty(this.filterJson))
            return [];
        return JSON.parse(this.filterJson!);
    }

    set groups(value: FieldGroup[]) {
        if (value == null || value.length == 0)
            this.filterJson = null;
        this.filterJson = JSON.stringify(value);
    }

    static fromJson(json: any): ExtendedFilter {
        return new ExtendedFilter({
            ...json
        });
    }

    @action
    update(branch: Partial<ExtendedFilter>) {
        super.update(branch);
    }

    clone() {
        return new ExtendedFilter(this);
    }
}


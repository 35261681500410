import { FontColorsOutlined, SearchOutlined } from '@ant-design/icons';
import Input, { InputProps } from 'antd/lib/input';
import { debounce as _debaunce } from 'lodash';
import React, { useContext, useMemo } from "react";
import { Context } from '../AccelProvider/AccelProvider';
import useDebouncedCallback from '../hooks/useDebouncedCallback';
import { TimeSpan } from '../../utils';

type InputSearchSettings = {
    match: {
        enabled: boolean;
        value: boolean;
        onChange: (value: boolean) => void;
    }
}

export type InputSearchProps = InputProps & {
    debounce?: number;
    onSearch?: (value: string) => void;
    width?: number | string;
    searchIcon?: React.ReactNode;
    iconInSuffux?: boolean;
    debounceSettings?: {
        trailing?: boolean;
        leading?: boolean;
        maxWait?: number;
    }
    /**
     * @example match setting
     * match: {
     *    enabled: true,
     *    onChange: match => filter.update({match}),
     *    value: filter.match
     * }
     */
    settings?: InputSearchSettings;
}

const InputSearch: React.FC<InputSearchProps> = ({ debounce, onSearch, width, searchIcon, iconInSuffux, settings, debounceSettings, ...rest }) => {
    const ctx = useContext(Context);
    const loc = ctx.loc;

    const search = useDebouncedCallback((search: string) => {
        onSearch?.(search);
    }, [], TimeSpan.fromMilliseconds(debounce ?? 300), { ...debounceSettings });

    const suffix = useMemo(() => {
        const suffix = [];
        if (settings?.match.enabled === true)
            suffix.push(
                <FontColorsOutlined
                    title={loc.word('InputSearch.matchWholeWordHelp', { default: 'Match whole word' })}
                    onClick={() => settings.match.onChange(!settings.match.value)}
                    className={settings.match.value === true ? 'color-primary' : 'color-secondary'} />
            );

        if (iconInSuffux === true)
            suffix.push(searchIcon);

        return suffix;
    }, [settings?.match, iconInSuffux, searchIcon]);

    return <Input {...rest}
        onChange={e => {
            rest.onChange?.(e);
            search(e.target.value);
        }}
        placeholder={rest.placeholder ?? loc.word('Search')}
        prefix={!iconInSuffux ? searchIcon : undefined}
        suffix={suffix}
        style={rest.style ?? { width }}
    />;
}
InputSearch.defaultProps = {
    debounce: 300,
    width: 250,
    searchIcon: <SearchOutlined />,
    iconInSuffux: false,
    allowClear: true
}
export default InputSearch;
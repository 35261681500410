import { action, observable } from 'mobx';
import { Entity } from '..';

export default class LocalizationRequestTranslate extends Entity {
    constructor(item?: Partial<LocalizationRequestTranslate>) {
        super(item);
        if (item) this.update(item);
    }

    @observable languageId: string;
    @observable text: string;

    static fromJson(json: any): LocalizationRequestTranslate {
        return new LocalizationRequestTranslate({
            ...json,
        });
    }

    @action update(word: Partial<LocalizationRequestTranslate>) {
        super.update(word);
    }
}
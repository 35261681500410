import { observable, action } from 'mobx';
import { Library } from '../..';
import type { TimeMeasure } from '../..';
import { Designable } from '../../Entity';

export enum LibraryItemType {
    Section = 'section',
    Article = 'step'
};

export declare type FlowTimeDelayConditionType = 'flowStart';

export default class LibraryItem extends Designable {
    constructor(lib?: Partial<LibraryItem>) {
        super(lib);
        if (lib) this.update(lib);
    }

    @observable title: string;
    @observable comment: string;
    @observable position: number;
    @observable avgRating: number;
    @observable type: LibraryItemType;

    @observable parent?: LibraryItem;
    @observable parentItemId: string | null;

    @observable isDraft: boolean;

    @observable allowComments: boolean;
    @observable showComments: boolean;
    @observable ratingEnabled: boolean;
    @observable disableCommentModeration: boolean;
    @observable ratingCommentRequired: boolean;

    @observable preventCopingAndSelectingText: boolean;

    @observable createdDate: Date;
    @observable updatedDate: Date;

    @observable flowTimeDelayActive: boolean;
    @observable flowTimeDelay: number;
    @observable flowTimeDelayCondition: FlowTimeDelayConditionType;
    @observable flowTimeDelayMeasureType: TimeMeasure;

    @observable library: Library;

    clone(): LibraryItem {
        return new LibraryItem({
            ...this,
            parent: this.parent ? this.parent.clone() : undefined,
            library: this.library ? this.library.clone() : undefined,
        })
    }

    static fromJson(json: any): LibraryItem {
        return new LibraryItem({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            library: json.library ? Library.fromJson(json.library) : undefined,
            parent: json.parentItem ? LibraryItem.fromJson(json.parentItem) : undefined
        });
    }

    @action
    update(course: Partial<LibraryItem>) {
        super.update(course);
    }
}


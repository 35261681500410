import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useContext } from 'react';
import { combineClasses } from '../../utils';
import { Context } from '../AccelProvider/AccelProvider';
import styles from './Copyable.module.scss';

type Props = {
    text?: string;
    onCopy?: () => void;
    className?: string;
    style?: React.CSSProperties;
}
const Copyable: React.FC<Props> = ({ text, onCopy, className, style }) => {

    const { loc } = useContext(Context);

    return <>
        <Typography.Paragraph
            className={combineClasses(className, styles.copyable)}
            style={style}
            copyable={{
                icon: [<CopyOutlined key="copy-icon" />, <CheckOutlined key="copied-icon" />],
                tooltips: [loc.word('Form.copyMsg', { default: 'Copy' }), loc.word('Form.copiedMsg', { default: 'Copied' })],
                text,
                onCopy
            }}
        />
    </>;
}

export default Copyable;
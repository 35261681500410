import { BaseFilter } from '..';

export default class UserNotificationFilter extends BaseFilter<Notification> {
    constructor(filter?: Partial<UserNotificationFilter>) {
        super();
        if (filter) this.update(filter);
    }

    adminId: string;
    studentId: string;
    read?: boolean;

    update(changes: Partial<UserNotificationFilter>) {
        super.update(changes);
    }
}
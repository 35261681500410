
export default abstract class HubRequest {
    public url: string;
    public body: Array<any> = [];
    public connection: signalR.HubConnection;

    public async invoke(connection: signalR.HubConnection): Promise<any> {
        this.connection = connection;
        return await this.connection.invoke(this.url, ...this.body);
    }
}

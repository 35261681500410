import { AccelFile, ContactCommunication, Entity } from '../../..';
import { observable, action } from 'mobx';
import moment, { type Moment } from 'moment';
import TelegramGroup from '../TgGroup';

export type TelegramGroupUserState = 'requested' | 'invited' | 'approved' | 'rejected' | 'left' | 'banned' | 'removed';

export default class TelegramGroupUser extends Entity {
    constructor(user?: Partial<TelegramGroupUser>) {
        super(user);
        if (user) this.update(user);
    }

    @observable state: TelegramGroupUserState;
    @observable createdDate: Moment;
    @observable updatedDate: Moment | null;

    @observable group: TelegramGroup;
    @observable communication: ContactCommunication;
    @observable image: AccelFile | null;
    @observable isBot: boolean;

    @action update(g: Partial<TelegramGroupUser>, allowUndefined = false) {
        super.update(g, allowUndefined);
    }

    static fromJson(json: any): TelegramGroupUser {
        return new TelegramGroupUser({
            id: json.id,
            state: json.state,
            softDeleted: json.softDeleted,
            isBot: json.isBot,
            softDeletedDate: json.softDeletedDate ? moment(json.softDeletedDate) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : null,
            group: json.group ? TelegramGroup.fromJson(json.group) : undefined,
            communication: json.studentCommunication ? ContactCommunication.fromJson(json.studentCommunication) : undefined,
            image: json.image ? AccelFile.fromJson(json.image) : undefined,
        });
    }
}
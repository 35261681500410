import { observable, action, computed } from 'mobx';
import Contact from '../contact/contact';
import Admin from '../admin/Admin';
import moment from 'moment';
import Entity, { ISerializable } from '../Entity';
import BaseFilter from '../BaseFilter';
import PurchaseOrder from '../Payment/PurchaseOrder/PurchaseOrder';

export type PartnershipTransactionType = 'deposit' | 'withdrawal';
export default class PartnershipTransaction extends Entity implements ISerializable {
    constructor(t?: Partial<PartnershipTransaction>) {
        super();
        if (t) this.update(t);
    }

    @observable amount: number;
    @observable fee: number;
    @observable useBonus: boolean;
    @observable comment: string;
    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable partner: Contact;
    @observable adminCreator?: Admin;
    @observable type: PartnershipTransactionType;
    @observable order: PurchaseOrder;
    @observable validAfterDate: moment.Moment;
    level?: number;
    levelPartner?: Contact;

    @computed get contact() {
        return this.order?.contact;
    }

    @computed get canWithdraw() {
        return this.validAfterDate && this.validAfterDate.isBefore(moment());
    }

    @action update(partnership: Partial<PartnershipTransaction>) {
        super.update(partnership);
    }

    toJson() {
        return {
            id: this.id,
            amount: this.amount,
            type: this.type,
            fee: this.fee,
            comment: this.comment,
            partnerId: this.partner?.id,
            contactId: this.contact?.id,
            orderId: this.order?.id,
            useBonus: this.useBonus,
            level: this.level,
            levelPartnerId: this.levelPartner?.id,
        }
    }

    clone() {
        return new PartnershipTransaction({
            ...this,
            partner: this.partner?.clone(),
            contact: this.contact?.clone(),
            adminCreator: this.adminCreator?.clone(),
            order: this.order?.clone()
        });
    }

    static fromJson(json: any): PartnershipTransaction {
        return new PartnershipTransaction({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            partner: json.partner ? Contact.fromJson(json.partner) : undefined,
            order: json.order ? PurchaseOrder.fromJson(json.order) : undefined,
            validAfterDate: json.validAfterDate ? moment(json.validAfterDate) : undefined,
            adminCreator: json.adminCreator ? Admin.fromJson(json.adminCreator) : undefined,
            levelPartner: json.levelPartner ? Contact.fromJson(json.levelPartner) : undefined
        });
    }

    static default() {
        return new PartnershipTransaction({
            fee: 0,
            type: 'deposit',
        });
    }
}

export class PartnershipTransactionFilter extends BaseFilter<PartnershipTransaction> {
    constructor(filter?: Partial<PartnershipTransactionFilter>) {
        super();
        if (filter) this.update(filter);
    }

    createdDateFrom: moment.Moment | null;
    createdDateTo: moment.Moment | null;
    partnerIds: string[];
    amountFrom: number;
    amountTo: number;
    feeFrom: number;
    feeTo: number;
    /**
     * has admin => manual transaction
     */
    hasAdminId: boolean;
    type: PartnershipTransactionType;
    orderId: string;

    update(changes: Partial<PartnershipTransactionFilter>) {
        super.update(changes);
    }
}
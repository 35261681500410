import { action, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';

export type DropdownListItemOption = {
    key: string;
    label: string;
}

export class DropdownListItemSettings extends QuizItemSettings {
    constructor(s?: Partial<DropdownListItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable options: DropdownListItemOption[];

    @action update(q: Partial<DropdownListItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<DropdownListItemSettings>) {
        return new DropdownListItemSettings({
            ...QuizItemSettings.default,
            options: [],
            ...settings
        });
    }

    clone() {
        return new DropdownListItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return DropdownListItemSettings.build(json);
    }
}



import { action } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Admin, BillingAddress, Entity, PaymentGatewayType } from '../..';

export default class BillingPaymentMethod extends Entity {
    constructor(t?: Partial<BillingPaymentMethod>) {
        super(t);
        if (t) this.update(t);
    }

    externalId?: string;
    cardNumber: string;
    createdDate: Moment;
    updatedDate?: Moment;
    expYear: number;
    expMonth: number;
    gatewayType: PaymentGatewayType;
    admin: Admin;
    address?: BillingAddress;

    static fromJson(json: any): BillingPaymentMethod {
        return new BillingPaymentMethod({
            id: json.id,
            externalId: json.externalId,
            cardNumber: json.cardNumber,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            expYear: json.expYear,
            expMonth: json.expMonth,
            gatewayType: json.gatewayType,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            address: json.address ? BillingAddress.fromJson(json.address) : undefined
        });
    }

    @action
    update(t: Partial<BillingPaymentMethod>) {
        super.update(t);
    }

    clone() {
        return new BillingPaymentMethod({
            ...this,
            address: this.address ? this.address.clone() : undefined
        });
    }
}


import React from 'react';
import LogoWhite  from './logo-axl-white.svg';
import LogoBlack from './logo-axl-black.svg';

type LogoProps = {
    theme?: 'light' | 'dark',
    width?: string | number;
    height?: string | number;
}

const AccelLogo: React.FC<LogoProps> = ({ theme, width, height }) => {

    return theme == 'light' ? <img src={LogoWhite} width={width} height={height} /> : <img src={LogoBlack} width={width} height={height} />;
}
AccelLogo.defaultProps = {
    theme: 'light',
    height: 100
}
export default AccelLogo;
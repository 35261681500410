import { Button, Modal, notification } from 'antd';
import React, { ErrorInfo } from "react";
import { isDev } from '../../enums';

type Props = {
    children: React.ReactNode;
    fallback?: React.ReactNode;
    onError?: (error: Error, errorInfo: ErrorInfo) => void;
}

type State = {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
    callStackVisible: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {
        hasError: false,
        error: undefined,
        errorInfo: undefined,
        callStackVisible: false
    };

    public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): State {
        return { hasError: true, error, errorInfo, callStackVisible: false };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
        this.props.onError?.(error, errorInfo);

        if (isDev)
            notification.error({
                message: error.toString(),
                duration: 0,
                description: <>
                    {error.stack?.split('\n')?.[1]}
                    <div>
                        <Button type='link' className='p-0' onClick={() => this.setState({ ...this.state, callStackVisible: true })}>Show call stack</Button>
                    </div>
                </>,
                placement: 'bottomLeft',
            });
    }

    public render() {
        return <>
            {this.state.hasError
                ? (this.props.fallback !== undefined ? this.props.fallback : <div>Something went wrong</div>)
                : this.props.children}
            {this.state.callStackVisible && <Modal open={true}
                onCancel={() => this.setState({ ...this.state, callStackVisible: false })}
                width={1000}
                title={this.state.error?.toString()}
                footer={null}>
                <pre>
                    {this.state.error?.stack?.toString()}
                </pre>
                <footer>
                    <Button onClick={() => this.setState({ ...this.state, callStackVisible: false })}>Close</Button>
                </footer>
            </Modal>}
        </>
    }
}
import React from 'react';
import { Tag as AntTag, TagProps } from 'antd';
import styles from './Tag.module.scss';
import { combineClasses } from '../../utils';

type Props = TagProps & {
    size?: 'xs' | 'sm' | 'md';
    className?: string;
}
const Tag: React.FC<Props> = ({ size, className, children, ...props }) => {
    return <AntTag {...props} data-size={size} className={combineClasses(styles.tag, className)}>
        {children}
    </AntTag>;
}
Tag.defaultProps = {
    size: 'md'
}
export default Tag;

import React, { useContext, useRef, useState, useEffect } from "react";
import { Row, Input, Button, Typography, Tooltip, Badge, Popconfirm, Space } from 'antd';
import styles from './Comment.module.scss';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { AccelFile, User } from '../../models';
import { Files, UserAvatar, LinkifyText, DateTime, useClipboardFiles } from '../../components';
import { Context } from '../AccelProvider/AccelProvider';
import { FileListViewType, FileListSize } from '../Files/Files';
import { PaperClipOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';

declare type CommentViewMode = 'default' | 'buttonsBelow';

export type CommentProps = {
    author?: User;

    defaultText?: string;
    defaultEditing?: boolean;
    defaultFiles?: AccelFile[];
    text?: string;
    editing?: boolean;
    files?: AccelFile[];
    placeholder?: string;
    emptyText?: React.ReactNode;
    sendBtnText?: string;
    minRows?: number;
    maxRows?: number;
    keepEmptyLines?: boolean;

    createdDate?: Date;
    updatedDate?: Date;
    clearAfterSending?: boolean;
    className?: string;
    fileView?: FileListViewType;
    fileListSize?: FileListSize;
    replyTo?: React.ReactNode;
    maxFiles?: number;
    extra?: (editing: boolean) => React.ReactNode;
    authorExtra?: (user: User) => React.ReactNode;
    viewMode?: CommentViewMode;

    useAvatar?: boolean;
    canCancel?: boolean;
    canEdit?: boolean;
    canRemove?: boolean;
    canReply?: boolean;
    disabled?: boolean;
    disableFiles?: boolean;
    enterToSubmit?: boolean;

    onFileAdded?: (file: AccelFile) => void;
    onFileRemoved?: (file: AccelFile) => void;
    onSend?: (text: string, files: AccelFile[], replyRemoved: boolean) => Promise<boolean>;
    onRemove?: () => Promise<boolean>;
    onEdit?: () => void;
    onCancelEdit?: () => void;
    onTextChange?: (text: string) => void;
    onFilesChange?: (files: AccelFile[]) => void;
    onReply?: () => void;
    onReplyRemoved?: () => void;
}


/**
 * @deprecated Use CommentForm or CommentFormEditor instead
 */
//@ts-ignore
const Comment = React.forwardRef<TextArea, CommentProps>((props, ref) => {
    const ctx = useContext(Context);
    const { loc, user, deviceStore } = ctx;

    const dropzoneRef = useRef<DropzoneRef>(null);

    const [text, _setText] = useState(props.defaultText ?? props.text ?? '');
    const setText = (val: string) => {
        if (!props.text) _setText(val);
    }

    const [files, _setFiles] = useState(props.defaultFiles?.slice() ?? props.files?.slice() ?? []);
    const setFiles = (val: AccelFile[]) => {
        if (!props.files) _setFiles(val);
    }

    const [editing, _setEditing] = useState(props.defaultEditing ?? props.editing ?? false);
    const setEditing = (val: boolean) => {
        if (!props.editing) _setEditing(val);
    }

    const [sending, setSending] = useState(false);
    const [, setRemoving] = useState(false);

    const [editingText, setEditingText] = useState<string>('');
    const [editingFiles, setEditingFiles] = useState<AccelFile[]>([]);
    const [editingReplyRemoved, setEditingReplyRemoved] = useState(false);
    const hasFocusRef = useRef<boolean>(false);

    useEffect(() => {
        if (props.editing == undefined) return;
        _setEditing(props.editing);
    }, [props.editing]);

    useEffect(() => {
        if (editing) {
            setEditingText(text);
            setEditingFiles(files.slice());
        }
    }, [editing]);

    useEffect(() => {
        if (props.text == undefined) return;
        _setText(props.text);
        if (editing) {
            setEditingText(props.text);
        }
    }, [props.text]);

    useEffect(() => {
        if (props.files == undefined) return;
        _setFiles(props.files.slice());
        if (editing) {
            setEditingFiles(props.files.slice());
        }
    }, [props.files]);

    useEffect(() => {
        if (replyTo == null)
            setEditingReplyRemoved(false);
    }, [props.replyTo]);

    const trimText = (val: string) => {
        if (props.keepEmptyLines == true) {
            return val;
        } else {
            return val.split('\n').filter((x) => x.length > 0).join('\n');
        }
    }

    const availableFileCount = props.maxFiles != null ? Math.max(0, props.maxFiles - editingFiles.length) : null;
    const canAttachFiles = availableFileCount == null || availableFileCount > 0;

    const sendComment = async () => {
        if (props.onSend == null) return;
        setSending(true);
        const trimmedText = trimText(editingText);

        const success = await props.onSend(trimmedText, editingFiles, editingReplyRemoved);
        if (success) {
            if (props.clearAfterSending == true) {
                setFiles([]);
                setText('');
                props.onReplyRemoved?.();
            } else {
                setFiles(editingFiles.slice());
                setText(trimmedText);
            }
            setEditing(false);
            setEditingFiles([]);
            setEditingText('');
            setEditingReplyRemoved(false);
        }
        setSending(false);
    }

    const attachFiles = (files: File[]) => {
        if (files.length == 0)
            return;
        if (availableFileCount != null) {
            if (availableFileCount == 0) return;
            // take max images we can upload
            if (files.length > availableFileCount)
                files = files.slice(0, availableFileCount);
        }
        const newEditingFiles = [...editingFiles];
        for (const file of files.map((x: any) => AccelFile.fromFile(x))) {
            newEditingFiles.push(file);
            props.onFileAdded?.(file);
        }
        setEditingFiles(newEditingFiles);
        props.onFilesChange?.(newEditingFiles);
    }

    const deleteFile = (file: AccelFile) => {
        const newEditingFiles = editingFiles.filter(x => x.id != file.id || x != file);
        setEditingFiles(newEditingFiles);
        props.onFileRemoved?.(file);
        props.onFilesChange?.(newEditingFiles);
    }

    useClipboardFiles(file => {
        if (!hasFocusRef.current) return;
        attachFiles([file]);
    }, props.disableFiles !== true, [props.disableFiles, attachFiles]);

    const edit = () => {
        props.onEdit?.();
        setEditing(true);
    }

    const cancelEdit = () => {
        props.onCancelEdit?.();
        setEditing(false);
        setEditingFiles([]);
        setEditingReplyRemoved(false);
    }

    const updateText = (val: string) => {
        setEditingText(val);
        props.onTextChange?.(val);
    }

    const remove = async () => {
        setRemoving(true);
        if (props.onRemove != null)
            await props.onRemove();
        setRemoving(false);
    }

    const reply = () => {
        props.onReply?.();
    }

    const removeReply = () => {
        props.onReplyRemoved?.();
        setEditingReplyRemoved(true);
    }

    const getAttachFilesTooltip = () => {
        let tooltip = loc.word('Attach files');
        if (props.maxFiles)
            tooltip += ` (${loc.word('Max', { case: 'lower' })}: ${props.maxFiles})`;
        return tooltip;
    }

    const openFileDialog = () => {
        if (!canAttachFiles) return;
        dropzoneRef.current?.open();
    }

    const disabled = props.disabled == true;

    const renderDropzone = (children: React.ReactNode) => {
        return <Dropzone ref={dropzoneRef} noClick noDrag noKeyboard onDrop={x => attachFiles(x)}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {children}
                    </div>
                </section>
            )}
        </Dropzone>;
    }

    const renderButtons = () => {
        const canSend = editingText.length > 0 || editingFiles.length > 0;
        const sendButtonVisible = props.onSend != null;
        const attachButtonVisible = props.disableFiles != true;
        if (props.viewMode != 'buttonsBelow') {
            return <>
                {sendButtonVisible &&
                    <Button type='link'
                        onClick={() => sendComment()}
                        disabled={disabled || !canSend || sending}
                        size='small'
                        className={styles.comment_send_btn}>
                        {props.sendBtnText ?? loc.word('Send')}
                    </Button>}

                {attachButtonVisible && <>
                    {!disabled
                        ? renderDropzone(<Tooltip placement='topRight' title={getAttachFilesTooltip()}>
                            <Button type='link'
                                size='large'
                                onClick={() => openFileDialog()}
                                disabled={sending || !canAttachFiles}
                                icon={<PaperClipOutlined />} />
                        </Tooltip>)
                        : <Button type='link'
                            size='large'
                            disabled
                            icon={<PaperClipOutlined />} />}
                </>}
            </>
        } else {
            return <Space className='mt-10'>
                {attachButtonVisible && <>
                    {!disabled
                        ? renderDropzone(<Button type='ghost'
                            onClick={() => openFileDialog()}
                            disabled={sending || !canAttachFiles}
                            icon={<PaperClipOutlined />}>
                            {deviceStore.size != 'mobile' && loc.word('Attach files')}
                        </Button>)
                        : <Button type='ghost'
                            disabled
                            icon={<PaperClipOutlined />}>
                            {deviceStore.size != 'mobile' && loc.word('Attach files')}
                        </Button>}
                </>}

                {sendButtonVisible &&
                    <Button type='primary'
                        onClick={() => sendComment()}
                        disabled={disabled || !canSend || sending}
                        className={styles.comment_send_btn}
                        icon={<SendOutlined />}>
                        {deviceStore.size != 'mobile' && (props.sendBtnText ?? loc.word('Send'))}
                    </Button>}
            </Space >;
        }
    }

    const renderInput = () => {
        return <div className={styles.comment_form}>
            <Input.TextArea
                ref={ref}
                value={editingText}
                disabled={disabled || sending}
                onChange={e => updateText(e.target.value)}
                placeholder={props.placeholder ?? loc.word('Enter comment')}
                autoSize={{
                    minRows: props.minRows ?? 2,
                    maxRows: props.maxRows ?? 5
                }}
                onFocus={() => hasFocusRef.current = true}
                onBlur={() => hasFocusRef.current = false}
                onKeyDown={e => {
                    if (props.enterToSubmit === true && (e.key == 'Enter' && !e.shiftKey)) {
                        e.preventDefault();
                        sendComment();
                    }
                }} />
            {props.viewMode != 'buttonsBelow' && renderButtons()}
        </div>;
    }

    const renderFiles = (files: AccelFile[]) => {
        if (files.length == 0)
            return null;
        return <div className={styles.comment_files}>
            {files.length > 0
                && <Files
                    editable={!disabled && editing}
                    deletable={!disabled && editing}
                    files={files}
                    beforeRemove={async x => {
                        deleteFile(x);
                        // prevent remove
                        return false;
                    }}
                    view={props.fileView || 'grid'}
                    size={props.fileListSize || 'md'} />}
        </div>;
    }

    const { author, createdDate, updatedDate, replyTo } = props;
    const canEdit = props.canEdit != false;
    const canRemove = props.canRemove != false;
    const canCancel = props.canCancel != false;
    const canReply = props.canReply == true;
    const useAvatar = props.useAvatar != false;

    return <div className={`${props.className ?? ''} ${styles.comment}`} data-view={props.viewMode ?? 'default'} data-files-disabled={props.disableFiles ?? false}>
        <Row>
            {(author != null && useAvatar) && <UserAvatar user={author} size={40} className='mr-10' />}
            <div className='flex-1'>
                {(author != null && user != null && !editing)
                    && <Typography.Text>
                        {user.id != author.id
                            ? props.authorExtra?.(author) ?? author.fullName
                            : loc.word('You')}
                    </Typography.Text>}
                {
                    (replyTo != null && !editingReplyRemoved)
                    && <Row align='middle'>
                        {replyTo}
                        {editing &&
                            <Tooltip title={loc.word('Remove')}>
                                <Button onClick={() => removeReply()} type='link' size='small' icon={<CloseOutlined />}></Button>
                            </Tooltip>}
                    </Row>
                }
                <div className={styles.comment_text}>
                    {!editing
                        ? <>{text ? <LinkifyText text={text} /> : props.emptyText}</>
                        : renderInput()}
                </div>
                <Row align='top' justify='space-between'>
                    <div></div>
                    {renderFiles(!editing ? files : editingFiles)}
                    {(editing && props.viewMode == 'buttonsBelow')
                        && <div className='text-right'>{renderButtons()}</div>}
                </Row>
                <Row align='middle' className={styles.comment_bottom}>

                    {createdDate != null
                        && <Typography.Text type='secondary'>
                            <Space size={2}>
                                {loc.word('Sent')}
                                <DateTime date={createdDate} />
                            </Space>
                        </Typography.Text>}

                    {updatedDate != null
                        && <>
                            {createdDate && <Badge status='default' className='separator' />}
                            <Typography.Text type='secondary'>
                                <Space size={2}>
                                    {loc.word('Updated')}
                                    <DateTime date={updatedDate} />
                                </Space>
                            </Typography.Text>
                        </>}

                    {editing
                        ? <>
                            {canCancel
                                && <>
                                    {(createdDate || updatedDate) && <Badge status='default' className='separator' />}
                                    <Button className={styles.comment_bottom_btn}
                                        disabled={disabled}
                                        type='link'
                                        size='small'
                                        onClick={() => cancelEdit()}>
                                        {loc.word('Cancel', { case: 'lower' })}
                                    </Button>
                                </>}
                        </>
                        : <>
                            {canReply
                                && <>
                                    {(createdDate || updatedDate) && <Badge status='default' className='separator' />}
                                    <Button className={styles.comment_bottom_btn}
                                        disabled={disabled}
                                        type='link'
                                        size='small'
                                        onClick={() => reply()}>
                                        {loc.word('Reply', { case: 'lower' })}
                                    </Button>
                                </>}
                            {canEdit
                                && <>
                                    {(createdDate || updatedDate || canReply) && <Badge status='default' className='separator' />}
                                    <Button className={styles.comment_bottom_btn}
                                        disabled={disabled}
                                        type='link'
                                        size='small'
                                        onClick={() => edit()}>
                                        {loc.word('Edit', { case: 'lower' })}
                                    </Button>
                                </>}
                            {canRemove
                                && <>
                                    {(createdDate || updatedDate || canReply || canEdit) && <Badge status='default' className='separator' />}
                                    {
                                        disabled
                                            ? <Button className={styles.comment_bottom_btn}
                                                disabled={true}
                                                type='link'
                                                size='small'>
                                                {loc.word('Delete', { case: 'lower' })}
                                            </Button>
                                            : <Popconfirm title={loc.word('Comment.delete.confirm')}
                                                onConfirm={() => remove()}>
                                                <Button className={styles.comment_bottom_btn}
                                                    type='link'
                                                    size='small'>
                                                    {loc.word('Delete', { case: 'lower' })}
                                                </Button>
                                            </Popconfirm>
                                    }
                                </>}
                        </>}
                    {props.extra?.(editing)}
                </Row>
            </div>
        </Row>
    </div>;
});

// TODO: remove this when refactored
Comment.defaultProps = {
    enterToSubmit: true
}

export default Comment;
import FileSizeUnit, { FileSizeUnitUtils } from './FileSizeUnit';
import { BytesPer } from './HowMany';

export default class FileSize {
    readonly bytes: number;
    readonly kilobytes: number;
    readonly megabytes: number;
    readonly gigabytes: number;

    constructor(value: number, units: FileSizeUnit = FileSizeUnit.Bytes) {
        const bytes = FileSizeUnitUtils.toBytes(value, units);
        this.bytes = bytes;
        this.kilobytes = bytes / BytesPer.Kilobytes;
        this.megabytes = bytes / BytesPer.Megabytes;
        this.gigabytes = bytes / BytesPer.Gigabytes;
    }

    add(span: FileSize): FileSize {
        return FileSize.fromBytes(this.bytes + span.bytes);
    }

    substract(span: FileSize): FileSize {
        return FileSize.fromBytes(this.bytes - span.bytes);
    }

    get total(): FileSize {
        return this;
    }

    get isZero(): boolean {
        return this.bytes == 0;
    }

    static from(value: number, units: FileSizeUnit) {
        return new FileSize(value, units);
    }

    static fromBytes(value: number) {
        return new FileSize(value, FileSizeUnit.Bytes);
    }

    static fromKilobytes(value: number): FileSize {
        return new FileSize(value, FileSizeUnit.Kilobytes);
    }

    static fromMegabytes(value: number): FileSize {
        return new FileSize(value, FileSizeUnit.Megabytes);
    }

    static fromGigabytes(value: number): FileSize {
        return new FileSize(value, FileSizeUnit.Gigabytes);
    }

    static get zero(): FileSize {
        return new FileSize(0);
    }
}
import React, { useContext, useMemo } from "react";
import { isEmpty } from "../../utils";
import { AccelContext, Context } from '../AccelProvider/AccelProvider';
import { LocalizationOptions } from "../../utils/localization/localization";

declare type PlaceholderType = 'unnamed' | 'unspecified' | 'untitled';
type ValuePlaceholderProps = {
    value: React.ReactNode;
    valueRender?: React.ReactNode;
    placeholder?: React.ReactNode;
    type?: PlaceholderType;
    ctx?: AccelContext;
    className?: string;
    placeholderClassname?: string;
    locOpts?: LocalizationOptions;
}

const ValuePlaceholder: React.FC<ValuePlaceholderProps> = ({ value, valueRender, type,
    placeholder: _placeholder, ctx: _ctx, className, placeholderClassname, locOpts }) => {
    const { loc } = _ctx ?? useContext(Context);

    const placeholder = useMemo<React.ReactNode>(() => {
        if (_placeholder !== undefined) return _placeholder;
        switch (type) {
            case 'unnamed': return loc.word('Global.unnamed', locOpts);
            case 'untitled': return loc.word('Global.untitled', locOpts);
            case 'unspecified': return loc.word('Global.unspecified', locOpts);
            default: return null;
        }
    }, [_placeholder, type]);

    return <span className={className}>
        {isEmpty(value) ? <span className={placeholderClassname}>{placeholder}</span> : (valueRender ?? value)}
    </span>;
}

ValuePlaceholder.defaultProps = {
    type: 'unnamed'
}

export default ValuePlaceholder;
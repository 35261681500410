import { action, computed, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';

export class SocialItemSettings extends QuizItemSettings {
    constructor(s?: Partial<SocialItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable facebookEnabled: boolean;
    @observable facebookLink: string;

    @observable twitterEnabled: boolean;
    @observable twitterLink: string;

    @observable vkEnabled: boolean;
    @observable vkLink: string;

    @observable instagramEnabled: boolean;
    @observable instagramLink: string;

    @observable tgEnabled: boolean;
    @observable tgLink: string;

    @observable whatsAppEnabled: boolean;
    @observable whatsAppLink: string;

    @observable linkedinEnabled: boolean;
    @observable linkedinLink: string;

    @observable shape: 'default' | 'circle' | 'round';
    @observable size: 'md' | 'sm' | 'lg';

    @computed get hasEnabled() {
        return this.facebookEnabled
            || this.twitterEnabled
            || this.vkEnabled
            || this.instagramEnabled
            || this.tgEnabled
            || this.whatsAppEnabled
            || this.linkedinEnabled;
    }

    @action update(q: Partial<SocialItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<SocialItemSettings>) {
        return new SocialItemSettings({
            ...QuizItemSettings.default,
            facebookEnabled: false,
            twitterEnabled: false,
            vkEnabled: false,
            instagramEnabled: false,
            tgEnabled: false,
            whatsAppEnabled: false,
            linkedinEnabled: false,
            shape: 'default',
            size: 'md',
            ...settings
        });
    }

    clone() {
        return new SocialItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return SocialItemSettings.build(json);
    }
}

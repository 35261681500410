import { observable, action, runInAction, computed } from 'mobx';
import { TypedEvent, S3Uploader, saveAs } from '../utils';
import { AccelFile } from '../models';
import { BeginEventArgs } from '../utils/s3-uploader/s3-uploader';
import { FileUploadStatus } from '../enums';
import Api from '../api';
import type { UploadFileArgs } from '../api/methods/file';

// TODO remove exchange with react component
export default class FileItemStore {
	@observable file: AccelFile;
	@observable percent: number = 10;
	@observable hasUploadError: boolean = false;
	@observable uploading: boolean = false;

	private s3Uploader: S3Uploader;
	constructor(private api: Api, fileUploader?: S3Uploader) {
		this.s3Uploader = fileUploader || new S3Uploader(api);
	}

	public began = new TypedEvent<BeginEventArgs>();
	public uploaded = new TypedEvent<AccelFile>();
	public aborted = new TypedEvent<void>();
	public error = new TypedEvent<void>();

	@computed
	get status(): FileUploadStatus {
		if (this.hasUploadError) return FileUploadStatus.Error;
		if (this.uploading) return FileUploadStatus.Uploading;
		return FileUploadStatus.Uploaded;
	}

	@action
	setFile(file: AccelFile) {
		if (this.file != null && this.file.id == file.id) return;
		this.file = file;
	}

	@action
	abort() {
		this.s3Uploader.abort();
		this.aborted.emit();
	}

	@computed get ready() {
		return this.file != null;
	}

	@computed get canUpload() {
		return this.ready && this.file.nativeFile != null;
	}

	@action
	async upload(links: UploadFileArgs = {}) {
		if (this.file.nativeFile == null) return;
		this.percent = 10;
		this.hasUploadError = false;
		this.uploading = true;
		links.fileId = this.file.id;

        if (links.isPublic == null) {
            links.isPublic = this.file.isImage;
        }

		this.s3Uploader.setFile(this.file.nativeFile!, links);
		this.s3Uploader.onBegin.on(x => {
			runInAction(() => {
				this.file.id = x.id;
				this.began.emit(x);
			});
		});
		this.s3Uploader.onProgress.on(x => {
			runInAction(() => {
				this.percent = Math.max(10, x.percent);
			});
		});
		this.s3Uploader.onComplete.on(x => {
			runInAction(() => {
				this.uploading = false;
				this.percent = 100;
				this.file.update({
					nativeFile: null,
					cloudKey: x.file.cloudKey,
					type: x.file.type,
					isPublic: links.isPublic ?? this.file.isPublic
				});
				this.uploaded.emit(x.file);
			});
		});
		if (!await this.s3Uploader.upload()) {
			runInAction(() => {
				this.hasUploadError = true;
				this.error.emit();
			});
		}
	}

	@action
	async download() {
		if (this.file.isExternalFile) {
			saveAs(this.file.url!, true, this.file.filename);
			return true;
		}
		const result = await this.api.file.download(this.file.id);
		if (result.success) {
			saveAs(result.response.body, false, this.file.filename);
		}
		return result.success;
	}

	@action
	async preview() {
		const result = await this.api.file.download(this.file.id, true);
		if (result.success) {
			saveAs(result.response.body, true, this.file.filename);
		}
		return result.success;
	}
}

import { computed, observable } from 'mobx';
import moment from 'moment';
import { Contact, Entity } from '../..';
import Product from '../Product';

export enum PromoCodeDiscountType {
    Percent = 'percent',
    Fixed = 'fixed',
}

export enum PromoCodeActionType {
    AllProducts = 'allProducts',
    SelectedProducts = 'selectedProducts',
}

export enum PromoCodeStatus {
    Active = 'active',
    Inactive = 'inactive',
    Expired = 'expired',
    Ended = 'ended'
}

export default class PromoCode extends Entity {
    constructor(item?: Partial<PromoCode>) {
        super(item);
        if (item) this.update(item);
    }

    createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;

    @observable beginDate: moment.Moment;
    @observable endDate: moment.Moment | null;
    @observable name: string;
    @observable code: string;
    @observable discountType: PromoCodeDiscountType;
    @observable discountAmount: number;
    @observable products: Product[];
    @observable subsRenewalDisabled: boolean;
    @observable partner: Contact | null;
    @observable useAvailableCount: boolean;
    @observable availableCount: number | null;

    actionType: PromoCodeActionType;

    @computed get status() {
        var utcNow = moment();
        if (this.endDate != null && utcNow >= this.endDate)
            return PromoCodeStatus.Expired;

        if (this.useAvailableCount && this.availableCount !== null && this.availableCount <= 0)
            return PromoCodeStatus.Ended;

        return (utcNow >= this.beginDate && (this.endDate == null || utcNow < this.endDate))
            ? PromoCodeStatus.Active
            : PromoCodeStatus.Inactive;
    }

    update(promoCode: Partial<PromoCode>) {
        super.update(promoCode);
    }

    static fromJson(json: any): PromoCode {
        return new PromoCode({
            ...json,
            createdDate: json.beginDate ? moment(json.beginDate) : undefined,
            updatedDate: json.endDate ? moment(json.endDate) : undefined,
            beginDate: json.beginDate ? moment(json.beginDate) : undefined,
            endDate: json.endDate ? moment(json.endDate) : null,
            products: json.productLinks
                ? json.productLinks.map((x: any) => Product.fromJson(x.product))
                : null,
            partner: json.partner ? Contact.fromJson(json.partner) : undefined
        });
    }

    copy(): PromoCode {
        return new PromoCode({
            ...this,
            products: this.products?.map(i => i.copy()),
            partner: this.partner?.clone(),
        });
    }
}
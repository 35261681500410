import { isDate, isEmpty as lodashIsEmpty } from 'lodash';

type Options = { trimSpaces: boolean };

export default function isEmpty(val: any, opts: Options = { trimSpaces: true }): boolean {
    let v = val;
    if (typeof (v) === 'string' && opts.trimSpaces == true)
        v = v?.trim();
    // lodash isEmpty considerd any number or boolean to be empty (not only 0)
    else if (typeof (v) === 'number' || typeof (v) === 'boolean' || isDate(val))
        return v === undefined;
    return lodashIsEmpty(v);
}
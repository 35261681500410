import React, { Key } from 'react';

export type TabPaneProps = {
    key: Key;
    tab: React.ReactNode;
    disabled?: boolean;
    tabClassName?: string;
    className?: string;
}
const TabPane: React.FC<TabPaneProps> = ({ children }) => {
    return <>{children}</>;
}

export default TabPane;
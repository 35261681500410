import type { DragSource, DropTarget } from '../models';
import { useRef, useContext, useCallback, useEffect } from 'react';
import { useLocalStore } from 'mobx-react';
import { DragDropContext } from '../DragDropProvider';
import { throttle } from 'lodash';
import { runInAction } from 'mobx';
import DragDropManager from '../DragDropManager';

export type DragProps<T> = {
    data: T;
    type?: string;
    onMouseDown?: (e: MouseEvent) => void;
    onMouseUp?: (e: MouseEvent) => void;
    onStart?: (e: MouseEvent) => void;
    onStop?: (e: MouseEvent) => void;
    onDrag?: (e: MouseEvent) => void;
    draggable?: () => boolean;
    handler?: HTMLElement;
}

export type DragOutput = {
    source: DragSource | null;
    isDragging: boolean;
    draggable: boolean;
    target: DropTarget<any> | null;
    manager: DragDropManager;
}

type DragState<T> = DragOutput & {
    source: DragSource<T> | null;
}

export type DragReturn<
    TSourceDragRef extends HTMLElement = HTMLDivElement,
    THandlerDragRef extends HTMLElement = HTMLElement
    > = [
        DragOutput,
        {
            sourceRef: React.RefObject<TSourceDragRef>;
            handlerRef: React.RefObject<THandlerDragRef>;
        }
    ];

export default function useDrag<T = {},
    TSourceDragRef extends HTMLElement = HTMLDivElement,
    THandlerDragRef extends HTMLElement = HTMLElement>
    (props: DragProps<T>): DragReturn<TSourceDragRef, THandlerDragRef> {

    const sourceRef = useRef<TSourceDragRef>(null);
    const handlerRef = useRef<THandlerDragRef>(null);
    const { manager } = useContext(DragDropContext);

    const state = useLocalStore<DragState<T>>(() => ({
        manager,
        source: null,
        get isDragging(): boolean {
            return state.source != null && manager.isCurrentSource(state.source);
        },
        get draggable() {
            return props.draggable?.() ?? true;
        },
        get target() {
            if (!state.isDragging) return null;
            return manager.currentTarget;
        }
    }));

    const getSourceElement = () => sourceRef.current!;
    const getHandlerElement = () => (handlerRef.current ?? sourceRef.current!);

    const onMouseDown = useCallback((e: MouseEvent) => {
        log('onMouseDown');
        if (!state.draggable) return false;
        state.source!.e = e;
        const srcElement = getSourceElement();
        srcElement.setAttribute('draggable', 'true');
        props.onMouseDown?.(e);
    }, []);

    const onMouseUp = useCallback((e: MouseEvent) => {
        log('onMouseUp');
        if (!state.source || !state.source.e) return;
        state.source!.e = undefined;
        const srcElement = getSourceElement();
        srcElement.removeAttribute('draggable');
        props.onMouseUp?.(e);
    }, []);

    const onDragStart = useCallback((e: MouseEvent) => {
        log('onDragStart');

        manager.setCurrentSource(state.source);
        props.onStart?.(e);
    }, []);

    const onDrag = useCallback(throttle((e: MouseEvent) => {
        log('onDrag');
        props.onDrag?.(e);
    }, 100, { leading: true, trailing: false }), []);

    const onDragEnd = useCallback((e: MouseEvent) => {
        log('onDragEnd')
        manager.setCurrentSource(null);

        const srcElement = getSourceElement();
        srcElement.removeAttribute('draggable');

        props.onStop?.(e);
    }, []);

    const log = (msg: string) => {
        //console.log(msg);
    }

    useEffect(() => {
        const handlerElement = getHandlerElement();
        if (handlerElement == null) return;

        const srcElement = getSourceElement();
        const source = manager.addSource({
            el: srcElement,
            handlerEl: handlerElement,
            data: props.data,
            type: props.type
        });
        runInAction(() => state.source = source);

        handlerElement.addEventListener('mousedown', onMouseDown);
        document.addEventListener('mouseup', onMouseUp);
        srcElement.addEventListener('dragstart', onDragStart);
        srcElement.addEventListener('drag', onDrag);
        srcElement.addEventListener('dragend', onDragEnd);

        return () => {
            manager.deleteSource(state.source!);

            handlerElement.removeEventListener('mousedown', onMouseDown);
            document.removeEventListener('mouseup', onMouseUp);
            srcElement.removeEventListener('dragstart', onDragStart);
            srcElement.removeEventListener('drag', onDrag);
            srcElement.removeEventListener('dragend', onDragEnd);
        }
    }, [sourceRef, handlerRef]);

    return [state, {
        sourceRef,
        handlerRef
    }];
}
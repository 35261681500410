import { observable, action } from 'mobx';
import { Admin, Contact, Entity } from '../..';
import UserNotificationChannel from '../Channels/Channel';

export default class UserNotificationSettings extends Entity {
    constructor(s?: Partial<UserNotificationSettings>) {
        super(s);
        if (s) this.update(s);
    }

    @observable admin: Admin;
    @observable contact: Contact;

    @observable useEmail: boolean;
    @observable email: string;
    @observable customEmail: string | null;

    @observable useTg: boolean;
    @observable tgId: string | null;
    @observable channels: UserNotificationChannel[];
    tgLink: string;
    
    @observable useWebPush: boolean;

    static fromJson(json: any): UserNotificationSettings {
        return new UserNotificationSettings({
            id: json.id,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            useEmail: json.useEmail,
            email: json.email,
            customEmail: json.customEmail,
            useTg: json.useTg,
            tgId: json.tgId,
            tgLink: json.tgLink,
            useWebPush: json.useWebPush,
            channels: json.channels ? json.channels.map(UserNotificationChannel.fromJson) : [],
        });
    }

    @action
    update(n: Partial<UserNotificationSettings>) {
        super.update(n);
    }
}
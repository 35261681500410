import { Select, SelectProps } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect } from "react";
import { AccelContext, Context } from "../AccelProvider/AccelProvider";
import { useUpdateEffect } from "../hooks/useUpdateEffect";
import { observer, useLocalStore } from "mobx-react";
import { runInAction } from "mobx";
import { combineClasses } from "../../utils";


type DateSplitPickerProps = Pick<SelectProps, 'size' | 'disabled'> & {
    value?: Moment | null;
    defaultValue?: Moment;
    ctx?: AccelContext;
    className?: string;
    // TODO: add settings to hide or show parts of picker
    onChange?: (value: Moment) => void;
}

const DateSplitPicker: React.FC<DateSplitPickerProps> = ({ value, defaultValue, ctx: _ctx, className, onChange, ...props }) => {
    const { loc } = _ctx ?? useContext(Context);

    const state = useLocalStore(() => ({
        day: value?.date() ?? defaultValue?.date(),
        month: value ? value?.month() + 1 : defaultValue ? defaultValue.month() + 1 : undefined,
        year: value?.year() ?? defaultValue?.year(),

        get days() {
            return Array.from({ length: state.daysInMonth }, (_, i) => i + 1);
        },

        get months() {
            return Array.from({ length: 12 }, (_, i) => i + 1);
        },

        get years() {
            const currentYear = moment().year();
            return Array.from({ length: 100 }, (_, i) => currentYear - i);
        },

        get daysInMonth() {
            if (state.month === undefined || state.year === undefined)
                return 31;

            return moment({ day: 1, month: state.month - 1, year: state.year }).daysInMonth();
        },

        get date() {
            if (state.day === undefined || state.month === undefined || state.year === undefined)
                return undefined;
            return moment({ day: state.day, month: state.month - 1, year: state.year });
        },

        onChange: onChange,
        update(dateObj: { day?: number, month?: number, year?: number }) {
            let day = dateObj.day ?? state.day ?? 1;
            const month = dateObj.month ?? state.month ?? 1;
            const year = dateObj.year ?? state.year ?? moment().year();
            // check if month or year changed and update day if needed
            if (dateObj.month !== undefined || dateObj.year !== undefined) {
                const daysInMonth = moment({ day: 1, month: month - 1, year }).daysInMonth();
                day = Math.min(daysInMonth, day);
            }

            runInAction(() => {
                state.day = day;
                state.month = month;
                state.year = year;
            });

            if (state.date !== undefined)
                state.onChange?.(state.date);
        },

        reset() {
            runInAction(() => {
                state.day = undefined;
                state.month = undefined;
                state.year = undefined;
            })
        }
    }));

    // update onChange link when prop changes
    useEffect(() => {
        state.onChange = onChange;
    }, [onChange]);

    // trigger date change when value prop changes
    useUpdateEffect(() => {
        if (!value) {
            state.reset();
            return;
        }
        if (!value.isSame(state.date, 'day') || !value.isSame(state.date, 'month') || !value.isSame(state.date, 'year')) {
            state.update({ day: value?.date(), month: value?.month() + 1, year: value?.year() });
        }
    }, [value]);

    return <div className={combineClasses(className, 'flex flex-1 gap-5')}>
        <Select {...props}
            placeholder={loc.word('DateSplitPicker.dayPlaceholder')}
            value={state.day}
            options={state.days.map(day => ({ value: day, label: day }))}
            onChange={day => state.update({ day })}
            style={{ flex: 1 }} />

        <Select {...props}
            placeholder={loc.word('DateSplitPicker.monthPlaceholder')}
            value={state.month}
            options={state.months.map(month => ({ value: month, label: loc.word(`Month.${month}`) }))}
            onChange={month => state.update({ month })}
            style={{ flex: 2 }} />

        <Select {...props}
            placeholder={loc.word('DateSplitPicker.yearPlaceholder')}
            value={state.year}
            options={state.years.map(year => ({ value: year, label: year }))}
            onChange={year => state.update({ year })}
            style={{ flex: 1 }} />
    </div>;
}
export default observer(DateSplitPicker);

export enum UserNotificationType {
    // Admin
    AccountLoginSuccess = 'accountLoginSuccess',
    AccountLoginPrevented = 'accountLoginPrevented',
    EmailConfirmed = 'adminEmailConfirmed',

    CourseTaskAnswer = 'courseTaskAnswer',
    Order = 'order',
    OrderPayment = 'orderPayment',
    AdminTaskStatus = 'adminTaskStatus',
    AdminTaskCreated = 'adminTaskCreated',
    AdminTaskReminder = 'adminTaskReminder',
    CustomNotification = 'customNotification',
    CourseTaskComment = 'courseTaskComment',
    CourseItemComment = 'courseItemComment',
    LibraryItemComment = 'libraryItemComment',

    PipelineDealCreated = 'pipelineDealCreated',
    PipelineDealCompleted = 'pipelineDealCompleted',

    // Student
    CourseTaskAnswerStatus = 'courseTaskAnswerStatus',
    PasswordChanged = 'passwordChanged',

    // Chat
    NewChatMessage = 'receivedNewChatMessage',

    // Email
    NewEmailMessage = 'receivedNewEmailMessage',

    // Partnership
    AdminPartnershipTransactionRequest = 'adminPartnershipTransactionRequest',
    StudentPartnershipTransaction = 'studentPartnershipTransaction',
    StudentPartnershipRegistration = 'studentPartnershipRegistration',
    StudentBonusTransaction = 'studentBonusTransaction'
}

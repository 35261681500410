import { Updatable } from '../../..';

export default class SmartGlocalSettings extends Updatable {
    public project: string;
    public privateKey: string;
    public publicKey: string;  
    public testMode: boolean;

    update(entity: Partial<SmartGlocalSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
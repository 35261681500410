import { debounce } from 'lodash';
import { action } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';
import styles from './VirtualScroll.module.scss';

type VirtualScrollProps = {
    itemHeight: number;
    buffer?: number;
}

const VirtualScroll: React.FC<VirtualScrollProps> = ({ itemHeight, buffer, children }) => {
    const viewportRef = useRef<HTMLDivElement>(null);

    const state = useLocalStore(() => ({
        get scrollHeight() {
            return state.items.length * itemHeight;
        },
        get offsetTop() {
            return state.firstVisibleIndex * itemHeight;
        },
        parentEl: undefined as HTMLElement | undefined,
        setParentEl: action((el: HTMLElement) => {
            state.parentEl = el;
            state.setParentHeight(el.clientHeight);
        }),
        parentHeight: 0,
        setParentHeight: action((height: number) => {
            state.parentHeight = height;
        }),
        scrollY: 0,
        setScrollY: action((scrollY: number) => {
            state.scrollY = scrollY;
        }),
        items: [] as React.ReactNode[],
        setItems: action((items: React.ReactNode[]) => {
            state.items = items;
        }),
        get currentVisibleIndex() {
            return Math.ceil(state.scrollY / itemHeight);
        },
        get firstVisibleIndex() {
            return Math.max(0, state.currentVisibleIndex - buffer!);
        },
        get lastVisibleIndex() {
            return Math.min(Math.ceil(state.parentHeight / itemHeight) + state.currentVisibleIndex + buffer!, state.items.length);
        },
        get visibleItems() {
            return state.items.slice(state.firstVisibleIndex, state.lastVisibleIndex);
        },
    }));

    const handleScroll = useCallback(debounce(() => {
        state.setScrollY(state.parentEl!.scrollTop);
    }, 10, { maxWait: 10 }), []);

    const handleResize = useCallback(debounce(() => {
        state.setParentHeight(state.parentEl!.clientHeight);
    }, 50, { maxWait: 50 }), []);

    useEffect(() => {
        state.setParentEl(viewportRef.current!.parentElement!);

        state.parentEl!.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            state.parentEl!.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        state.setItems(React.Children.toArray(children));
    }, [children]);

    return <div ref={viewportRef} className={styles.viewport} style={{ height: state.scrollHeight }}>
        {state.parentEl &&
            <div className={styles.content} style={{ top: state.offsetTop }}>
                {state.visibleItems}
            </div>}
    </div>;
}

VirtualScroll.defaultProps = {
    buffer: 1,
}

export default observer(VirtualScroll);
import { action, observable } from "mobx";
import moment, { type Moment } from "moment";
import { Admin, Entity, School, SharedSettingsExport, SharedSettingsImportEntity, Site } from "../..";

export enum SharedSettingsImportStatus {
    Created = 'created',
    Processing = 'processing',
    Completed = 'completed',
    Error = 'error'
}

export default class SharedSettingsImport extends Entity {
    constructor(i?: Partial<SharedSettingsImport>) {
        super();
        if (i) this.update(i);
    }

    @observable status: SharedSettingsImportStatus;
    @observable errorMsg: string;
    @observable email: string;

    @observable export: SharedSettingsExport;
    @observable site: Site | null;

    createdDate: Moment;
    updatedDate?: Moment;

    createdByAdmin: Admin;
    entities: SharedSettingsImportEntity[];
    school: School;

    @action
    update(session: Partial<SharedSettingsImport>) {
        super.update(session);
    }

    static fromJson(json: any) {
        return new SharedSettingsImport({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            export: json.export ? SharedSettingsExport.fromJson(json.export) : undefined,
            site: json.site ? Site.fromJson(json.site) : undefined,
            createdByAdmin: json.createdByAdmin ? Admin.fromJson(json.createdByAdmin) : undefined,
            entities: json.entities ? json.entities.map(SharedSettingsImportEntity.fromJson) : undefined,
            school: json.school ?  School.fromJson(json.school) : undefined
        });
    }
}


import { type Moment } from 'moment';
import { observable, action } from 'mobx';
import { Admin, Scenario } from '..';
import moment from 'moment';
import { Designable } from '../Entity';

export default class Widget extends Designable {
    constructor(widget?: Partial<Widget>) {
        super(widget);
        if (widget) this.update(widget);
    }

    @observable name: string;
    @observable description: string;
    @observable viewCount: number;

    @observable createdDate: Moment;
    @observable createdByAdmin: Admin;

    @observable updatedDate: Moment;
    @observable updatedByAdmin: Admin;

    @observable scenario: Scenario;

    static fromJson(json: any): Widget {
        const widget = new Widget({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            createdByAdmin: json.createdByAdmin ? Admin.fromJson(json.createdByAdmin) : undefined,
            updatedByAdmin: json.updatedByAdmin ? Admin.fromJson(json.updatedByAdmin) : undefined,
        });
        if (widget.scenario) widget.scenario.update({ widget });
        return widget;
    }

    @action
    update(widget: Partial<Widget>) {
        super.update(widget);
    }
}


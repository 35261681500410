import React, { ReactNode } from "react";
import { Context } from '../AccelProvider/AccelProvider';
import InfiniteScroll from 'react-infinite-scroller';
import VisibilitySensor from 'react-visibility-sensor';
import { observer } from 'mobx-react';
import { observable, computed, action } from 'mobx';
import styles from './InfinityList.module.scss';
import { Pagination, Empty } from 'antd';
import { InfinityListStore } from './InfinityListStore';
import { BaseFilter, Entity } from '../../models';
import { InView } from 'react-intersection-observer';

export declare type FetchFunction<T> = (page: number, take: number) => Promise<{ items: T[], filter: BaseFilter<T> }>;
type InfinityListProps<T> = {
    fetch: FetchFunction<T>;
    pageStart?: number;
    pageSize?: number;
    useWindow?: boolean;
    pagination?: boolean;
    height?: string;
    renderItem: (item: T, isVisible: boolean) => ReactNode;
    placeholder?: () => ReactNode;
    trackItemVisibility?: boolean;

    onPageFetched?: (page: number) => void;
}

type ItemCacheValue = [boolean, ReactNode];

@observer
export default class InfinityList<T extends Entity> extends React.Component<InfinityListProps<T>> {
    static contextType = Context;
    context!: React.ContextType<typeof Context>;

    get loc() { return this.context.loc; }
    private container: HTMLDivElement | null;
    private itemsCache = new Map<string, ItemCacheValue>();

    constructor(props: InfinityListProps<T>) {
        super(props);
        if (props.pageSize)
            this.store.filter.update({ take: props.pageSize });
    }

    componentDidMount() {
        this.fetchPage(this.pageStart);
    }

    @observable pageStart: number = this.props.pageStart || 1;
    @observable lastFetchedPage: number = 0;
    @observable store = new InfinityListStore<T>(this.props.fetch);

    get trackItemVisibility() {
        return this.props.trackItemVisibility !== false;
    }

    get isScrolledToBottom() {
        // we can use just return this.container!.scrollHeight - this.container!.scrollTop === this.container!.clientHeight; 
        // but all rounded which could potentially lead to a 3px error if all align
        // https://stackoverflow.com/questions/3898130/check-if-a-user-has-scrolled-to-the-bottom/3898152#comment92747215_34550171
        return Math.abs(this.container!.scrollHeight - this.container!.scrollTop - this.container!.clientHeight) <= 3.0
    }

    public get pageTotal() {
        return this.store.filter.pageTotal;
    }

    public get items() {
        return this.store.items;
    }

    public async goToPage(page: number, scrollToBottom: boolean = false) {
        if (page < 0 || page > this.store.filter.pageTotal)
            throw new Error('page is out of range');
        if (this.currentPage == page)
            return;
        await this.setPage(page);
        if (scrollToBottom)
            this.scrollToBottom();
    }

    public async goToPosition(position: number) {
        const page = this.calcPageByPosition(position);
        await this.setPage(page);
        // dirty hack
        setTimeout(() => {
            const el = this.container!.querySelector(`[data-position="${position}"]`) as HTMLElement;
            if (el == null) return;
            this.container!.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
            this._highlightItem(el);
        }, 50);
    }

    public updateFilter(filter: Partial<BaseFilter<T>>) {
        this.store.filter.update(filter);
    }

    public scrollToBottom() {
        // dirty hack
        setTimeout(() => {
            this.container!.scrollTo({ top: this.container!.scrollHeight, behavior: 'auto' });
        }, 50);
    }

    public highlightItem(id: string) {
        const el = this.container!.querySelector(`[data-id="${id}"]`) as HTMLElement;
        if (el == null) return;
        this._highlightItem(el);
    }

    @action public pushItem(item: T, forceScroll: boolean = false) {
        const needScrollToBottom = forceScroll == true || this.isScrolledToBottom;

        this.store.items.push(item);
        const oldPageTotal = this.pageTotal;
        this.store.filter.update({ itemsTotal: this.store.filter.itemsTotal + 1 });
        // in case if a new pushed item starts a new page
        if (this.pageTotal - oldPageTotal == 1)
            this.lastFetchedPage = this.pageTotal;

        if (needScrollToBottom)
            this.scrollToBottom();
    }

    @action public tryRemoveItem(id: string) {
        const i = this.store.items.findIndex(x => x.id == id);
        if (i > -1) {
            this.store.items.splice(i, 1);
            this.store.filter.update({ itemsTotal: this.store.filter.itemsTotal - 1 });
            return true;
        }
        return false;
    }

    @action public tryFindItem(id: string) {
        return this.store.items.find(x => x.id == id);
    }

    @computed get offset() {
        return Math.max(0, this.pageStart - 1) * this.store.filter.take;
    }

    @computed get hasMore() {
        return this.lastFetchedPage == 0 || this.pageTotal > this.lastFetchedPage;
    }

    @observable currentPage = this.pageStart;

    // @debounce(100, { maxWait: 200, leading: true })
    @action calcCurrentPage() {
        // +1 because index starts from 0
        const page = this.calcPageByPosition(this.lastVisibleIndex + 1);
        this.currentPage = Math.max(this.pageStart, page);
    }

    @observable lastVisibleIndex: number = 0;
    @observable visibleIndecies = new Set<number>();
    @action private setChildVisibility(isVisible: boolean, index: number) {
        index = this.offset + index;
        if (isVisible) {
            this.visibleIndecies.add(index);
        } else {
            this.visibleIndecies.delete(index);
        }
        if (this.visibleIndecies.size == 0) return;
        const max = Math.max(...Array.from(this.visibleIndecies));
        if (max != this.lastVisibleIndex) {
            this.lastVisibleIndex = max;
            this.calcCurrentPage();
        }
    }

    @action private async fetchPage(page: number, reset: boolean = false) {
        if (!this.hasMore) return;
        if (page > this.lastFetchedPage)
            this.lastFetchedPage = page;
        this.store.filter.update({
            page: page
        });
        await this.store.fetch(reset);
        this.props.onPageFetched?.(page);
    }

    @action private onPageChange(page: number, pageSize?: number) {
        this.setPage(page);
    }

    @action private async setPage(page: number) {
        this.visibleIndecies.clear();
        this.lastFetchedPage = 0;
        this.pageStart = page;

        await this.fetchPage(page, true);
        this.container!.scrollTo({ top: 0, behavior: 'auto' });
    }

    private calcPageByPosition(position: number) {
        return Math.ceil(position / this.store.filter.take);
    }

    private getOrCreateItemCache(item: T, isVisible: boolean): ReactNode {
        let itemCache = this.itemsCache.get(item.id);
        if (itemCache == undefined || (this.trackItemVisibility && itemCache[0] != isVisible)) {
            itemCache = [isVisible ?? false, this.props.renderItem(item, isVisible ?? false)];
            this.itemsCache.set(item.id, itemCache);
            return itemCache[1];
        }
        return itemCache[1];
    }

    private _highlightItem(el: HTMLElement) {
        el.classList.add(styles.il_item_highlight);
        setTimeout(() => {
            el.classList.remove(styles.il_item_highlight);
        }, 2000);
    }

    render() {
        const placeholder = this.props.placeholder?.();
        return <div className={styles.il}>
            {
                this.props.pagination != false && this.store.filter.pageTotal > 1
                    ? <div className={styles.il_pagination}>
                        <Pagination
                            size='small'
                            total={this.store.filter.itemsTotal}
                            current={this.currentPage}
                            pageSize={this.store.filter.take}
                            showSizeChanger={false}
                            onChange={(page, pageSize) => this.onPageChange(page, pageSize)}
                        />
                    </div>
                    : null
            }
            <div style={{ height: this.props.height || '100%' }}
                className={styles.il_scroller}
                ref={ref => this.container = ref}>
                <InfiniteScroll
                    pageStart={this.pageStart}
                    initialLoad={false}
                    loadMore={() => this.fetchPage(this.lastFetchedPage + 1)}
                    hasMore={this.hasMore}
                    loader={undefined}
                    useWindow={this.props.useWindow || false}>
                    {this.store.items.length == 0 && !this.store.fetching
                        ? <>{placeholder}</>
                        : null}
                    {this.store.items.map((x: T, index) =>
                        <InView key={x.id}
                            root={this.container}
                            threshold={0.1}
                            onChange={visible => this.setChildVisibility(visible, index)}>
                            {({ inView }) => {
                                const item = this.getOrCreateItemCache(x, inView);
                                return <div data-id={x.id} data-position={index + this.offset + 1}>{item}</div>;
                            }}
                        </InView>)}
                </InfiniteScroll>
            </div>
        </div>;
    }
}
import React, { ReactNode, useContext } from "react";
import { KeyGenerator } from "../../utils";

type StringTemplateProps = {
    template: string;
    args?: ReactNode[];
    className?: string;
}
const StringTemplate: React.FC<StringTemplateProps> = ({ template, ...props }) => {

    const matchAll = (template: string, regex: RegExp) => {
        let match;
        const matches: RegExpMatchArray[] = [];

        do {
            match = regex.exec(template);
            if (match) {
                matches.push(match);
            }
        } while (match);

        return matches;
    }

    const matches = Array.from(matchAll(template, /{(\d+)}/g));
    if (matches.length > 0) {
        const args = props.args ?? React.Children.toArray(props.children);

        if (args == null || args.length == 0) {
            console.error(`StringTemplate '${template}' invalid args: required ${matches.length}, actual ${args?.length ?? 0}`);
            return <span>{template}</span>;
        }
        const nodes: Array<ReactNode> = [];
        let lastIndex = 0;
        for (let i = 0; i < matches.length; i++) {
            const match = matches[i];
            const argIndex = parseInt(match[1]);
            nodes.push(template.substring(lastIndex, match.index!));
            nodes.push(args[argIndex]);
            lastIndex = match.index! + 3;
        }
        nodes.push(template.substr(lastIndex));
        return <span className={props.className}>{nodes.map(x => <span key={KeyGenerator.generate(5)}>{x}</span>)}</span>;
    }
    return <span className={props.className}>{template}</span>;
}
export default StringTemplate;
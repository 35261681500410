
import { observable, action } from 'mobx';
import { CallSIPRegistration, Entity } from '../..';

export enum CallLineType {
    Sip = 'sip',
    Phone = 'phone'
}

export default class CallLine extends Entity {
    constructor(callLine?: Partial<CallLine>) {
        super(callLine);
        if (callLine) this.update(callLine);
    }

    @observable sipRegistration: CallSIPRegistration | null;
    @observable type: CallLineType;

    static fromJson(json: any): CallLine {
        return new CallLine({
            ...json,
            sipRegistration: json.sipRegistration ? CallSIPRegistration.fromJson(json.sipRegistration) : null,
        });
    }

    @action
    update(callLine: Partial<CallLine>) {
        super.update(callLine);
    }
}


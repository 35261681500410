import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';


import {
	FileExcelOutlined,
	FileOutlined,
	FilePdfOutlined,
	FilePptOutlined,
	FileWordOutlined,
	FileZipOutlined,
	FolderOutlined,
} from '@ant-design/icons';

import { useInstance } from 'react-ioc';
import FileManagerStore, { ViewFileManagerItem } from './FileManagerStore';
import styles from './FileManager.module.scss';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import FileManager, { FileManagerMode } from './FileManager';
import { FileItemStore } from '../../stores';
import { FileManagerItemType, FileType } from '../../models';
import { useDrag, useDrop } from '../DnD';
import { KeyCode, FileUploadStatus, ErrorCode } from '../../enums';
import { Localization } from '../../utils';
import { notification, Menu, Tooltip, Progress, Dropdown } from 'antd';
import { Context } from '../AccelProvider/AccelProvider';
import { ApiResult } from '../../api';
import { UpdateFileManagerItemResponse } from '../../api/methods/file-manager/types/update-item';



export type ViewFileManagerItemRef = {
	rename: () => void;
	delete: () => void;
	changeParent: (item: ViewFileManagerItem, parentId: string | null) => void;
	setMoved: (b: boolean) => void;
}

const FileManagerOneItem: React.FC<{ item: ViewFileManagerItem; fileManager: FileManager, managerStore: FileManagerStore }> = ({ item, fileManager, managerStore }) => {
	const ctx = useContext(Context);
	const { loc, api } = ctx;
	const [fileItemStore] = useState(new FileItemStore(api));

	const [isDropActive, setIsDropActive] = useState(false);
	const [isMoved, setIsMoved] = useState(false);

	const renameInputRef = useRef<HTMLInputElement>(null);
	const getProperRef = () => item.type == FileManagerItemType.File ? sourceRef : dropRef;

	const [, { sourceRef }] = useDrag<ViewFileManagerItem, HTMLDivElement>({
		data: item
	});

	const [, dropRef] = useDrop<ViewFileManagerItem, HTMLDivElement>({
		onDrop: async (src, _, e) => {
			changeParent(src.data, item.id);
		},
		onEnter: () => setIsDropActive(true),
		onLeave: () => setIsDropActive(false)
	});


	useEffect(() => {
		item.itemHandler = {
			delete: deleteItem,
			rename: rename,
			changeParent: changeParent,
			setMoved: setIsMoved
		}
	}, [])

	useEffect(() => {
		if (!item.isUploading || item.type != FileManagerItemType.File) return;

		fileItemStore.aborted.on(x => managerStore.abortUploading(item));
		fileItemStore.uploaded.on(uploadedFile => {
			item.isUploading = false;
			item.file!.update(uploadedFile);
			managerStore.createFileManagerItem(item, uploadedFile);
		})
		fileItemStore.setFile(item.file!);
		if (item.file!.nativeFile) fileItemStore.upload();
	}, [item.isUploading])

	useEffect(() => {
		if (!item.isRenaming) return;
		renameInputRef?.current?.focus();
		renameInputRef?.current?.select();
	}, [item.isRenaming])


	const changeParent = async (droppedItem: ViewFileManagerItem, newFolderId: string | null) => {
		var result = await managerStore.changeParentFolderSaveToServer(droppedItem, newFolderId);
		if (result.success)
			droppedItem?.itemHandler?.setMoved(true);

		result.notify({
			success: {
				message: loc.word('FM.updateFile.successfulMsg', { default: 'File/folder updated successfully' }),
				duration: 2
			},
			loc: loc
		})
	};

	const selectToggle = () => {
		if (fileManager.mode == FileManagerMode.SelectOneURL) {
			managerStore.deselectAll();
		}

		runInAction(() => item.isSelected = !item.isSelected)

		if (item.isSelected && item.type == FileManagerItemType.File) {
			fileManager.onSelected();
		}
	}

	const onDoubleClick = () => {
		if (item.isRenaming) return;
		if (item.type == FileManagerItemType.Directory)
			managerStore.changeParent(item.id);
	}

	const abortUploading = () => {
		fileItemStore.abort();
		managerStore.abortUploading(item);
	}

	const rename = () => {
		renameCancel();
		runInAction(() => {
			item.newTemporaryName = item.name;
			item.isRenaming = true;
		})
		setTimeout(() => {
			window.addEventListener("keyup", renameKeyUpListener);
			window.addEventListener("click", renameOnOutsideClick);
		}, 33);
	}

	const renameKeyUpListener = async (e: KeyboardEvent) => {
		switch (e.keyCode) {
			case KeyCode.Escape:
				renameCancel();
				break;
			case KeyCode.Enter:
				renameApply();
				break;
		}
	}

	const renameApply = async () => {
		var result = await managerStore.renameSaveToServer(item);
		if (result.success)
			renameCancel();

		result.notify({
			success: {
				message: loc.word('FM.updateFile.successfulMsg', { default: 'File/folder updated successfully' }),
				duration: 2
			},
			error: { message: loc.word('Error occured'), duration: 2 }
		})
	}

	const renameOnOutsideClick = (e: MouseEvent) => {
		if (item.isRenaming == false) return;

		const ref = getProperRef();
		if (ref.current && ref.current != e.target && !ref.current.contains(e.target as Node)) {
			renameApply();
		}
	}

	const renameCancel = () => {
		runInAction(() => {
			item.isRenaming = false;
		})
		window.removeEventListener("keyup", renameKeyUpListener);
		window.removeEventListener("click", renameOnOutsideClick);
	}

	const deleteItem = () => {
		fileManager.deleteSelected(item);
	}


	//UI Below

	const renderPreview = () => {
		if (item.type == FileManagerItemType.Directory) return <FolderOutlined />;
		if (!item.file) return <FileOutlined />;

		if (item.file.type == FileType.Image || item.file.previewImage) {
			return <div className={styles.imageCover} style={{ backgroundImage: `url('${fileManager.fileProvider.getUrl(item.file)}')` }} />;
		}

		if (item.file.type == FileType.Video)
			return <div className={styles.imageCover} style={{ backgroundImage: `url('${fileManager.fileProvider.getUrlByKey(item.file.videoThumbnailUrl)}')` }} />;

		if (item.file.mimetype) {
			if (item.file.mimetype.includes('doc')) return <FileWordOutlined />;
			if (item.file.mimetype.includes('pdf')) return <FilePdfOutlined />;
			if (item.file.mimetype.includes('xls')) return <FileExcelOutlined />;
			if (item.file.mimetype.includes('ppt')) return <FilePptOutlined />;
			if (item.file.mimetype.includes('zip') || item.file.mimetype.includes('rar')) return <FileZipOutlined />;
		}

		return <FileOutlined />;
	};


	const menu = () => {
		return (
			<Menu>
				{item.type == FileManagerItemType.Directory &&
					<Menu.Item key="0" onClick={() => onDoubleClick()}>{loc.word('Do Open')}</Menu.Item>
				}
				<Menu.Item key="1" onClick={() => rename()}>{loc.word('Rename')}</Menu.Item>
				<Menu.Item key="2" onClick={() => deleteItem()}>{loc.word('Delete')}</Menu.Item>
			</Menu>
		);
	};


	if (item.type == FileManagerItemType.File && !item?.id) return null;

	if (fileItemStore.status == FileUploadStatus.Error) {
		return <div className={styles.fileManagerTreeItem}>
			<div className={styles.itemPreview}>
				<Tooltip title={<>{loc.word('Error occured')}
					{loc.word('Click to remove image')}</>}>
					<div onClick={() => managerStore.removeItemFromList(item)} style={{ cursor: 'pointer' }} className={styles.fileManagerTreeItemOverlay}>
						<Progress type="circle" percent={100} status='exception' width={40} />
					</div>
				</Tooltip>
				{renderPreview()}
			</div>
			<div className={styles.itemMeta}>
				<div className={styles.itemMetaContent}>{item.name}</div>
			</div>
		</div>
	} else if (fileItemStore.status == FileUploadStatus.Uploading) {
		return <div className={styles.fileManagerTreeItem}>
			<div className={styles.itemPreview}>
				<Tooltip title={loc.word('Click to cancel uploading')}>
					<div onClick={() => abortUploading()} style={{ cursor: 'pointer' }} className={styles.fileManagerTreeItemOverlay}>
						<Progress type="circle" percent={fileItemStore.percent} width={40} />
					</div>
				</Tooltip>
				{renderPreview()}
			</div>
			<div className={styles.itemMeta}>
				<div className={styles.itemMetaContent}>{item.name}</div>
			</div>
		</div>
	}

	const itemClasses = [styles.fileManagerTreeItem];
	if (item.isSelected) itemClasses.push(styles.fileManagerTreeItemSelected)
	if (isDropActive) itemClasses.push(styles.fileManagerTreeItemDropActive)
	if (isMoved) itemClasses.push(styles.fileManagerTreeItemMoved)


	return <Dropdown overlay={menu()} trigger={['contextMenu']}>
		<div ref={getProperRef()}
			className={itemClasses.join(" ")}
			onDoubleClick={() => onDoubleClick()}
			onClick={() => selectToggle()}>
			<div className={styles.itemPreview}>
				{renderPreview()}
			</div>
			<div className={styles.itemMeta}>
				{item.isRenaming ?
					<div className={styles.itemMetaContent}>
						<input ref={renameInputRef} type="text" defaultValue={item.name} onKeyUp={(e: any) => item.newTemporaryName = e.target.value} />
					</div>
					: <div className={styles.itemMetaContent}>
						{item.name}
						{item.type == FileManagerItemType.File ? item.file!.extension : undefined}
					</div>
				}
			</div>
		</div>
	</Dropdown>
}

export default observer(FileManagerOneItem)
import { BaseFilter, Entity } from '../models';
import { observable, runInAction, computed, action } from 'mobx';
import { Disposable } from '../utils';

export type InfinityListStoreFetchRequest = {
    startIndex: number;
    stopIndex: number;
    fields?: string;
};
export type InfinityListStoreFetchResult<T, TFilter> = {
    success: boolean;
    items: T[];
    filter: TFilter
};

/**
* first rendered index - last rendred index
*/
export type InfinityListViewport = {
    startIndex: number;
    stopIndex: number;
}

export abstract class InfinityListStore<T extends Entity, TFilter extends BaseFilter<T>> extends Disposable {
    @observable hasError: boolean = false;
    @observable isFetching: boolean = false;
    // no @observable because we need to modify array by index out of range
    items: T[] = [];
    itemsMap: Map<string, T> = new Map();
    @observable isInited: boolean = false;
    @observable filter: TFilter;
    @observable totalCount: number;

    @computed get empty() { return this.totalCount == 0; }

    /**
     * @param defaultValue 
     * @param defaultTotalCount need to preview items placeholder while first fetching
     * @param defaultFilter
     */
    constructor(defaultValue: T[] = [],
        private defaultTotalCount: number = 25,
        defaultFilter: Partial<TFilter> = {}) {
        super();
        this.resetItems(defaultValue, defaultTotalCount);
        this.filter = this.createFilter(defaultFilter);
    }


    viewport: InfinityListViewport | undefined;
    setViewport(v: InfinityListViewport) {
        this.viewport = v;
    }

    @action
    setTotalCount(count: number) {
        this.totalCount = count;
    }

    isRowLoaded(index: number) {
        return !!this.items[index];
    }

    /**
     * -1 means no rows loaded
     */
    get lastLoadedIndex() {
        return this.items.length - 1;
    }

    get isAllRowsLoaded() {
        return this.items.length >= this.totalCount;
    }

    /**
     * null if item hasn't been loaded yet
     */
    getItem(index: number) {
        if (!this.isRowLoaded(index)) return null;
        return this.items[index];
    }

    getItemById(id: string) {
        return this.itemsMap.get(id) ?? null;
    }

    abstract fetchItems(filter: TFilter, req: InfinityListStoreFetchRequest): Promise<InfinityListStoreFetchResult<T, TFilter>>;
    abstract createFilter(changes: Partial<TFilter | BaseFilter<T>>): TFilter;

    async fetch(req: InfinityListStoreFetchRequest) {
        const allowFetch = this.beforeFetch(this.filter, req);
        if (!allowFetch) return;
        runInAction(() => {
            this.isFetching = true;
        });
        const filter = this.createFilter({
            ...this.filter,
            skip: req.startIndex,
            take: req.stopIndex - req.startIndex + 1
        });
        const result = await this.fetchItems(filter, req);
        if (result.success) {
            let index = req.startIndex;
            for (const item of result.items) {
                if (!this.items[index])
                    this.items[index] = item;
                index++;
                this.itemsMap.set(item.id, item);
            }
            this.setFilter(result.filter);
        }
        runInAction(() => {
            this.hasError = !result.success;
            this.isFetching = false;
        });
        if (result.success && !this.isInited)
            runInAction(() => {
                this.isInited = true;
            });
        this.afterFetch(result);
    }

    @action
    updateFilter(changes: Partial<TFilter | BaseFilter<T>> = {}, resetPaging: boolean = true) {
        if (resetPaging)
            this.filter.page = 1;
        this.filter = this.createFilter(changes);
    }

    @action
    setFilter(filter: TFilter, reset = false) {
        this.filter = filter;
        this.totalCount = this.filter.itemsTotal;
        if (reset) this.forceReset();
    }

    @action forceReset(items: T[] = [],
        totalCount: number = this.defaultTotalCount,
        isInited = false) {
        this.resetItems(items, totalCount);
        this.isInited = isInited;
        this.hasError = false;
    }

    @action
    resetItems(items: T[],
        totalCount: number = this.totalCount) {
        this.itemsMap.clear();
        this.items = items;
        if (this.items.length > 0)
            this.items.forEach(x => this.itemsMap.set(x.id, x));
        this.totalCount = totalCount;
    }

    @action
    insertItem(item: T, index: number) {
        const preventInsert = index < 0 || (index > this.lastLoadedIndex && !this.isAllRowsLoaded);
        this.totalCount += 1;
        if (preventInsert)
            return false;
        this.items.splice(index, 0, item);
        this.itemsMap.set(item.id, item);
        return true;
    }

    @action
    updateItem(item: T, index: number) {
        if (!this.isRowLoaded(index)) return;
        this.items[index] = item;
        this.itemsMap.set(item.id, item);
    }

    @action
    swapItems(index: number, newIndex: number) {
        if (!this.isRowLoaded(index)) return;
        const item = this.items[index];
        this.items[index] = this.items[newIndex];
        this.items[newIndex] = item;
    }

    @action
    deleteItem(index: number) {
        const preventDelete = !this.isRowLoaded(index);
        this.totalCount -= 1;
        if (preventDelete) return false;
        const item = this.items[index];
        this.items.splice(index, 1);
        this.itemsMap.delete(item.id);
        return true;
    }

    beforeFetch(filter: TFilter, req: InfinityListStoreFetchRequest): boolean { return true; }
    afterFetch(result: InfinityListStoreFetchResult<T, TFilter>) { return; }
}

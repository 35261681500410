import { observable, action } from 'mobx';
import moment, { type Moment } from 'moment';
import { Currency } from '../../../enums';
import Entity from '../../Entity';
import Admin from '../Admin';

export default class AdminPartnerTransaction extends Entity {
    constructor(transaction?: Partial<AdminPartnerTransaction>) {
        super(transaction);
        if (transaction) this.update(transaction);
    }

    @observable mainServiceAmount: number;
    @observable additionalServiceAmount: number;
    @observable totalAmount: number;
    @observable exchangeCurrency: Currency;
    @observable exchangeRate: number;
    @observable createdDate: Moment;
    @observable admin: Admin;
    @observable fromAdmin: Admin | null;
    @observable comment: string;

    clone() {
        return new AdminPartnerTransaction({
            ...this,
            admin: this.admin?.clone(),
            fromAdmin: this.fromAdmin ? this.fromAdmin.clone() : null
        });
    }

    @action update(user: Partial<AdminPartnerTransaction>, allowUndefined = false) {
        super.update(user, allowUndefined);
    }

    static fromJson(json: any) {
        return new AdminPartnerTransaction({
            id: json.id,
            mainServiceAmount: json.mainServiceAmount,
            additionalServiceAmount: json.additionalServiceAmount,
            totalAmount: json.totalAmount,
            exchangeCurrency: json.exchangeCurrency,
            exchangeRate: json.exchangeRate,
            comment: json.comment,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            fromAdmin: json.fromAdmin ? Admin.fromJson(json.fromAdmin) : undefined,
        });
    }
}
import { Entity } from "..";
import { action } from "mobx";
import moment from "moment";

export default class Timezone extends Entity {
    constructor(timezone?: Partial<Timezone>) {
        super(timezone);
        if (timezone) this.update(timezone);
    }

    name: string;
    offset: moment.Duration;

    get offsetAsText() {
        if (!this.offset) return ''; // just in case
        const negative = this.offset.asMilliseconds() < 0;
        const hours = Math.floor(Math.abs(this.offset.asHours()));
        const mins = Math.abs(this.offset.asMinutes()) - hours * 60;
        const format = hours.toString().padStart(2, '0') + ":" + mins.toString().padStart(2, '0');
        return negative ? `-${format}` : `+${format}`;
    }

    @action update(timezone: Partial<Timezone>) {
        super.update(timezone);
    }

    clone() {
        return new Timezone({
            id: this.id,
            offset: this.offset,
            name: this.name
        })
    }
    
    static fromJson(json: any): Timezone {
        return new Timezone({
            id: json.id,
            offset: moment.duration(json.offset ?? '00:00'),
            name: json.name
        });
    }

    static utc() {
        return new Timezone({
            id: 'UTC',
            offset: moment.duration('00:00')
        });
    }
}

import { observable, action } from 'mobx';
import { Admin, Contact, Entity } from '../..';

export default class CallUser extends Entity {
    constructor(callUser?: Partial<CallUser>) {
        super(callUser);
        if (callUser) this.update(callUser);
    }


    @observable number: string;
    @observable admin: Admin;
    @observable contact: Contact;

    static fromJson(json: any): CallUser {
        return new CallUser({
            ...json,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined
        });
    }

    @action
    update(callUser: Partial<CallUser>) {
        super.update(callUser);
    }
}


import { observable } from 'mobx';
import { ChatChannelType, Entity } from '..';


export class ChatChannelIntegration extends Entity {
    @observable name: string;
    @observable type: ChatChannelType;
    @observable canInitiateMessaging: boolean;

    clone() {
        return new ChatChannelIntegration({
            ...this
        });
    }

    update(integration: Partial<ChatChannelIntegration>) {
        super.update(integration);
    }

    static fromJson(json: any) {
        return new ChatChannelIntegration({
            ...json
        });
    }
}

import CourseImage from './image/course-image';
import { observable, computed, action } from 'mobx';
import { Entity, CoursePlan, Category, Scenario, Cert } from '..';

export default class Course extends Entity {
    constructor(course?: Partial<Course>) {
        super(course);
        if (course) this.update(course);
    }

    @observable name: string;
    @observable shortDescription: string;
    @observable categories: Category[];
    @observable coursesIds: Course[];
    @observable images: CourseImage[];
    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable isPublished: boolean;
    @observable primaryImageId: string | undefined;
    @observable showOnSite: boolean;
    @observable showInSchool: boolean;
    @observable showOnlyForWhoHaveAccess: boolean;
    @observable freeAccess: boolean;
    @observable dontAskFeedback: boolean;
    @observable gamificationEnabled: boolean;
    @observable isCompleteAnyCourseRequirement: boolean;
    @observable primaryImage: CourseImage;
    @observable studentCount: number;
    @observable plans: CoursePlan[];

    @observable scenario?: Scenario;
    @observable cert: Cert | null;

    /**
     * sections excluded
     */
    totalItemCount: number;

    @computed get currentImage() {
        if (this.primaryImage) return this.primaryImage;
        if (!this.primaryImageId || this.images.length == 0)
            return undefined;
        return this.images.find(x => x.id == this.primaryImageId);
    }

    static fromJson(json: any): Course {
        const course = new Course({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            images: json.images ? json.images.map(CourseImage.fromJson) : undefined,
            primaryImage: json.primaryImage ? CourseImage.fromJson(json.primaryImage) : undefined,
            categories: json.categoriesLinks ? json.categoriesLinks.map((x: any) => Category.fromJson(x.category)) : undefined,
            coursesIds: json.courseRequirements ? json.courseRequirements.map((x: any) => Course.fromJson(x.requiredCourse)) : undefined,
            plans: json.plans ? json.plans.map((x: any) => new CoursePlan(x)) : undefined,
            scenario: json.scenarios && json.scenarios.length > 0 ? Scenario.fromJson(json.scenarios[0]) : undefined,
            cert: json.cert ? Cert.fromJson(json.cert) : undefined
        });
        if (course.scenario) course.scenario.update({ course });
        return course;
    }

    @action
    update(changes: Partial<Course>) {
        super.update(changes);
    }
}


import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AccelFile } from '../../../../models';
import { combineClasses, Localization } from '../../../../utils';
import { useClickOutside } from '../../../hooks/useClickOutside';
import styles from './FileName.module.scss';

type Props = {
    file: AccelFile;
    useExtension: boolean;
    editable: boolean;
    loc: Localization;
    onRename: (name: string) => Promise<boolean>;
    className?: string;
}
const FileName: React.FC<Props> = ({ file, editable, useExtension, loc, className, onRename }) => {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useClickOutside(inputRef, editing, () => {
        save();
    });

    const save = useCallback(async () => {
        const val = inputRef.current?.value ?? '';
        if (val !== file.name) {
            setSaving(true);
            await onRename(val);
            setSaving(false);
        }
        setEditing(false);
    }, [file]);

    useEffect(() => {
        if (editing) inputRef.current?.focus();
    }, [editing])

    return <>
        {!editing
            ? <div className={combineClasses('ellipsis', editable ? 'c-pointer hoverable' : undefined, className)}
                onClick={() => editable && setEditing(true)}
                title={editable ? loc.word('File.clickToRename', { default: 'Click to rename' }) : file.filename}>
                {useExtension == true ? file.filename : file.name}
            </div>
            : <input ref={inputRef} defaultValue={file.name} type="text" disabled={saving} className={combineClasses(styles.fl_filename_input, 'w-100 p-0 m-0 no-border')} onKeyUp={e => {
                e.preventDefault();
                e.stopPropagation();
                if (e.key == 'Enter')
                    save();
            }}></input>}
    </>
}

export default FileName;
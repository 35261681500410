import * as React from 'react';
import { Localization, KeyGenerator, FileProvider, GeoLocator } from '../../utils';
import Api from "../../api";
import { DeviceStore } from '../../stores';
import { Lang } from '../../enums';
import { User } from '../../models';

export interface AccelContext {
    loc: Localization;
    keygen: KeyGenerator;
    api: Api;
    fileProvider: FileProvider;
    lang: Lang;
    user: User | null;
    deviceStore: DeviceStore;
    geoLocator: GeoLocator;
    /**
     * TEMPORARY - document title custom prefix
     */
    domainDocPrefix?: string;
}
//@ts-ignore
export const Context: React.Context<AccelContext> = React.createContext<AccelContext>();

export default class AccelProvider extends React.Component<AccelContext> {
    render() {
        const config: AccelContext = {
            loc: this.props.loc,
            keygen: this.props.keygen,
            api: this.props.api,
            fileProvider: this.props.fileProvider,
            lang: this.props.lang,
            user: this.props.user,
            deviceStore: this.props.deviceStore,
            geoLocator: this.props.geoLocator,
            domainDocPrefix: this.props.domainDocPrefix,
        };
        return <Context.Provider value={config}>
            {this.props.children}
        </Context.Provider>;
    }
}


export type Optional<T> = {
    value: T | null;
} | undefined;

export function toOptional<T>(value: any): Optional<T> {
    // important strict check
    if (value === undefined) return undefined;
    return { value };
}

export function toOptionalObject<T>(value: any): { [k: string]: Optional<any> } {
    if (typeof value !== 'object') return {};
    const obj = {};
    for (const k in value)
        obj[k] = toOptional(value[k]);
    return obj;
}

export function fromOptional<T>(value: Optional<T>, parse?: (v: any) => T | null): T | null | undefined {
    if (!value || typeof value !== 'object') return undefined;
    if (parse && value.value) return parse(value.value);
    return value.value;
}

export function cleanOptionalObject(obj: any): any {
    let cleanObject = {};
    // clean object from Optional values
    Object.keys(obj).forEach(k => cleanObject[k] = fromOptional(obj[k]));
    return cleanObject;
}
import React, { useEffect } from 'react';

export function useClickOutside(ref: React.RefObject<any>, enable: boolean, onClick: (e: MouseEvent) => void) {
    useEffect(() => {
        if (!enable || !ref.current) return;
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClick(event);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, enable]);
}
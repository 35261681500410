import { action, observable } from "mobx";
import { Updatable } from "../..";

export default class WebinarIntegrationSettings extends Updatable {
    constructor(settings?: Partial<WebinarIntegrationSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable headerScript: string;
    @observable bodyScript: string;

    @action update(settings: Partial<WebinarIntegrationSettings>) {
        super.update(settings);
    }

    hasChanges(settings: WebinarIntegrationSettings) {
        return this.headerScript != settings.headerScript
            || this.bodyScript != settings.bodyScript;
    }

    copy() {
        return new WebinarIntegrationSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return new WebinarIntegrationSettings({
            ...json
        });
    }
}
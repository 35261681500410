import { Updatable } from '../../../..';

export default class ZadarmaSettings extends Updatable {
    public secret: string;

    constructor(settings?: Partial<ZadarmaSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<ZadarmaSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { useState } from 'react';
import { ISerializable } from '../../models/Entity';

export default function useLocalStorage<T = any>(key: string,
    initialValue?: T,
    removeIfUndefined?: boolean,
    parse?: (json: any) => T): [T, (x: T) => void] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item
                ? parse
                    ? parse(JSON.parse(item))
                    : JSON.parse(item)
                : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);

            if (removeIfUndefined === true && valueToStore == undefined) {
                window.localStorage.removeItem(key);
            } else {
                // Save to local storage
                window.localStorage.setItem(key,
                    // check if it has toJson method
                    JSON.stringify((valueToStore as ISerializable).toJson ? (valueToStore as ISerializable).toJson() : valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

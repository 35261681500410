import { ApiRequest, ApiResponse, ApiResult, BadApiResult, HttpMethod } from '..';
import { HttpStatusCode } from '../../enums';
import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import ApiClient from './ApiClient';
import { ApiFileResponse } from '../core/response';

export default abstract class HttpApiClient extends ApiClient {
    protected axios: AxiosInstance;

    constructor(private apiUrl: string) {
        super();
        this.axios = axios.create();
    }

    protected buildHeaders(request: ApiRequest) {
        return {
            'Content-Type': 'application/json'
        };
    }

    protected buildUrl(request: ApiRequest) {
        return `${this.apiUrl}/v${request.v}${request.url}`;
    }

    protected async processRequest<TRequest extends ApiRequest, TResponse extends ApiResponse>
        (request: TRequest, ResponseType?: { new(json: any): TResponse; }): Promise<ApiResult<TResponse>> {
        try {
            const url = this.buildUrl(request);
            let headers = this.buildHeaders(request);
            if (request.headers)
                headers = { ...headers, ...request.headers };
            let responseJson: any;
            switch (request.method) {
                case HttpMethod.GET: {
                    let body = { ...request.body, fields: request.resultFields };
                    responseJson = await this.axios.get(url, {
                        params: body,
                        headers,
                        paramsSerializer: obj => {
                            // exclude undefined (not null!) fields
                            const json = JSON.parse(JSON.stringify(obj));
                            return qs.stringify(json);
                        },
                        signal: request.cancellationToken.signal,
                        responseType: request.responseType
                    });
                    break;
                }
                case HttpMethod.POST: {
                    responseJson = await this.axios.post(url, request.body, { headers, signal: request.cancellationToken.signal, responseType: request.responseType });
                    break;
                }
                case HttpMethod.PUT: {
                    responseJson = await this.axios.put(url, request.body, { headers, signal: request.cancellationToken.signal, responseType: request.responseType });
                    break;
                }
                case HttpMethod.DELETE: {
                    let body = { ...request.body, fields: request.resultFields };
                    responseJson = await this.axios.delete(url, { params: body, headers, signal: request.cancellationToken.signal, responseType: request.responseType });
                    break;
                }
            }
            return new ApiResult({
                request,
                response: await this.parseResponseData<TRequest, TResponse>(request, responseJson, ResponseType),
                status: HttpStatusCode.OK
            });
        } catch (e: any) {
            if (e.response == null || e.response.data == null) {
                console.error('Network error', e);
                return new BadApiResult();
            }
            const response = e.response.data.body != null
                ? ResponseType ? new ResponseType(e.response.data) : new ApiResponse(e.response.data) as TResponse
                : new ApiResponse(e.response.data);
            return new BadApiResult(e.response.status, response);
        }
    }

    protected async parseResponseData<TRequest extends ApiRequest, TResponse extends ApiResponse>(request: ApiRequest, response: any, ResponseType?: { new(json: any): TResponse; }): Promise<TResponse> {
        const { data, headers } = response;
        switch (request.responseType) {
            case 'blob':
                const contentType = data.type;

                if (contentType === 'application/json') {
                    const text = await data.text();
                    const jsonData = JSON.parse(text);
                    return ResponseType ? new ResponseType(jsonData) : new ApiResponse(jsonData) as TResponse;
                } else {
                    const fileName = headers['X-Filename'] ?? '';
                    return new ApiFileResponse(data, fileName) as any as TResponse;
                }

            default:
            case 'json':
                return data.body != null
                    ? ResponseType ? new ResponseType(data) : new ApiResponse(data) as TResponse
                    : new ApiResponse(data) as TResponse;
        }
    }
}

import { BaseFilter, Entity } from '../models';
import { observable, runInAction, computed, action } from 'mobx';
import { ApiListResponse, ApiResult, BadApiResult } from '../api';
import _ from 'lodash';
import { Disposable } from '../utils';
import { ISerializable } from '../models/Entity';
import CRUDApi from '../api/CRUDApi';

export abstract class IListStore<T, TFilter = BaseFilter<T>> {
    fetching: boolean;
    items: T[];
    filter: TFilter;

    abstract fetch(fields?: string): Promise<any>;
}

export declare type ListStoreMode = 'paging' | 'infinity';
export abstract class ListStore<T extends Entity, TFilter extends BaseFilter<T> = BaseFilter<T>> extends Disposable implements IListStore<T, TFilter> {
    @observable inited: boolean = false;
    @observable hasError: boolean = false;
    @observable fetching: boolean = false;
    @observable items: T[] = [];
    @observable filter: TFilter;
    @observable mode: ListStoreMode = 'paging';
    @observable replaceFilter: boolean = true;

    @computed get empty() { return this.inited && this.items.length == 0; }
    @computed get emptyByFilter() { return this.inited && this.filter.itemsTotal == 0; }
    @computed get hasMore() {
        if (this.filter.itemsTotal === undefined) return true;
        switch (this.mode) {
            case 'paging':      
                return this.filter.page * this.filter.take < this.filter.itemsTotal;
            case 'infinity':
                return this.filter.itemsTotal > this.items.length;
        }
    }
    @computed get moreCount() {
        switch (this.mode) {
            case 'paging':
                return Math.max(this.filter.itemsTotal - this.filter.page * this.filter.take, 0);
            case 'infinity':
                return this.filter.itemsTotal - this.items.length;
        }
    }

    constructor(mode: ListStoreMode = 'paging', replaceFilter = true) {
        super();
        this.filter = this.createFilter({});
        this.mode = mode ?? 'paging';
        this.replaceFilter = replaceFilter ?? true;
    }

    @action
    setItems(items: T[]) {
        this.items = items;
    }

    abstract fetchItems(fields?: string, filter?: TFilter): Promise<ApiResult<ApiListResponse<T, TFilter>>>;

    async fetch(fields?: string) {
        const allowFetch = this.beforeFetch(this.filter, fields);
        // TODO remove
        if (!allowFetch) return new BadApiResult() as ApiResult<ApiListResponse<T, TFilter>>;
        runInAction(() => {
            this.fetching = true;
        });
        const result = await this.fetchItems(fields);
        if (result.success) {
            // compatibility with old api
            const items = result.response.items ?? result.response.body?.items ?? [];
            const filter = result.response.filter ?? result.response.body?.filter;
            this.processResponse(items, filter);
        }
        runInAction(() => {
            this.inited = true;
            this.hasError = !result.success;
            this.fetching = false;
        });
        this.afterFetch(result);
        return result;
    }

    /**
     * fetch with no pagination
     * @param fields 
     * @returns 
     */
    async fetchAll(fields?: string, includeDeleted = false) {
        this.updateFilter({ useBaseFilter: false, softDeleted: includeDeleted ? null : this.filter.softDeleted });
        return await this.fetch(fields);
    }

    @action private processResponse(items: T[], filter: TFilter) {
        switch (this.mode) {
            case 'paging': {
                this.items = items;
                break;
            }
            case 'infinity': {
                console.logDev('Infinity fetching', filter.skip, filter.skip + filter.take, items.length);
                // copy array to avoid mobx errors
                const arr = this.items.slice();
                let index = filter.skip;
                for (const item of items) {
                    if (!arr[index])
                        arr[index] = item;
                    index++;
                }
                this.items = arr;
                break;
            }
        }
        if (!filter) {
            console.warn('[ListStore] no filter in response')
            return;
        }
        if (this.replaceFilter && filter instanceof BaseFilter)
            this.filter = filter;
        else this.filter = this.createFilter({ ...this.filter, itemsTotal: filter.itemsTotal });
    }

    @action
    updateFilter(changes: Partial<TFilter | BaseFilter<T>>, resetPaging: boolean = true) {
        if (resetPaging) this.filter.page = 1;
        this.filter = this.createFilter(changes);
    }

    @action
    setFilter(filter: TFilter) {
        this.filter = filter;
    }

    createFilter(changes: Partial<TFilter | BaseFilter<T>>): TFilter {
        return new BaseFilter({ ...this.filter, ...changes }) as TFilter;
    }

    beforeFetch(filter: TFilter, fields?: string): boolean { return true; }
    afterFetch(result: ApiResult<ApiListResponse<T, TFilter>>) { return; }

    @action reset() {
        this.items = [];
        this.hasError = false;
        this.filter = this.createFilter({});
        this.inited = false;
    }
}



export abstract class SelectableListStore<T extends Entity, TFilter extends BaseFilter<T>> extends ListStore<T, TFilter> {
    @observable selectedAll = false;
    @observable selectedIdsSet = new Set<string>();
    @computed get selectedIds() { return Array.from(this.selectedIdsSet); }
    @computed get hasSelection() { return this.selectedAll || this.selectedIdsSet.size > 0; }
    @computed get selectedCount() { return this.selectedAll ? this.filter.itemsTotal : this.selectedIdsSet.size; }
    @computed get selectionFilter() {
        return this.selectedAll
            ? this.createFilter({ ...this.filter, ids: undefined })
            : this.createFilter({ ids: this.selectedIds });
    }
    @computed get selectedItems() {
        return this.items.filter(x => this.selectedIdsSet.has(x.id));
    }

    @action
    updateFilter(changes: Partial<TFilter>, resetPaging: boolean = true) {
        super.updateFilter(changes, resetPaging);
        if (this.selectedAll && this.shouldResetSelection(changes)) {
            this.selectedAll = false;
        }
    }

    protected filterSkipFields = ['asNoTracking', 'itemsTotal', 'skip', 'take', 'sortName', 'sortType', 'useBaseFilter', 'useSort'];
    shouldResetSelection(changes: Partial<TFilter>): boolean {
        const keys = Object.keys(changes)
            .filter(x => changes[x] !== undefined)
            .filter(x => !this.filterSkipFields.includes(x));
        return keys.length > 0;
    }

    @action
    toggleItem(id: string) {
        const isSelected = this.isSelected(id);
        if (isSelected) {
            this.deselectItems([id]);
            return;
        }
        this.selectItems([id]);
    }

    @action
    setSelectedItems(ids: Array<string>) {
        this.deselectAll();
        ids.forEach(id => this.selectedIdsSet.add(id));
    }

    @action
    selectItems(ids: Array<string>, deselectPrev = false) {
        this.selectedAll = false;
        if (deselectPrev)
            this.deselectAll();
        ids.forEach(id => this.selectedIdsSet.add(id));
    }

    @action
    deselectItems(ids: Array<string>) {
        this.selectedAll = false;
        ids.forEach(id => this.selectedIdsSet.delete(id));
    }

    isSelected(id: string) {
        return this.selectedIdsSet.has(id);
    }

    @action
    selectAll() {
        this.deselectAll();
        this.selectedAll = true;
    }

    @action
    deselectAll() {
        this.selectedAll = false;
        this.selectedIdsSet.clear();
    }
}



export abstract class CRUDListStore<T extends Entity & ISerializable, TFilter extends BaseFilter<T>, TApi extends CRUDApi<T>> extends ListStore<T, TFilter> {

    abstract get crudApi(): TApi;

    createFilter(changes: Partial<TFilter | BaseFilter<T>>): TFilter {
        return new BaseFilter({ ...this.filter, ...changes }) as TFilter;
    }

    @observable creating = false;
    @action async create<TBody = string>(e: T) {
        this.creating = true;
        const result = await this.crudApi.create<TBody>(e);
        if (result.success) {
            if (typeof result.response.body === 'string')
                e.update({ id: result.response.body });
            // in case create returns not only id
            else e.update(result.response.body as any);
        }
        runInAction(() => {
            this.creating = false;
        });
        return result;
    }

    @observable saving = false;;
    @action async save(target: T) {
        this.saving = true;
        const result = await this.crudApi.save(target.toJson());
        runInAction(() => {
            this.saving = false;
        });
        return result;
    }

    @action async savePartial(changes: Partial<T>, target: T) {
        this.saving = true;
        const result = await this.crudApi.save({ id: target.id, ...changes });
        if (result.success)
            target.update(changes);
        runInAction(() => {
            this.saving = false;
        });
        return result;
    }

    @action async saveCustom(changes: any) {
        this.saving = true;
        const result = await this.crudApi.save(changes);
        runInAction(() => {
            this.saving = false;
        });
        return result;
    }

    @observable deleting = false;
    @action async delete(id: string, fetchAfter = true) {
        this.deleting = true;
        const result = await this.crudApi.delete(id);
        if (result.success && fetchAfter)
            this.fetch();
        runInAction(() => {
            this.deleting = false;
        });
        return result;
    }

    @action async fetchItem(id: string, fields?: string) {
        this.fetching = true;
        const result = await this.crudApi.fetchItem(id, fields);
        runInAction(() => {
            this.fetching = false;
        });
        return result;
    }

    fetchItems(fields?: string | undefined, filter?: TFilter | undefined): Promise<ApiResult<ApiListResponse<T, TFilter>>> {
        return this.crudApi.fetch(fields, filter ?? this.filter) as any;
    }
}

import { TokenStore } from '../../stores';
import { inject } from 'react-ioc';
import * as signalR from "@microsoft/signalr";
import { observable, action } from 'mobx';
import { HubConnectionState } from '@microsoft/signalr';
import { TypedEvent, reaction, Disposable } from '../../utils';
import { HubCaller, HubRequest, HubRequestWithResponse, HubSubscription, HubUnsubscription } from '..';
import HubResponse, { BadHubResponse } from './request/HubResponse';
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';

export type HubClientSettings = {
    host: string;
    invokeTimeoutMs: number;
    autoConnect?: boolean;
}

declare type ConnectionError = FailedToNegotiateWithServerError;
export default class BaseHubClient extends Disposable {
    protected inited = false;
    protected connection: signalR.HubConnection;
    protected connectionError: ConnectionError | null = null;
    @observable state: HubConnectionState = HubConnectionState.Disconnected;
    @observable connectionId: string | null = null;

    public connected = new TypedEvent<void>();
    public disconnected = new TypedEvent<void>();
    public reconnecting = new TypedEvent<void>();
    public connecting = new TypedEvent<void>();
    public unauthorized = new TypedEvent<void>();

    constructor(private tokenStore: TokenStore, private settings: HubClientSettings) {
        super();
        this.tokenStore = tokenStore || inject(this, TokenStore);
        this.toDispose(reaction(() => this.state, state => {
            switch (state) {
                case HubConnectionState.Connected:
                    this.connected.emit();
                    break;
                case HubConnectionState.Disconnected:
                    this.disconnected.emit();
                    break;
                case HubConnectionState.Reconnecting:
                    this.reconnecting.emit();
                    if (this.connectionError?.message?.includes('401'))
                        this.unauthorized.emit();
                    break;
                case HubConnectionState.Connecting:
                    this.connecting.emit();
                    break;
            }
            this.connectionId = this.connection.connectionId;
        }));

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(this.settings.host, {
                accessTokenFactory: () => this.tokenStore.accessToken!,
                transport: signalR.HttpTransportType.WebSockets
            })
            .withAutomaticReconnect([2000, 3000, 5000, 10000, 60000])
            .build();
        this.toDispose(() => this.connection.stop());
        this.init();
    }

    private init() {
        if (this.inited)
            throw new Error('[HubClient] Already inited');

        this.connection.onclose(e => {
            this.setState(HubConnectionState.Disconnected);
        });
        this.connection.onreconnecting(e => {
            this.setState(HubConnectionState.Reconnecting);
        });
        this.connection.onreconnected(e => {
            this.setState(HubConnectionState.Connected);
        });
        this.toDispose(reaction(() => this.tokenStore.accessToken, async (newValue, r, oldValue) => {
            if (newValue != null) {
                if (newValue != oldValue) {
                    await this.disconnect();
                }
                await this.connect();
            } else {
                await this.disconnect();
            }
        }, {
            fireImmediately: this.settings.autoConnect !== false
        }));
        this.inited = true;
    }

    public async connect() {
        if (this.state == HubConnectionState.Connected)
            return;
        try {
            this.setState(HubConnectionState.Connecting);
            await this.connection.start();
            this.setState(HubConnectionState.Connected);
        } catch (e: any) {
            this.setState(HubConnectionState.Reconnecting, e);
            setTimeout(() => this.connect(), 5000);
        }
    }

    public async disconnect() {
        if (this.state == HubConnectionState.Disconnected)
            return;
        try {
            this.setState(HubConnectionState.Disconnecting);
            await this.connection.stop();
            this.setState(HubConnectionState.Disconnected);
        } catch (e) {
            this.setState(HubConnectionState.Disconnected);
        }
    }

    public on<T>(subscription: HubSubscription<T>, callback: (payload: T, caller: HubCaller | null) => void): HubUnsubscription {
        subscription.subscribe(this.connection, callback);
        console.logDev(`[HubClient] Subscribed '${subscription.method}'`, subscription);
        return subscription.unsubscription;
    }

    public async invoke(request: HubRequest): Promise<HubResponse<any>> {
        let result = await this.safeInvoke(request);
        if (!result.success && this.connection.state != HubConnectionState.Connected) {
            const waitResult = await this.waitForConnection();
            if (!waitResult)
                return result;
            return await this.safeInvoke(request);
        }
        return result;
    }

    private async safeInvoke(request: HubRequest): Promise<HubResponse<any>> {
        try {
            const res = await request.invoke(this.connection);
            console.logDev(`[HubClient] HubResponse`, request.url, request.body, res);
            return res;
        } catch (e) {
            console.logDev(`[HubClient] BadHubResponse`, request, e);
            return new BadHubResponse();
        }
    }

    public async invokeWithResult<T>(request: HubRequestWithResponse<T>): Promise<HubResponse<T>> {
        return await this.invoke(request);
    }

    @action
    private setState(value: HubConnectionState, error: ConnectionError | null = null) {
        this.connectionError = error;
        this.state = value;
    }

    /**
     * wait for reconnection or timeout
     * @param durationMs
     */
    private waitForConnection(): Promise<boolean> {
        return new Promise(resolve => {
            const timeout = setTimeout(() => {
                disposer();
                resolve(false);
            }, this.settings.invokeTimeoutMs);
            const disposer = reaction(() => this.state, state => {
                if (state == HubConnectionState.Connected) {
                    clearTimeout(timeout);
                    disposer();
                    resolve(true);
                }
            });
        });
    }
}

import { observable, action } from 'mobx';
import { CourseItemTaskField, Entity } from '../../../../..';

export default class StudentCourseItemTaskAnswerField extends Entity {
    constructor(answerField?: Partial<StudentCourseItemTaskAnswerField>) {
        super(answerField);
        if (answerField) this.update(answerField);
    }

    @observable stringValue?: string;
    @observable booleanValue?: boolean;

    @observable listValue?: string;
    @observable listLabelValue?: string;

    @observable multiListValue?: string[];
    @observable multiListLabelValue?: string[];

    @observable field: CourseItemTaskField;

    get value(): any {
        if (this.booleanValue !== undefined) return this.booleanValue;
        if (this.stringValue !== undefined) return this.stringValue;
        if (this.listValue !== undefined) return this.listValue;
        if (this.multiListValue !== undefined) return this.multiListValue;
        return undefined;
    }

    @action update(answerField: Partial<StudentCourseItemTaskAnswerField>, allowUndefined = false) {
        super.update(answerField, allowUndefined);
    }

    isEquals(field: StudentCourseItemTaskAnswerField) {
        if (this.field.id != field.field.id) return false;
        if (this.booleanValue !== undefined) return this.booleanValue === field.booleanValue;
        if (this.listValue !== undefined) return this.listValue === field.listValue;
        if (this.multiListValue !== undefined) return this.multiListValue === field.multiListValue;
        return this.stringValue === field.stringValue;
    }

    copy(): StudentCourseItemTaskAnswerField {
        return new StudentCourseItemTaskAnswerField({
            id: this.id,
            field: this.field,
            stringValue: this.stringValue,
            booleanValue: this.booleanValue,
            listValue: this.listValue,
            listLabelValue: this.listLabelValue,
            multiListValue: this.multiListValue?.slice(),
            multiListLabelValue: this.multiListLabelValue?.slice()
        });
    }

    get valueJson() {
        return typeof this.value === 'object' ? JSON.stringify(this.value) : this.value;
    }

    toJson() {
        return {
            id: this.field.id,
            value: this.valueJson,
        }
    }

    static fromJson(json: any) {
        return new StudentCourseItemTaskAnswerField({
            id: json.id,
            stringValue: json.stringValue ?? undefined,
            booleanValue: json.booleanValue ?? undefined,
            listValue: json.listValue ?? undefined,
            listLabelValue: json.listLabelValue ?? undefined,
            multiListValue: json.multiListValue ? JSON.parse(json.multiListValue) : undefined,
            multiListLabelValue: json.multiListLabelValue ? JSON.parse(json.multiListLabelValue) : undefined,
            field: json.field ? CourseItemTaskField.fromJson(json.field) : undefined
        });
    }
}
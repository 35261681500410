
import { observable, action } from 'mobx';
import { Admin, Entity } from '../..';


export enum CallSIPRegistrationStatus {
    None = 'none',
    InProgress = 'inProgress',
    Verified = 'verified',
    Diactivated = 'diactivated',
    Error ='error'
}

export default class CallSIPRegistration extends Entity {
    constructor(callSIPRegistration?: Partial<CallSIPRegistration>) {
        super(callSIPRegistration);
        if (callSIPRegistration) this.update(callSIPRegistration);
    }

    @observable name: string;
    @observable domain: string;
    @observable externalId: string;
    @observable login: string;
    @observable password: string;
    @observable lastErrorMessage: string;
    @observable providerCost: number;
    @observable status: CallSIPRegistrationStatus;
    @observable createdDate: Date;    
    @observable updatedDate: Date; 
    @observable purchaseDate: Date | null;
    @observable nextSubscriptionRenewalDate: Date | null;
    @observable admins: Admin[];

    static fromJson(json: any): CallSIPRegistration {
        return new CallSIPRegistration({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            purchaseDate: json.purchaseDate ? new Date(json.purchaseDate) : null,
            nextSubscriptionRenewalDate: json.nextSubscriptionRenewalDate ? new Date(json.nextSubscriptionRenewalDate) : null,
            admins: json.admins ? json.admins.map((x: any) => Admin.fromJson(x)) : [],
        });
    }

    @action
    update(callSIPRegistration: Partial<CallSIPRegistration>, allowUndefined = false) {
        super.update(callSIPRegistration, allowUndefined);
    }

    static default() {
        return new CallSIPRegistration({
            admins: []
        });
    }
}


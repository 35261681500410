import React, { useCallback, useContext } from "react";
import { Context } from "../AccelProvider/AccelProvider";
import { ApiResponse, ApiResult } from "../../api";
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { ErrorCode } from "../../enums";

export const useNotifier = function () {
    const { loc } = useContext(Context);

    const notify = useCallback(function <T>(result: ApiResult<ApiResponse<T>>, success?: React.ReactNode, successOpts?: Omit<ArgsProps, 'message'>,
        errorOpts?: ArgsProps) {
        if (result.success) {
            success && notification.open({ duration: 2, message: success, type: successOpts?.type ?? 'success', ...successOpts });
            return;
        }
        if (result.response.errors.length > 0) {
            result.response.errors.forEach(e => notification.error({ duration: 2, message: e.message ?? loc.word(`Error${ErrorCode[e.code]}`), ...errorOpts }));
            return;
        }
        notification.error({ duration: 2, message: loc.word('Error occured'), ...errorOpts });
    }, []);

    return [notify];
}

export default useNotifier;

import { Observer } from "mobx-react";
import { ApiResponse, ApiResult } from "../../api";
import useApiRequest from "../hooks/useApiRequest";
import React from "react";

type RequestProps<TData, TCb> = {
    action: TCb;
    children: ((obj: { exec: TCb, loading: boolean, data: TData | null, state: 'idle' | 'loading' | 'success' | 'error' }) => JSX.Element) | React.ReactNode;
    onSuccess?: (d: TData) => void;
    onError?: (result: ApiResult<ApiResponse<TData>>) => void;
    successMsg?: React.ReactNode;
    auto?: boolean;
    mock?: TData;
    // TODO
    // disableErrorNotify?: boolean;
}
const Request = function <TData>
    ({ action, successMsg, onSuccess, onError, children, auto, mock }: RequestProps<TData, () => Promise<ApiResult<ApiResponse<TData>>>>) {

    const [exec, { loading, data, state }] = useApiRequest(action, {
        auto,
        mock,
        onError,
        onSuccess,
        successMsg
    });

    return <Observer>
        {() => {
            if (typeof children !== 'function')
                return children;
            return children({
                exec,
                loading,
                data,
                state
            });
        }}
    </Observer>;
}
export default Request;
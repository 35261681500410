import { observable } from "mobx";
import { isEmpty } from "../../../utils";
import BaseChannelSettings from "./BaseChannelSettings";

export enum Wazzup24ChatType {
    WhatsApp = 'whatsapp',
    WAPI = 'wapi',
    Instagram = 'instagram',
    Telegram = 'telegram',
    TgAPI = 'tgapi',
    Vk = 'vk'
}

export default class LineChannelSettings extends BaseChannelSettings {
    @observable secret: string;
    @observable channelId: string;

    constructor(settings?: Partial<LineChannelSettings>) {
        super();
        if (settings) this.update(settings);
    }

    get isValid() {
        return !isEmpty(this.accessToken) && !isEmpty(this.secret) && !isEmpty(this.channelId);
    }

    update(entity: Partial<LineChannelSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    hasChanges(settings: LineChannelSettings) {
        return super.hasChanges(settings)
            || this.channelId !== settings.channelId
            || this.secret !== settings.secret;
    }

    clone() {
        return new LineChannelSettings(this);
    }
}
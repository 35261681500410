import { observable, action } from 'mobx';
import moment, { type Moment } from 'moment';
import { Admin, Contact, Entity, UserNotificationType } from '../..';
import { UserNotificationSubscriptionType } from './SubscriptionType';

export default class UserNotificationSubscription extends Entity {
    constructor(s?: Partial<UserNotificationSubscription>) {
        super(s);
        if (s) this.update(s);
    }

    @observable type: UserNotificationSubscriptionType;
    @observable notificationType: UserNotificationType;

    @observable createdDate: Moment;
    @observable updatedDate: Moment;

    @observable subscriberAdmin: Admin;
    @observable subscriberContact: Contact;

    @observable admin: Admin;
    @observable contact: Contact;

    static fromJson(json: any): UserNotificationSubscription {
        return new UserNotificationSubscription({
            id: json.id,
            type: json.type,
            notificationType: json.notificationType,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            subscriberAdmin: json.subscriberAdmin ? Admin.fromJson(json.subscriberAdmin) : undefined,
            subscriberContact: json.subscriberStudent ? Contact.fromJson(json.subscriberStudent) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
        });
    }

    @action
    update(n: Partial<UserNotificationSubscription>) {
        super.update(n);
    }
}


export function navigateTo(url: string, targetBlank: boolean = false) {
    const ua = window.navigator.userAgent;
    // without !ua.match(/CriOS/) check, chrome on ios will open in self tab
    const isMobileSafari = ua.match(/(iPod|iPhone|iPad)/) && ua.match(/AppleWebKit/) && !ua.match(/CriOS/);
    //check if safari on ios (open in self tab)
    if (isMobileSafari)
        window.location.href = url;
    else {
        const link = document.createElement('a');
        document.body.appendChild(link); // firefox fix
        link.href = url;
        if (targetBlank)
            link.target = '_blank';
        link.click();
        document.body.removeChild(link);
    }
}
import { observable } from 'mobx';
import { ChatBot, ChatChannelType, Entity, Scenario } from '..';
import FbChannelSettings from './Settings/FbChannelSettings';
import VkChannelSettings from './Settings/VkChannelSettings';
import Wazzup24ChannelSettings from './Settings/Wazzup24ChannelSettings';
import WhatsAppChannelSettings from './Settings/WhatsAppChannelSettings';
import InstagramChannelSettings from './Settings/InstagramChannelSettings';
import LineChannelSettings from './Settings/LineChannelSettings';
import BaseChannelSettings from './Settings/BaseChannelSettings';
import { ChatChannelSettingsFactory } from './ChatChannelSettingsFactory';

export default class ChatChannel extends Entity {
    constructor(item?: Partial<ChatChannel>) {
        super();
        if (item) this.update(item);
    }

    @observable name: string;
    @observable type: ChatChannelType;
    @observable disabled: boolean;
    @observable broadcastDisabled: boolean;
    @observable disabledReason?: string;
    @observable number: number;
    @observable color: string;
    @observable userCount: number;
    @observable subscribedUserCount: number;
    @observable defaultChatBot: ChatBot | null;
    @observable webhookInvalidated: boolean;
    @observable settings: BaseChannelSettings;
    @observable scenario: Scenario;

    externalId: string;

    get vkSettings() {
        return this.settings as VkChannelSettings;
    }

    get fbSettings() {
        return this.settings as FbChannelSettings;
    }

    get wazzup24Settings() {
        return this.settings as Wazzup24ChannelSettings;
    }

    get whatsAppSettings() {
        return this.settings as WhatsAppChannelSettings;
    }

    get instagramSettings() {
        return this.settings as InstagramChannelSettings;
    }

    get lineSettings() {
        return this.settings as LineChannelSettings;
    }

    get isValidSettings() {
        return this.settings.isValid;
    }

    get isWA() {
        return this.type === ChatChannelType.Wa;
    }

    get isWazzup() {
        return this.type === ChatChannelType.Wazzup24;
    }

    update(channel: Partial<ChatChannel>) {
        super.update(channel);
    }

    hasChanges(channel: ChatChannel): boolean {
        return this.name != channel.name
            || this.color != channel.color
            || this.type != channel.type
            || this.broadcastDisabled != channel.broadcastDisabled
            || this.settings.hasChanges(channel.settings)
            || this.defaultChatBot?.id !== channel.defaultChatBot?.id;
    }

    clone() {
        return new ChatChannel({
            ...this,
            settings: this.settings.clone(),
            defaultChatBot: this.defaultChatBot
        });
    }

    static fromJson(json: any): ChatChannel {
        const channel = new ChatChannel({
            ...json,
            settings: ChatChannelSettingsFactory.build(json.type, json.settingsJson),
            defaultChatBot: json.defaultChatBot ? ChatBot.fromJson(json.defaultChatBot) : null,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : null
        });
        if (channel.scenario) {
            channel.scenario.update({ chatChannel: channel });
        }
        return channel;
    }

    static default(c: Partial<ChatChannel> & Pick<ChatChannel, 'type'>) {
        return new ChatChannel({
            ...c,
            broadcastDisabled: c.type === ChatChannelType.Wa || c.type === ChatChannelType.Wazzup24,
            settings: ChatChannelSettingsFactory.build(c.type)
        });
    }
}
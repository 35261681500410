import { computed, observable } from 'mobx';
import { HelpItemType } from './HelpItemType';
import HelpItemContent from './HelpItemContent/HelpItemContent';
import { Entity } from '..';
import { Lang } from '../../enums';

export default class HelpItem extends Entity {
    constructor(helpItem?: Partial<HelpItem>) {
        super(helpItem);
        if (helpItem) this.update(helpItem);
    }
    @observable url: string;
    @observable type: HelpItemType;
    @observable position: number;
    @observable number: number;

    @observable parentItem: HelpItem | null;
    @observable children: HelpItem[];
    @observable contents: HelpItemContent[];

    getContent(lang: Lang) {
        return this.contents.find(x => x.language == lang) ?? null;
    }

    update(session: Partial<HelpItem>, allowUndefined = false) {
        super.update(session, allowUndefined);
    }

    @computed get empty(): boolean {
        if (this.type == HelpItemType.Article) return false;
        return this.children.length == 0 || this.children.every(x => x.empty);
    }

    @computed get emptyContent(): boolean {
        return this.contents.length == 0;
    }

    static fromJson(json: any): HelpItem {
        return new HelpItem({
            id: json.id,
            url: json.url,
            type: json.type,
            number: json.number,
            position: json.position,
            parentItem: json.parentItem ? HelpItem.fromJson(json.parentItem) : null,
            contents: json.contents ? json.contents.map(HelpItemContent.fromJson) : [],
            children: json.children ? json.children.map(HelpItem.fromJson) : [],
        });
    }
}
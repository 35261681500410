import React from 'react';
import styles from './VoiceRecordButton.module.scss';
import { AudioOutlined, BorderOutlined } from '@ant-design/icons';

type Props = {
    outside: boolean;
    locked: boolean;
    onStopRecording: () => void;
}
const VoiceRecordButton: React.FC<Props> = ({ outside, locked, onStopRecording }) => {

    return <div className={styles.voice_record_container} style={{ width: 50, height: 50 }} data-outside={outside} data-locked={locked}
        onClick={e => {
            if (!locked) return;
            e.preventDefault();
            e.stopPropagation();
            onStopRecording();
        }}>
        <div className={styles.voice_record_btn} >
            {locked ? <BorderOutlined className='chat-message-icon bg-white' /> : <AudioOutlined className='chat-message-icon' />}
        </div>
        <div>
            <div className={'voice_record_btn_wave voice_record_btn_wave_one'}></div>
            <div className={'voice_record_btn_wave voice_record_btn_wave_two'}></div>
        </div>
    </div>;
}
export default VoiceRecordButton;
import React from "react";
import { CheckOutlined } from '@ant-design/icons';
import { Typography, Form } from 'antd';
import { Localization, DisposableStack, combineClasses } from '../../../utils';
import CustomEditableFieldStore from './CustomEditableFieldStore';

import styles from './CustomEditableField.module.scss';
import { Context } from '../../AccelProvider/AccelProvider';
import { FormInstance, Rule } from 'antd/lib/form';
import { observer } from 'mobx-react';

export type ChangeSource = 'button' | 'enter' | 'custom';
export type CancelSource = 'button' | 'escape' | 'outside';
export type EditTrigger = 'click' | 'dblclick' | 'custom';
export type EditableFieldCommonProps<T> = {
    id?: string,
    defaultValue: T;
    editing?: boolean;
    defaultEditing?: boolean;
    enter?: boolean;
    escape?: boolean;
    clickOutside?: boolean;
    autoclose?: boolean;
    editTrigger?: EditTrigger;
    title?: string | React.ReactNode;
    saveable?: boolean;
    onChange?: (value: T, self: EditableField<T>, e: Event, source: ChangeSource) => void;
    beforeSave?: (value: T, self: EditableField<T>, e: Event, source: ChangeSource) => boolean;
    onSave?: (value: T, self: EditableField<T>, e: Event, source: ChangeSource) => Promise<boolean>;
    onCancel?: (self: EditableField<T>, e: Event, source: CancelSource) => void;
    preventSaveIfEquals?: boolean;
    showSavedIcon?: boolean;
    savedText?: string;
    style?: React.CSSProperties
    disabled?: boolean;
    width?: string | number;
    savedIconVisibleDurationMs?: number;

    rules?: Rule[];
    help?: string | React.ReactNode;
    name?: string;

    className?: string;
};
export type EditableFieldRenderProps<T> = {
    render: (self: EditableField<T>) => React.ReactNode;
    renderEdit: (self: EditableField<T>) => React.ReactNode;
}
type EditableFieldProps<T> = EditableFieldCommonProps<T> & EditableFieldRenderProps<T>;
@observer
export default class EditableField<T> extends React.Component<EditableFieldProps<T>> {
    static contextType = Context;
    context!: React.ContextType<typeof Context>;

    get loc(): Localization { return this.context.loc }
    static defaultProps: Partial<EditableFieldProps<any>> = {
        id: '',
        clickOutside: true,
        enter: true,
        escape: true,
        defaultEditing: false,
        autoclose: true,
        saveable: true,
        preventSaveIfEquals: true,
        showSavedIcon: true,
        style: {
            width: '100%'
        },
        savedIconVisibleDurationMs: 1000,
        width: '100%',
    }
    store: CustomEditableFieldStore<T>;
    disposableStack = new DisposableStack();
    // wrapperRef: HTMLDivElement;
    savedIconTimeout: any;

    formRef: React.RefObject<FormInstance> = React.createRef();
    wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

    public get value(): T { return this.store.value; }
    public set value(val: T) {
        this.store.setValue(val);
        if (this.formRef.current != null) {
            this.formRef.current.setFieldsValue(this.formValues);
        }
    }
    private get formValues() {
        const values: any = {};
        values['value'] = this.store.value;
        return values;
    }

    public get editing(): boolean { return this.store.editing; }
    public set editing(val: boolean) {
        this.store.setEditing(this.props.editing || val);
    }

    public get saving(): boolean { return this.store.saving; }
    public set saving(val: boolean) {
        this.store.setSaving(val);
    }

    public get savedIconVisible(): boolean { return this.store.savedIconVisible; }
    public set savedIconVisible(val: boolean) {
        this.store.setSavedIconVisible(val);
    }

    constructor(props: Readonly<EditableFieldProps<T>>, ctx: any) {
        super(props, ctx);
        this.store = new CustomEditableFieldStore({
            editing: this.props.editing || this.props.defaultEditing || false,
            value: this.props.defaultValue,
            saving: false,
            savedIconVisible: false
        });
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleTrigger = this.handleTrigger.bind(this);
    }

    handleClickOutside(event: MouseEvent) {
        if (!this.editing)
            return;
        if (this.wrapperRef.current != null && !this.wrapperRef.current.contains(event.target as Node)) {
            this.onCancel(event, 'outside');
        }
    }

    handleTrigger(event: MouseEvent) {
        if (this.editing) return;
        this.editing = true;
    }

    handleKeydown(event: KeyboardEvent) {
        if (!this.editing)
            return;
        switch (event.keyCode) {
            case 27: {
                if (!this.props.escape)
                    return;
                this.onCancel(event, 'escape');
                break;
            }
            case 13: {
                if (!this.props.enter)
                    return;
                this.onChange(event, 'enter');
                this.onSave(event, 'enter');
                break;
            }
            default: return;
        }
    }

    async onChange(e: Event, source: ChangeSource = 'button') {
        if (this.saving)
            return;
        if (this.props.onChange) {
            this.props.onChange(this.value, this, e, source);
        }
    }

    async validate(): Promise<boolean> {
        try {
            await this.formRef.current!.validateFields();
            return true;
        } catch {
            return false;
        }
    }

    async onSave(e: Event, source: ChangeSource = 'button') {
        if (this.saving)
            return;
        let preventSave = this.props.preventSaveIfEquals == true && this.value == this.props.defaultValue;
        if (this.props.beforeSave) {
            preventSave = this.props.beforeSave(this.value, this, e, source) == false;
        }
        if (preventSave) {
            this.value = this.props.defaultValue;
            this.editing = false;
            return;
        }
        //form validation
        if (this.formRef.current != null) {
            if (!await this.validate())
                return;
        }
        if (this.props.saveable == true) {
            if (!this.props.onSave)
                throw new Error('You must specify onSave function');
            this.hideSavedIcon();
            this.saving = true;
            const res = await this.props.onSave(this.value, this, e, source);
            this.saving = false;
            if (res == true) {
                this.showSavedIcon();
            }
        }
        if (this.props.autoclose) {
            this.editing = false;
        }
    }

    showSavedIcon() {
        this.savedIconVisible = true;
        this.savedIconTimeout = setTimeout(() => {
            this.savedIconVisible = false;
        }, this.props.savedIconVisibleDurationMs || 1000);
    }

    hideSavedIcon() {
        clearTimeout(this.savedIconTimeout);
        this.savedIconVisible = false;
    }

    onCancel(e: Event, source: CancelSource = 'button') {
        this.value = this.props.defaultValue;
        this.editing = false;
        if (this.props.onCancel != null)
            this.props.onCancel(this, e, source);
    }

    componentDidMount() {
        if (this.props.clickOutside == true) {
            document.addEventListener('mousedown', this.handleClickOutside);
            this.disposableStack.push(() => document.removeEventListener('mousedown', this.handleClickOutside));
        }
        const wrapper = this.wrapperRef.current!;
        if (this.props.enter == true || this.props.escape == true) {
            wrapper.addEventListener('keydown', this.handleKeydown);
            this.disposableStack.push(() => wrapper.removeEventListener('keydown', this.handleKeydown));
        }
        if (this.props.editTrigger) {
            switch (this.props.editTrigger) {
                case 'click':
                    wrapper.addEventListener('click', this.handleTrigger);
                    this.disposableStack.push(() => wrapper.removeEventListener('click', this.handleTrigger));
                    break;
                case 'dblclick':
                    wrapper.addEventListener('dblclick', this.handleTrigger);
                    this.disposableStack.push(() => wrapper.removeEventListener('dblclick', this.handleTrigger));
                    break;
            }
        }
    }

    componentWillUnmount() {
        this.disposableStack.dispose();
    }

    renderSavedIcon() {
        if (this.props.showSavedIcon == false)
            return null;
        return (
            <div className={styles.ef_saved_icon}>
                <CheckOutlined />
                {
                    this.props.savedText === undefined
                        ? null
                        : <Typography.Text>{this.props.savedText}</Typography.Text>
                }

            </div>
        );
    }

    renderEditableField() {
        return <div className={combineClasses(styles.ef_wrapper, this.props.className)} style={this.props.style}>
            {
                this.props.title != null
                    ? <div className={styles.ef_title}>
                        {
                            typeof (this.props.title) == 'string'
                                ? <label>{this.props.title}</label>
                                : this.props.title
                        }
                        {
                            this.savedIconVisible
                                ? this.renderSavedIcon()
                                : null
                        }
                    </div>
                    : null
            }
            <div id={this.props.id} ref={this.wrapperRef} className={styles.ef_field_holder} data-editing={this.editing}>
                {
                    !this.editing
                        ? <div className={styles.ef_normal} style={{ width: this.props.width }}>
                            {this.props.render(this)}
                        </div>
                        : <div className={styles.ef_editable} style={{ width: this.props.width }}>
                            {
                                this.props.rules
                                    ? <Form ref={this.formRef} initialValues={{ value: this.props.defaultValue }} style={{ width: '100%' }}>
                                        <Form.Item name={this.props.name ?? 'value'} help={this.props.help} rules={this.props.rules} style={{ marginBottom: 0 }}>
                                            {/* //@ts-ignore */}
                                            {this.props.renderEdit(this)}
                                        </Form.Item>
                                    </Form>
                                    : this.props.renderEdit(this)
                            }
                        </div>
                }
                {
                    this.props.title == null && this.savedIconVisible
                        ? this.renderSavedIcon()
                        : null
                }
            </div>
        </div>;
    }


    render() {
        return this.renderEditableField();
    }
}
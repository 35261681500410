import { ApiRequest, ApiResponse, HttpMethod } from '../../..';
import { FileManagerItem } from '../../../../models';

export class UpdateFileManagerItemRequest extends ApiRequest {
	public method = HttpMethod.PUT;
	public url: string = `/file-manager`;

	public body = {
		name: this.item.name,
		id: this.item.id,
		parentId: this.item.parentId
	}
	constructor(public item: Partial<FileManagerItem>) {
		super();
	}
}

export class UpdateFileManagerItemResponse extends ApiResponse {
	public id: string;
	constructor(response: any) {
		super(response);
		this.id = this.body;
	}
}

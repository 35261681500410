import { observable, action } from 'mobx';
import Entity from '../../Entity';

export default class ContactGroup extends Entity {
    constructor(group?: Partial<ContactGroup>) {
        super(group);
        if (group) this.update(group);
    }

    @observable name: string;
    createdDate: Date;
    updatedDate: Date;

    @action update(tag: Partial<ContactGroup>) {
        super.update(tag);
    }

    clone(): ContactGroup {
        return new ContactGroup(this);
    }

    static fromJson(json: any) {
        return new ContactGroup({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }
}

import { observable } from "mobx";
import { Updatable } from "../..";
import { isEmpty } from "../../../utils";
export default class BaseChannelSettings extends Updatable {
    @observable accessToken: string;

    constructor(settings?: Partial<BaseChannelSettings>) {
        super();
        if (settings) this.update(settings);
    }

    get isValid() {
        return !isEmpty(this.accessToken);
    }

    update(entity: Partial<BaseChannelSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    hasChanges(settings: BaseChannelSettings) {
        return this.accessToken !== settings.accessToken
    }

    clone() {
        return new BaseChannelSettings(this);
    }
}
import { Updatable } from '../../..';
import { PaymentGatewayPaymentMethodType, PaymentGatewayPaymentObjectType, PaymentGatewayTaxType, PaymentGatewayTaxationType } from '../PaymentGatewayInfo';

export default class CloudPaymentsSettings extends Updatable {
    public publicId: string;
    public apiKey: string;

    public taxation: PaymentGatewayTaxationType;
    public tax: PaymentGatewayTaxType | null;
    public paymentMethod: PaymentGatewayPaymentMethodType;
    public paymentObject: PaymentGatewayPaymentObjectType;

    update(entity: Partial<CloudPaymentsSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
import { computed, observable, runInAction } from "mobx";
import { isEmpty } from "../../../utils";
import BaseChannelSettings from "./BaseChannelSettings";
import Entity from "../../Entity";

export class WhatsAppPhoneNumber extends Entity {
    constructor(channel?: Partial<WhatsAppPhoneNumber>) {
        super();
        if (channel) this.update(channel);
    }

    @observable verifiedName: string;
    @observable displayPhoneNumber: string;
    @observable qualityRating: string;

    static fromJson(json: any): WhatsAppPhoneNumber {
        return new WhatsAppPhoneNumber({
            ...json,
        });
    }

    update(channel: Partial<WhatsAppPhoneNumber>) {
        super.update(channel);
    }
}

export default class WhatsAppChannelSettings extends BaseChannelSettings {
    @observable accountId: string;
    @observable phoneNumberId: string;
    @observable phoneNumber: string;

    constructor(settings?: Partial<WhatsAppChannelSettings>) {
        super();
        if (settings) this.update(settings);
    }

    get phone(): WhatsAppPhoneNumber | null {
        if (!this.hasPhoneNumber) return null;
        return new WhatsAppPhoneNumber({
            id: this.phoneNumberId,
            displayPhoneNumber: this.phoneNumber
        });
    }

    set phone(phone: WhatsAppPhoneNumber | null) {
        if (!phone) return; // ignore this case
        runInAction(() => {
            this.phoneNumberId = phone.id;
            this.phoneNumber = phone.displayPhoneNumber;
        });
    }

    @computed get isValid() {
        return !isEmpty(this.accessToken) && this.hasAccount;
    }

    @computed get hasAccount() {
        return !isEmpty(this.accountId) && this.hasPhoneNumber;
    }

    @computed get hasPhoneNumber() {
        return !isEmpty(this.phoneNumberId) && !isEmpty(this.phoneNumber);
    }

    update(entity: Partial<WhatsAppChannelSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    hasChanges(settings: WhatsAppChannelSettings): boolean {
        return super.hasChanges(settings)
            || this.accountId != settings.accountId
            || this.phoneNumberId != settings.phoneNumberId;
    }

    clone() {
        return new WhatsAppChannelSettings(this);
    }
}
export function requestFullscreen(el?: any) {
    const target = el ?? document.body;
    if (target.requestFullscreen) {
        target.requestFullscreen();
    } else if (target.webkitRequestFullscreen) { /* Safari */
        target.webkitRequestFullscreen();
    } else if (target.msRequestFullscreen) { /* IE11 */
        target.msRequestFullscreen();
    }
}

export function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
        //@ts-ignore
    } else if (document.webkitExitFullscreen) { /* Safari */
        //@ts-ignore
        document.webkitExitFullscreen();
        //@ts-ignore
    } else if (document.msExitFullscreen) { /* IE11 */
        //@ts-ignore
        document.msExitFullscreen();
    }
}
import React, { useMemo, useState } from 'react';
import { AccelFile } from '../../../../models';
import { Button, Tooltip } from 'antd';
import { DeleteFilled, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import AudioPlayer from '../../../AudioPlayer/AudioPlayer';
import FileUploader from '../../../FileUploader/FileUploader';
import { FileUploadStatus } from '../../../../enums';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Localization } from '../../../../utils';

type Props = {
    voice: AccelFile;
    suffix: React.ReactNode | null
    audioSize?: SizeType;
    loc: Localization;
    deletable: boolean;
    downloadable?: boolean;
    onDelete?: () => void;
    deleteButtonPlacement?: 'left' | 'right';
    playerStyle?: React.CSSProperties;
}
const VoiceItem: React.FC<Props> = ({ voice, suffix, audioSize, loc, deletable, downloadable, deleteButtonPlacement = 'left', playerStyle, onDelete }) => {

    const fileUploaderHandler = FileUploader.useHandler();
    const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>(voice.nativeFile != null ? FileUploadStatus.Uploading : FileUploadStatus.Uploaded);

    const delBtn = useMemo(() => deletable ? <Button danger type='link' icon={<DeleteFilled />} onClick={onDelete}></Button> : null, [deletable, onDelete]);
    return <>
        <div className='flex gap-10 align-center flex-1'>
            {deleteButtonPlacement === 'left' && delBtn}
            <AudioPlayer size={audioSize} file={voice} downloadable={downloadable ?? true} className='p-8 pt-5 pb-5 bg-gray-4 br-xs flex-1' style={playerStyle} />
            {uploadStatus == FileUploadStatus.Uploaded
                ? suffix
                : <Tooltip title={uploadStatus == FileUploadStatus.Error && loc.word('CommentForm.voice.uploadError', { default: 'Upload error, click to try again' })}>
                    <Button size='large'
                        icon={uploadStatus == FileUploadStatus.Error ? <SyncOutlined /> : <LoadingOutlined />}
                        type='text'
                        danger={uploadStatus == FileUploadStatus.Error}
                        // retry uploading
                        onClick={() => uploadStatus == FileUploadStatus.Error && setUploadStatus(FileUploadStatus.Uploading)} />
                </Tooltip>}
            {deleteButtonPlacement === 'right' && delBtn}
        </div>

        {uploadStatus == FileUploadStatus.Uploading &&
            <FileUploader handlerRef={fileUploaderHandler}
                autostart
                file={voice}
                onPrepare={() => setUploadStatus(FileUploadStatus.Uploaded)}
                uploadArgs={{
                    voice: true,
                    meta: voice.audioMeta
                }}
                onAbort={() => {
                    if (voice.nativeFile) {
                        setUploadStatus(FileUploadStatus.Error);
                    }
                }}
                onError={(a) => {
                    setUploadStatus(FileUploadStatus.Error);
                }} />}
    </>;
}


type VoiceListProps = {
    voices: AccelFile[];
    audioSize?: SizeType;
    loc: Localization;
    deletable: boolean;
    playerStyle?: React.CSSProperties;
    onDelete?: (voice: AccelFile) => void;
}
const VoiceList: React.FC<VoiceListProps> = ({ voices, audioSize, loc, deletable, playerStyle, onDelete }) => {

    return <div className='flex flex-col gap-10'>
        {voices.map(voice => <VoiceItem key={voice.id} voice={voice} audioSize={audioSize} loc={loc} deletable={deletable} suffix={null} deleteButtonPlacement='right' playerStyle={playerStyle} onDelete={() => onDelete?.(voice)} />)}
    </div>;
}
export { VoiceItem, VoiceList }
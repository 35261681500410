import { observable } from 'mobx';
import { HelpItem } from '../..';
import { Lang } from '../../../enums';
import { Designable } from '../../Entity';

export default class HelpItemContent extends Designable {
    constructor(content?: Partial<HelpItemContent>) {
        super(content);
        if (content) this.update(content);
    }
    @observable title: string;
    @observable shortDescription: string;

    @observable isPublished: boolean;
    @observable language: Lang;
    @observable item: HelpItem;
    @observable relatedArticles: HelpItemContent[];

    static fromJson(json: any) {
        return new HelpItemContent({
            ...json,
            item: json.item ? HelpItem.fromJson(json.item) : undefined,
            relatedArticles: json.relatedItemLinks ? json.relatedItemLinks.map((x: any) => HelpItemContent.fromJson(x.relatedHelpItemContent)) : []
        });
    }

    update(session: Partial<HelpItemContent>, allowUndefined = false) {
        super.update(session, allowUndefined);
    }
}
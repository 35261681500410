import { observable, action } from 'mobx';
import Contact from '../contact/contact';
import Admin from '../admin/Admin';
import moment from 'moment';
import Entity, { ISerializable } from '../Entity';
import BaseFilter from '../BaseFilter';

export type PartnershipTransactionRequestStatus = 'requested' | 'approved' | 'declined';

export default class PartnershipTransactionRequest extends Entity implements ISerializable {
    constructor(t?: Partial<PartnershipTransactionRequest>) {
        super();
        if (t) this.update(t);
    }

    @observable amount: number;
    @observable partnerComment: string;
    @observable adminComment: string;
    @observable status: PartnershipTransactionRequestStatus;
    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable partner: Contact;
    @observable admin?: Admin;

    toJson() {
        return {
            id: this.id,
            amount: this.amount,
            partnerComment: this.partnerComment,
            adminComment: this.adminComment,
            status: this.status,
            partnerId: this.partner?.id,
        }
    }


    @action update(partnership: Partial<PartnershipTransactionRequest>) {
        super.update(partnership);
    }

    static fromJson(json: any): PartnershipTransactionRequest {
        return new PartnershipTransactionRequest({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            partner: json.partner ? Contact.fromJson(json.partner) : undefined,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined
        });
    }
}

export class PartnershipTransactionRequestFilter extends BaseFilter<PartnershipTransactionRequest> {
    constructor(filter?: Partial<PartnershipTransactionRequestFilter>) {
        super();
        if (filter) this.update(filter);
    }

    createdDateFrom: moment.Moment | null;
    createdDateTo: moment.Moment | null;
    partnerIds: string[];
    amountFrom: number;
    amountTo: number;
    feeFrom: number;
    feeTo: number;
    /**
     * has admin => manual transaction
     */
    hasAdminId: boolean;

    update(changes: Partial<PartnershipTransactionRequestFilter>) {
        super.update(changes);
    }
}
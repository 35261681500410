import { Space, Tooltip } from 'antd';
import { SpaceSize } from 'antd/lib/space';
import React, { useMemo } from 'react';
import Loc from '../Loc/Loc';
import Tag from '../Tag/Tag';

type Props<T> = {
    items: any[];
    count?: number;
    spaceSize?: SpaceSize;
    direction?: 'horizontal' | 'vertical';
    tagSize?: 'xs' | 'sm' | 'md';
    restItemsClassName?: string;
    ellipsis?: (moreCount: number) => React.ReactNode;
    children: (item: T, index: number) => React.ReactNode;
}
const TruncateItems: <T extends any>(p: Props<T>) => React.ReactElement<Props<T>> = ({ count, items, spaceSize, direction, tagSize, restItemsClassName, ellipsis, children }) => {

    const visibleItems = useMemo(() => {
        return items.slice(0, count).map((x, i) => children(x, i))
    }, [items, count]);

    const restItems = useMemo(() => items.slice(count).map((x, i) => <div key={i}>{children(x, i)}</div>), [items, count]);
    const moreCount = useMemo(() => Math.max(0, items.length - count!), [restItems]);

    return <>
        <Space size={spaceSize} direction={direction}>
            {visibleItems}
            {ellipsis
                ? ellipsis(moreCount)
                : <>
                    {moreCount > 0 && <Tooltip title={<div className={restItemsClassName}>{restItems}</div>}>
                        <Tag size={tagSize ?? 'xs'}>
                            <Loc word='Global.list.moreItemsTemplate'>
                                {moreCount}
                            </Loc>
                        </Tag>
                    </Tooltip>}
                </>}
        </Space>
    </>;
}
(TruncateItems as React.FC<Props<any>>).defaultProps = {
    items: [],
    count: 3,
    spaceSize: 2,
}
export default TruncateItems;
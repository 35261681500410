import { action, observable } from 'mobx';
import { Admin, Contact, AccelFile, Entity, User } from '../../../../../';
import moment from 'moment';

export default class CourseItemTaskAnswerComment extends Entity {
    constructor(comment?: Partial<CourseItemTaskAnswerComment>) {
        super(comment);
        if (comment) this.update(comment);
    }
    @observable id: string;
    @observable createdDate: moment.Moment;
    @observable updatedDate: moment.Moment;
    @observable comment: string;
    @observable admin: Admin | null;
    @observable contact: Contact | null;
    @observable files: AccelFile[];
    get author(): User {
        return this.contact ?? this.admin!;
    }

    static fromJson(json: any): CourseItemTaskAnswerComment {
        return new CourseItemTaskAnswerComment({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            files: json.files ? json.files.map(AccelFile.fromJson) : [],
        });
    }

    @action
    update(comment: Partial<CourseItemTaskAnswerComment>) {
        super.update(comment);
    }
}

import { observable, action } from 'mobx';
import { Entity, Course, CoursePlan, Package } from '..';

export default class PackageCourse extends Entity {
    constructor(item?: Partial<PackageCourse>) {
        super(item);
        if (item) this.update(item);
    }

    @observable plan: CoursePlan;
    @observable course: Course;
    @observable package: Package;

    static fromJson(json: any): PackageCourse {
        return new PackageCourse({
            ...json,
            course: json.course ? Course.fromJson(json.course) : undefined,
            plan: json.plan ? CoursePlan.fromJson(json.plan) : undefined,
            package: json.package ? Package.fromJson(json.package) : undefined,
        });
    }

    @action
    update(course: Partial<PackageCourse>) {
        super.update(course);
    }
}


import { Updatable } from '../../..';

export type TinkoffInstallmentPromocode = { _id?: string; _isNew?: boolean; key: string; value: string };
export default class TinkoffInstallmentSettings extends Updatable {
    public shopId: string;
    public showcaseId: string;
    public password: string;
    public testMode: boolean;
    public widgetMode: boolean = true;
    public promoCodes: TinkoffInstallmentPromocode[] = [];
    public flexible: boolean = true;

    constructor(settings?: Partial<TinkoffInstallmentSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(settings: Partial<TinkoffInstallmentSettings>, allowUndefined = false) {
        super.update(settings, allowUndefined);
    }
}
import { ChatChannelType } from '..';
import FbChannelSettings from './Settings/FbChannelSettings';
import VkChannelSettings from './Settings/VkChannelSettings';
import Wazzup24ChannelSettings from './Settings/Wazzup24ChannelSettings';
import WhatsAppChannelSettings from './Settings/WhatsAppChannelSettings';
import InstagramChannelSettings from './Settings/InstagramChannelSettings';
import LineChannelSettings from './Settings/LineChannelSettings';
import BaseChannelSettings from './Settings/BaseChannelSettings';


export class ChatChannelSettingsFactory {
    public static build(type: ChatChannelType, settingsJson?: string) {
        const settings = settingsJson ? JSON.parse(settingsJson) : {};
        switch (type) {
            case ChatChannelType.Vk:
                return new VkChannelSettings(settings);
            case ChatChannelType.Fb:
                return new FbChannelSettings(settings);
            case ChatChannelType.Wazzup24:
                return new Wazzup24ChannelSettings(settings);
            case ChatChannelType.Wa:
                return new WhatsAppChannelSettings(settings);
            case ChatChannelType.Ig:
                return new InstagramChannelSettings(settings);
            case ChatChannelType.Line:
                return new LineChannelSettings(settings);
            case ChatChannelType.Tg:
            case ChatChannelType.Vb:
            default:
                return new BaseChannelSettings(settings);
        }
    }
}

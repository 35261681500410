import { Updatable } from '../../..';

export default class SquareUpSettings extends Updatable {
    public appId: string;
    public locationId: string;
    public accessToken: string;    
    public testMode: boolean;
    public widgetMode: boolean;

    update(entity: Partial<SquareUpSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}
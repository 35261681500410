import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Input, InputRef } from 'antd';
import EditableField, { EditableFieldCommonProps, CancelSource } from '../CustomEditableField/CustomEditableField';
import { Localization, KeyGenerator, isEmpty } from '../../../utils';
import { observable, action } from 'mobx';
import styles from './TextEditableField.module.scss';
import { observer } from 'mobx-react';
import { Context } from '../../AccelProvider/AccelProvider';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TextAreaRef } from 'antd/lib/input/TextArea';

type TextEditableFieldProps = {
    value?: any;
    suffix?: (self: EditableField<string>) => React.ReactNode;
    actionsWidth?: number;
    dashed?: boolean;
    blur?: boolean;
    showButtons?: boolean;
    onBlur?: (self: EditableField<string>, e: React.FocusEvent<Element>) => void;
    autofocus?: boolean;
    inputSize?: SizeType,
    textarea?: boolean,
    autosize?: boolean | { minRows?: number, maxRows?: number },
    placeholder?: string | undefined,
    loc?: Localization,
    className?: string;
    allowEmpty?: boolean;
}
@observer
export class TextEditableField extends React.Component<TextEditableFieldProps & EditableFieldCommonProps<string>> {
    static contextType = Context;
    context!: React.ContextType<typeof Context>;

    get loc(): Localization { return this.context.loc }
    get keygen(): KeyGenerator { return this.context.keygen }
    editInputRef: InputRef | any | null;

    @observable actionsWidth: number = 25;
    @action setActionWidth(val?: number) { this.actionsWidth = val || 0; }

    static defaultProps: Partial<TextEditableFieldProps & EditableFieldCommonProps<string>> = {
        suffix: (self: EditableField<string>) => <>
            <EditOutlined onClick={() => { self.editing = true }} style={{ marginRight: '5px' }} />
        </>,
        actionsWidth: 25,
        dashed: false,
        blur: true,
        showButtons: true,
        clickOutside: false,
        autofocus: true,
        inputSize: 'middle',
        textarea: false,
        autosize: true,
        allowEmpty: true
    }

    handleChange(self: EditableField<string>, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        self.value = e.target.value;
        self.onChange(e.nativeEvent);
    }

    handleBlur(self: EditableField<string>, e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (this.props.onBlur)
            this.props.onBlur(self, e);
        if (this.props.blur != true)
            return;
        // if (this.preventBlur) {
        //     this.preventBlur = false;
        //     return;
        // }
        self.value = e.target.value;
        self.onSave(e.nativeEvent, 'custom');
    }

    handleSaveClick(self: EditableField<string>, e: React.MouseEvent) {
        //to prevent creating empty field
        if(this.props.allowEmpty == false && isEmpty(self.value)) {
            self.onCancel(e.nativeEvent);
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        self.onSave(e.nativeEvent, 'button');
    }

    handleCancelClick(self: EditableField<string>, e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        self.onCancel(e.nativeEvent);
        return false;
    }

    setEditInputRef(input: InputRef | null) {
        if (input == null) return;
        const ifFirstTime = this.editInputRef == null;
        this.editInputRef = input;
        if (ifFirstTime) {
            if (this.props.autofocus == true) {
                setTimeout(() => {
                    input.focus();
                }, 1);
            }
        }
    }

    setEditTextAreaRef(input: TextAreaRef | null) {
        if (input == null) return;
        const ifFirstTime = this.editInputRef == null;
        this.editInputRef = input;
        if (ifFirstTime) {
            if (this.props.autofocus == true) {
                setTimeout(() => {
                    input.focus();
                    input.resizableTextArea?.textArea?.setSelectionRange(10000, 10000);
                }, 1);
            }
        }
    }

    handleClick(self: EditableField<string>, e: React.MouseEvent<Element, MouseEvent>): void {
        if (this.props.editTrigger != 'click')
            return;
        e.preventDefault();
        e.stopPropagation();
        self.editing = true;
    }

    handleDoubleClick(self: EditableField<string>, e: React.MouseEvent<Element, MouseEvent>): void {
        if (this.props.editTrigger != 'dblclick')
            return;
        e.preventDefault();
        e.stopPropagation();
        self.editing = true;
    }

    // @action
    handleCancel(self: EditableField<string>, e: Event, source: CancelSource) {
        // if (this.editInputRef != null) {
        //     this.preventBlur = true;
        //     this.editInputRef.blur();
        // }
        if (this.props.onCancel)
            this.props.onCancel(self, e, source);
    }

    getInputTitle() {
        switch (this.props.editTrigger) {
            case 'click': return this.loc.word('Click to edit');
            case 'dblclick': return this.loc.word('Double click to edit');
            default: return '';
        }
    }

    renderInput() {
        const id = this.keygen.generate();
        return <>
            {/* <style>
                {`#${id}[data-editing='true'] .ant-input-affix-wrapper input {
                    padding-right:${this.props.showButtons != false ? this.props.actionsWidth || 0 : 0}px !important;
                }
                #${id}[data-editing='false'] .ant-input-affix-wrapper input {
                    padding-right:${this.props.actionsWidth || 0}px !important;
                }`}
            </style> */}
            {this.props.value}
            <EditableField
                {...this.props}
                id={id}
                editTrigger='custom'
                onCancel={this.handleCancel.bind(this)}
                render={self => {
                    return <Input size={this.props.inputSize} title={this.getInputTitle()} readOnly value={self.value} defaultValue={this.props.defaultValue}
                        className={styles.input_ef__inp}
                        data-dashed={this.props.dashed}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        onClick={e => this.handleClick(self, e)}
                        onDoubleClick={e => this.handleDoubleClick(self, e)}
                        suffix={
                            this.props.suffix
                                ? this.props.suffix(self)
                                : null
                        } />;
                }}
                renderEdit={self => {
                    // we shouldn't set value when using formitem's validation rules
                    const props = this.props.rules ? {} : { value: self.value };
                    return (
                        <Input {...props}
                            ref={this.setEditInputRef.bind(this)}
                            onChange={e => this.handleChange(self, e)}
                            onBlur={e => this.handleBlur(self, e)}
                            size={this.props.inputSize}
                            disabled={self.saving || this.props.disabled}
                            placeholder={this.props.placeholder}
                            suffix={this.props.showButtons
                                ? <>
                                    <div onMouseDown={e => { this.handleSaveClick(self, e); }} className={styles.input_ef__save_btn} title={this.loc.word('Save')}>
                                        <CheckCircleOutlined />
                                    </div>
                                    <div onMouseDown={e => { this.handleCancelClick(self, e); }} className={styles.input_ef__cancel_btn} title={this.loc.word('Cancel')}>
                                        <CloseCircleOutlined />
                                    </div>
                                </>
                                : null
                            } />
                    );
                }}
                className={this.props.className}
            />

        </>;
    }

    renderTextarea() {
        return <EditableField
            {...this.props}
            editTrigger='custom'
            onCancel={this.handleCancel.bind(this)}
            render={self => <>
                <Input.TextArea title={this.loc.word('Click to edit')} readOnly value={self.value} defaultValue={this.props.defaultValue}
                    className={styles.input_ef__inp}
                    data-dashed={this.props.dashed}
                    disabled={this.props.disabled}
                    onClick={e => this.handleClick(self, e)}
                    onDoubleClick={e => this.handleDoubleClick(self, e)}
                    autoSize={this.props.autosize}
                    placeholder={this.props.placeholder} />
            </>}
            renderEdit={self => {
                // we shouldn't set value when using formitem's validation rules
                const props = this.props.rules ? {} : { value: self.value };
                return <Input.TextArea {...props}
                    ref={this.setEditTextAreaRef.bind(this)}
                    disabled={self.saving || this.props.disabled}
                    onChange={e => this.handleChange(self, e)}
                    onBlur={e => this.handleBlur(self, e)}
                    autoSize={this.props.autosize}
                    placeholder={this.props.placeholder} />;
            }}
            className={this.props.className}
        />;
    }

    render() {
        return this.props.textarea ? this.renderTextarea() : this.renderInput();
    }
}
import { Admin, AccelTag, Contact, Entity, PaymentGateway, PromoCode, PurchaseOrderContent, PurchaseOrderPayment, School, Site, PipelineStageItem, UtmContainer, CustomAttributeValue, CustomFieldValue, CustomField } from '../..';
import { Currency } from '../../../enums';
import { PurchaseOrderStatus } from './PurchaseOrderStatus';
import moment from 'moment';
import { observable, computed, action } from 'mobx';
import { ProductType } from '../../Product/ProductType';
import { isArraysEqual, isEmpty } from '../../../utils';
import { ISerializable } from '../../Entity';

export default class PurchaseOrder extends Entity implements ISerializable {
    constructor(purchaseOrder?: Partial<PurchaseOrder>) {
        super(purchaseOrder);
        if (purchaseOrder) this.update(purchaseOrder, false, true);
    }

    @observable createdDate: moment.Moment;
    @observable paidDate: moment.Moment | null;
    @observable currency: Currency;
    @observable isDigitalDelivery: boolean;
    @observable fullyPaid: boolean;
    @observable shippingPrice?: number;
    // including savings
    @observable totalAmount: number;
    @observable paidAmount: number;
    @observable paidBonusAmount: number;
    @observable savingsAmount: number;
    @observable status: PurchaseOrderStatus;
    @observable completed: boolean;
    @observable shippingEmail: string;
    @observable shippingFirstName: string;
    @observable shippingLastName: string;
    @observable shippingPhone: string;
    @observable shippingAddress: string;
    @observable shippingCity: string;
    @observable shippingState: string;
    @observable shippingZip: string;
    @observable shippingCountry: string;
    @observable shippingComment: string;
    @observable shippingTrackingCode: string;
    @observable siteGuestId: string;
    @observable siteSessionEnterViewId: string;
    @observable number: number;
    @observable pipelineItem: PipelineStageItem;

    @observable parent: PurchaseOrder | null;
    @observable admin: Admin | null;
    @observable responsible: Admin | null;
    @observable site: Site;
    @observable contact: Contact | null;
    @observable school: School | null;
    @observable paymentGateway: PaymentGateway;
    @observable payments: PurchaseOrderPayment[];
    @observable contents: PurchaseOrderContent[];
    @observable promoCode: PromoCode | null;
    @observable tags: AccelTag[];
    @observable offSession: boolean;
    @observable utmTags: UtmContainer;
    @observable feeAmount: number;
    @observable partnerFeeAmount: number;
    @observable partner: Contact | null;
    @observable income: number;

    @observable expirationDate: moment.Moment | null;
    @observable fields: CustomField[];
    @observable fieldValues: CustomFieldValue[];

    @observable renewalLicenseId: string;

    @computed get isRenewal() {
        return !isEmpty(this.renewalLicenseId);
    }

    @computed get totalAmountWithShipping(): number {
        return this.totalAmount + (this.shippingPrice ?? 0);
    }

    @computed get totalAmountWithoutSavings(): number {
        return this.totalAmount + this.savingsAmount;
    }

    @computed get amountToBePaid(): number {
        return (this.totalAmount - this.paidAmount) + (this.shippingPrice ?? 0);
    }

    @computed get needShipping(): boolean {
        return this.contents.some(x => x.product.type == ProductType.Physical);
    }   

    @computed get canModifyContent(): boolean {
        return !this.completed && this.status != PurchaseOrderStatus.Payment;
    }

    @action calcTotals() {
        this.totalAmount = this.contents.map(x => x.totalPriceFinal).reduce((a, b) => a + b, 0);
        this.savingsAmount = this.contents.map(x => x.totalSavings).reduce((a, b) => a + b, 0);
    }

    @computed get canComplete() {
        return !this.completed && this.contents.length > 0;
    }

    @computed get hasContact() {
        return this.contact != null;
    }

    getFieldValue(attrId: string): CustomFieldValue | null {
        const val = this.fieldValues.find(x => x.customField.id == attrId);
        return val ?? null;
    }

    @action setFieldValue(value: CustomFieldValue) {
        const val = this.getFieldValue(value.customField.id);
        if (val) {
            val.update(value, true);
        } else {
            this.fieldValues.push(value);
        }
    }
    
    @computed get isExpired() {
        return this.expirationDate != null && this.expirationDate.isBefore(moment());
    }

    @computed get isContactEditable() {
        return !this.completed && !this.isRenewal;
    }

    @computed get isPartiallyPaid() {
        return this.paidAmount > 0 && this.paidAmount < this.totalAmount;
    }
    
    toJson() {
        throw new Error('Method not implemented.');
    }

    update(changes: Partial<PurchaseOrder>, allowUndefined = false, disableRecalc = false) {
        const needRecalc = disableRecalc != true && (changes.contents !== undefined || changes.shippingPrice != this.shippingPrice);
        super.update(changes, allowUndefined);
        if (needRecalc) this.calcTotals();
    }

    clone(): PurchaseOrder {
        return new PurchaseOrder({
            ...this,
            promoCode: this.promoCode?.copy(),
            payments: this.payments?.slice(),
            contents: this.contents?.map(x => x.copy()),
            tags: this.tags?.map(x => x.copy()),
            pipelineItem: this.pipelineItem?.clone(),
            partner: this.partner?.clone(),
            fields: this.fields?.map(x => x.clone()),
            fieldValues: this.fieldValues?.map(x => x.clone()),
        });
    }

    hasChanges(order: PurchaseOrder, skipContents = true): boolean {
        const result = this.status != order.status
            || this.shippingEmail != order.shippingEmail
            || this.shippingFirstName != order.shippingFirstName
            || this.shippingLastName != order.shippingLastName
            || this.shippingPhone != order.shippingPhone
            || this.shippingAddress != order.shippingAddress
            || this.shippingCity != order.shippingCity
            || this.shippingState != order.shippingState
            || this.shippingZip != order.shippingZip
            || this.shippingComment != order.shippingComment
            || this.shippingCountry != order.shippingCountry
            || this.shippingTrackingCode != order.shippingTrackingCode
            || this.shippingPrice != order.shippingPrice
            || this.contact?.id != order.contact?.id
            || this.responsible?.id != order.responsible?.id
            || this.promoCode?.id != order.promoCode?.id
            || this.partner?.id != order.partner?.id
            || this.pipelineItem?.id != order.pipelineItem?.id
            || !this.tags?.isEquals(order.tags, (a, b) => a.id === b.id)
            || this.expirationDate != order.expirationDate
            || this.partner?.id != order.partner?.id
            || !isArraysEqual(this.fieldValues,
                order.fieldValues,
                (a, b) => a.isEquals(b));
        if (result || skipContents) return result;
        return (this.contents
            && order.contents
            && (this.contents.length != order.contents.length || this.contents.some(x => order.contents.find(y => x.id == y.id) == null)));
    }

    static fromJson(json: any): PurchaseOrder {
        return new PurchaseOrder({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            paidDate: json.paidDate ? moment(json.paidDate) : null,
            contact: json.contact ? Contact.fromJson(json.contact) : null,
            site: json.site ? Site.fromJson(json.site) : undefined,
            paymentGateway: json.paymentGateway ? PaymentGateway.fromJson(json.paymentGateway) : undefined,
            contents: json.contents ? json.contents.map(PurchaseOrderContent.fromJson) : [],
            payments: json.payments ? json.payments.map(PurchaseOrderPayment.fromJson) : [],
            parent: json.parent ? PurchaseOrder.fromJson(json.parent) : null,
            admin: json.admin ? Admin.fromJson(json.admin) : null,
            responsible: json.responsible ? Admin.fromJson(json.responsible) : null,
            school: json.school ? School.fromJson(json.school) : null,
            promoCode: json.promoCode ? PromoCode.fromJson(json.promoCode) : null,
            tags: json.tagLinks ? json.tagLinks.map((i: any) => AccelTag.fromJson(i.tag)) : [],
            pipelineItem: json.pipelineItem ? PipelineStageItem.fromJson(json.pipelineItem) : undefined,
            utmTags: new UtmContainer({ campaign: json.utmCampaign, content: json.utmContent, medium: json.utmMedium, source: json.utmSource, term: json.utmTerm }),
            expirationDate: json.expirationDate ? moment(json.expirationDate) : null,
            partner: json.partner ? Contact.fromJson(json.partner) : null,
            fieldValues: CustomFieldValue.parseValuesFromJson(json.fieldValues),
            fields: json.fields ? json.fields.map(CustomField.fromJson) : [],
        });
    }
}
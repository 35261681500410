import { action, computed, observable } from 'mobx';
import { AccelFile, Scenario, Site, SiteComponent } from '../..';
import { Lang } from '../../../enums';
import { isEmpty } from '../../../utils';
import { Designable } from '../../Entity';

export enum SitePageLayoutType {
    Default = 'default',
    Custom = 'custom'
}

export type SitePageLang = Lang | 'custom' | null;

export default class SitePage extends Designable {
    constructor(sitePage?: Partial<SitePage>) {
        super(sitePage);
        if (sitePage) this.update(sitePage);
    }

    @observable siteId: string;
    @observable name: string;
    @observable internalName: string;
    @observable segment: string;
    @observable url: string;
    @observable shortDescription: string;
    @observable isPublished: boolean;
    @observable redirect: boolean;
    @observable parentId: string | null;
    @observable position: number;
    @observable redirectUrl: string | null;

    @observable image: AccelFile | null;

    @observable lang: SitePageLang;
    @observable customLang: string | null;

    @observable hideFromSearchBots: boolean;

    @observable headHtml: string;
    @observable bodyHtml: string;

    @observable headerIntegrationCode: string;
    @observable bodyIntegrationCode: string;

    @observable layoutType: SitePageLayoutType;

    @observable createdDate: Date;
    @observable updatedDate: Date;

    @observable parent?: SitePage | null;
    @observable site?: Site;

    @observable header?: SiteComponent | null;
    @observable footer?: SiteComponent | null;

    @observable scenario?: Scenario;

    @computed get hasInternalName() {
        return !isEmpty(this.internalName);
    }

    static fromJson(json: any): SitePage {
        const item = new SitePage({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,

            parent: json.parent ? SitePage.fromJson(json.parent) : undefined,
            site: json.site ? Site.fromJson(json.site) : undefined,

            image: json.image ? AccelFile.fromJson(json.image) : null,
            header: json.header ? SiteComponent.fromJson(json.header) : null,
            footer: json.footer ? SiteComponent.fromJson(json.footer) : null,

            lang: !isEmpty(json.customLang) ? 'custom' : json.lang ?? null,
            customLang: json.customLang ?? null,

            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
        });
        if (item.scenario) item.scenario.update({ sitePage: item });
        return item;
    }

    @action
    update(changes: Partial<SitePage>, allowUndefined: boolean = false) {
        super.update(changes, allowUndefined);
    }

    clone(changes?: Partial<SitePage>) {
        return new SitePage({
            ...this,
            ...changes
        });
    }

    hasChanges(page: SitePage) {
        return this.name != page.name
            || this.internalName != page.internalName
            || this.shortDescription != page.shortDescription
            || this.segment != page.segment
            || this.redirect != page.redirect
            || this.redirectUrl != page.redirectUrl
            || this.hideFromSearchBots != page.hideFromSearchBots
            || this.header?.id != page.header?.id
            || this.footer?.id != page.footer?.id
            || this.layoutType != page.layoutType
            || this.site?.id != page.site?.id
            || this.image?.id != page.image?.id
            || this.lang != page.lang
            || this.customLang != page.customLang;
    }
}
import { observable, action, computed } from 'mobx';
import { ContactGroup, Entity, Scenario } from '..';
import { ISerializable } from '../Entity';

export default class Partnership extends Entity implements ISerializable {
    constructor(partnership?: Partial<Partnership>) {
        super();
        if (partnership) this.update(partnership);
    }

    @observable isActive: boolean;

    @observable agreementRequired: boolean;
    @observable agreementText: string;
    @observable agreementUrl: string;

    @observable partnerAssigningCookieDurationDays: number;
    @observable partnerAssigningDurationType: 'endless' | 'days';
    @observable partnerAssigningDurationDays: number;

    @observable transferBonusPartner: boolean;
    @observable transferBonusPartnerAmount: number;
    @observable transferBonusReferral: boolean;
    @observable transferBonusReferralAmount: number;

    @observable transferFeeMethod: 'referral' | 'partner';
    @observable transferFeeAllowed: boolean;
    @observable transferFeeAllowedToBonus: boolean;
    @observable transferFeeMinAmount: number;
    @observable transferFeeHoldingPeriodDays: number;

    @observable partnershipVisible: boolean;
    @observable partnershipVisibleToGroup: ContactGroup | null;
    @observable partnershipPaidOrdersVisible: boolean;
    @observable partnershipAllOrdersVisible: boolean;
    @observable partnershipOrdersContactVisible: boolean;

    @observable paymentGatewayFeeEnabled: boolean;
    @observable levelCount: number;

    @computed get isSingleLevel() {
        return this.levelCount === 1;
    }

    @observable scenario: Scenario;

    toJson() {
        return {
            ...this,
            partnershipVisibleToGroupId: this.partnershipVisibleToGroup === null ? null : this.partnershipVisibleToGroup?.id,
            // skip some fields just in case,
            partnershipVisibleToGroup: undefined,
            scenario: undefined
        }
    }

    clone() {
        return new Partnership({
            ...this
        });
    }

    @action update(partnership: Partial<Partnership>, allowUndefined = false) {
        super.update(partnership, allowUndefined);
    }

    hasChanges(settings: Partnership) {
        return Object.keys(settings).some(key => settings[key] !== this[key]);
    }

    static fromJson(json: any): Partnership {
        const partnership = new Partnership({
            ...json,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            partnershipVisibleToGroup: json.partnershipVisibleToGroup ? ContactGroup.fromJson(json.partnershipVisibleToGroup) : undefined,
        });

        if (partnership.scenario)
            partnership.scenario.update({ partnership });

        return partnership;
    }
}
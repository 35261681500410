import { inject } from 'react-ioc';
import { CookieStorage } from '..';

const CountryCookieName = 'country';
export default class GeoLocator {
    constructor(protected cookieStorage: CookieStorage) {
        this.cookieStorage = cookieStorage || inject(this, CookieStorage);
    }

    public async fetchCounty(): Promise<string> {
        var savedCountry = this.cookieStorage.get(CountryCookieName);
        if (savedCountry) {
            return savedCountry;
        }
        try {
            const response = await fetch("https://ipinfo.io", {
                headers: { 'Accept': 'application/json' }
            });
            const json = await response.json();
            const countryCode = (json && json.country) ? json.country : "us";
            this.cookieStorage.set(CountryCookieName, countryCode, { expires: 7 });
            return countryCode;
        }
        catch {
            return "us";
        }
    }

    // TODO fetching ip
}